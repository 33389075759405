import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { useCallback, useMemo } from 'react';

import { CommonLink } from '../../../components/common/Forms/Button';
import { useAuth } from '../../../context/AuthContext';
import {
  loginToBookAtSiteUri,
  patientAddToHospitalUri,
} from '../../../routes/AppRoutes';
import {
  TrustMiniSite,
  TrustMiniSiteCategory,
} from '../../../types/trustMiniSites';
import {
  TrustMiniSiteListItem,
  TrustMiniSiteListItemName,
} from './TrustMiniSitePage.styled';

interface Props {
  trustMiniSite: TrustMiniSite;
  category: TrustMiniSiteCategory;
}

export const TrustMiniSiteCategorySitesList = ({
  trustMiniSite: { sites },
  category,
}: Props) => {
  const { t } = useCommonTranslation();
  const { sangixUser } = useAuth();
  sites = useMemo(
    () =>
      sites
        .filter((site) => site.categories.includes(category.id))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [sites, category.id],
  );

  const generateBookUri = useCallback(
    (siteId: string) => {
      if (!sangixUser) {
        return loginToBookAtSiteUri(siteId);
      } else {
        return patientAddToHospitalUri(siteId);
      }
    },
    [sangixUser],
  );

  return (
    <>
      {sites.map((site) => (
        <TrustMiniSiteListItem key={site.id}>
          <TrustMiniSiteListItemName>{site.name}</TrustMiniSiteListItemName>
          {/**
           * TODO SGX-382 The link for booking into hospital should support both new and already registered users.
           * We support login page with hash like /login#siteId=XYZ, which extends sign-up button url to /sign-up#siteId=XYZ.
           * But after login (for registered users), user is redirected to /patient page with loosing the siteId.
           * We should introduce new behavior to go to /patient/add-hospital#siteId=XYZ after login when
           * siteId is part of url hash.
           */}
          <CommonLink variant="primary" to={generateBookUri(site.id)}>
            {t('book')}
          </CommonLink>
        </TrustMiniSiteListItem>
      ))}
    </>
  );
};
