import { Card } from 'components/common';
import {
  DialogFormWrapper,
  FormGroupTitle,
} from 'components/common/Forms/Forms.styled';
import { Col } from 'components/common/Grid';
import { useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { FaExternalLinkAlt, FaTrashAlt } from 'react-icons/fa';
import { useConfirmDialog } from '../../../../components/Popup/ConfirmDialog/confirmDialogHook';
import { TableActions } from '../../../../components/Table/Table.styled';
import {
  CommonButton,
  CommonLink,
} from '../../../../components/common/Forms/Button';
import { CardLoader } from '../../../../components/common/Loading';
import { TrustMiniSiteCategoriesTable } from '../../../../components/views-components/admin/trusts/mini-site/TrustMiniSiteCategoriesTable';
import { TrustMiniSiteCategoryCard } from '../../../../components/views-components/admin/trusts/mini-site/TrustMiniSiteCategoryCard';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  useMutateCreateTrustMiniSiteCategory,
  useMutateDeleteTrustMiniSiteCategory,
  useMutateUpdateTrustMiniSiteCategory,
  useTrustMiniSite,
} from '../../../../query/trustMiniSites';
import { trustMiniSiteURL } from '../../../../routes/AppRoutes';
import {
  TrustMiniSiteCategory,
  TrustMiniSiteCategoryFormValues,
} from '../../../../types/trustMiniSites';
import { TrustData } from '../../../../types/trusts';
import { errorToast } from '../../../../utils/toast';

type Props = {
  trust: TrustData;
};

export const TrustMiniSitePreferences = ({ trust }: Props) => {
  const { t } = useCommonTranslation();
  const [formState, setFormState] = useState<{
    opened: boolean;
    prefilledData?: TrustMiniSiteCategory;
  }>({ opened: false });
  const { confirm } = useConfirmDialog();
  const {
    data: trustMiniSite,
    isLoading: isTrustMiniSiteLoading,
    isFetching: isTrustMiniSiteFetching,
  } = useTrustMiniSite(trust.id);
  const trustId = trust.id;

  const categories = useMemo(() => {
    if (!trustMiniSite) {
      return undefined;
    }
    return {
      data: trustMiniSite.categories,
      count: trustMiniSite.categories.length,
    };
  }, [trustMiniSite]);

  const { isLoading: isCreateLoading, mutate: createCategory } =
    useMutateCreateTrustMiniSiteCategory(trustId, {
      onSuccess: () => closeFormHandler(),
    });
  const { isLoading: isUpdateLoading, mutate: updateCategory } =
    useMutateUpdateTrustMiniSiteCategory(trustId, {
      onSuccess: () => closeFormHandler(),
    });
  const { isLoading: isDeleteLoading, mutate: deleteCategory } =
    useMutateDeleteTrustMiniSiteCategory(trustId, {
      onSuccess: () => closeFormHandler(),
    });

  const isTableLoading =
    isTrustMiniSiteLoading ||
    isDeleteLoading ||
    isCreateLoading ||
    isUpdateLoading;
  const isTableFetching = isTrustMiniSiteFetching;
  const isCardLoading = isCreateLoading || isUpdateLoading;

  const submitFormHandler: (
    data: TrustMiniSiteCategoryFormValues,
    categoryId?: string,
  ) => void = (data, categoryId) => {
    categoryId && formState.prefilledData
      ? updateCategory({ ...formState.prefilledData, ...data, id: categoryId })
      : createCategory({ ...data, trust_id: trustId });
  };

  const closeFormHandler = (err?: AxiosError) => {
    setFormState({
      opened: false,
      prefilledData: undefined,
    });
    if (err) {
      errorToast(err);
    }
  };

  const openFormHandler = (categoryId?: string) => {
    if (categoryId) {
      const selectedCategory = trustMiniSite?.categories?.find(
        (category) => category.id === categoryId,
      );

      if (selectedCategory) {
        setFormState({
          opened: true,
          prefilledData: selectedCategory,
        });
      } else {
        // should never happen as category should exists after open is triggered
        errorToast('selected category not found');
      }
    } else {
      setFormState({
        opened: true,
        prefilledData: undefined,
      });
    }
  };

  const handleDeleteCategory = (value: string) => {
    const categoryToDelete = trustMiniSite?.categories?.find(
      (category) => category.id === value,
    );
    if (categoryToDelete) {
      confirm({
        onConfirm: () => deleteCategory(value),
        confirmButtonVariant: 'danger',
        title: t('confirm-delete-trust-mini-site-category'),
        icon: <FaTrashAlt />,
        status: 'delete',
        description: t('confirm-delete-trust-mini-site-category-desc', {
          name: categoryToDelete.name,
        }),
      });
    } else {
      // should never happen as category should exists after delete is triggered
      errorToast('selected category not found');
    }
  };

  return (
    <>
      <Col md={8}>
        <Card>
          <DialogFormWrapper>
            <TableActions>
              <CommonButton variant="primary" onClick={() => openFormHandler()}>
                {t('add-trust-mini-site-category')}
              </CommonButton>
              <CommonLink
                size="auto"
                variant="secondary"
                to={`${trustMiniSiteURL}/${trustId}`}
                target="_blank"
              >
                <span>
                  {t('open-mini-site')} <FaExternalLinkAlt />
                </span>
              </CommonLink>
            </TableActions>
            {categories?.count === 0 ? (
              <FormGroupTitle style={{ textAlign: 'center' }}>
                Mini site is not configured until at least one category is
                created.
              </FormGroupTitle>
            ) : (
              <TrustMiniSiteCategoriesTable
                trustId={trustId}
                categories={categories}
                openFormHandler={openFormHandler}
                handleDeleteCategory={handleDeleteCategory}
                isLoading={isTableLoading}
                isFetching={isTableFetching}
              />
            )}
          </DialogFormWrapper>
          {isTableFetching && <CardLoader fillWrapper={true} />}
        </Card>
      </Col>
      <Col md={4}>
        {formState.opened && trustMiniSite && (
          <TrustMiniSiteCategoryCard
            key={formState.prefilledData?.id}
            trustMiniSite={trustMiniSite}
            closeFormHandler={closeFormHandler}
            submitFormHandler={submitFormHandler}
            isLoading={isCardLoading}
            prefilledData={formState.prefilledData}
          />
        )}
      </Col>
    </>
  );
};
