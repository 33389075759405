import { CommonButton as Button } from 'components/common/Forms/Button';
import { RowActions } from 'components/Table/Table.styled';
import React, { ReactElement } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { ClinicalCommissioningGroup } from 'types/ccg';
import {
  GeneralPractice,
  GPFilters,
  GPResponseData,
} from 'types/generalPractices';
import { FetchedDataTable } from '../../../../Table/FetchedDataTable';
import { FiltersWithSet } from '../../../../../hooks/usePaginatedFilters';
import { getFilterControls } from '../../../../Table/TableUtils';
import { useGpColumns } from '../../../../../hooks/referrals/useGpColumns';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { Column } from 'react-table';

type Props = {
  gpData?: GPResponseData;
  ccgData?: ClinicalCommissioningGroup[] | null;
  openFormHandler: (value?: string) => void;
  handleDeleteGPSurgery: (value: string) => void;
  isLoading: boolean;
  isFetching: boolean;
} & FiltersWithSet<GPFilters>;

export const GPSurgeriesTable = ({
  gpData,
  ccgData,
  openFormHandler,
  handleDeleteGPSurgery,
  setFilters,
  isLoading,
  isFetching,
  filters,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  const columns = useGpColumns({
    ccgData,
    lastColumn: {
      ...getFilterControls({
        t,
        filters,
        setFilters,
      }),
      Cell: ({ value }: { value: string }) => {
        return (
          <RowActions>
            <Button
              size="auto"
              iconOnly
              variant="primary"
              title={t('edit-gp-surgery')}
              onClick={() => openFormHandler(value)}
            >
              <FaPencilAlt />
            </Button>
            <Button
              size="auto"
              iconOnly
              variant="danger"
              title={t('remove-gp-surgery')}
              onClick={() => handleDeleteGPSurgery(value)}
            >
              <FaTrashAlt />
            </Button>
          </RowActions>
        );
      },
    } as Column<GeneralPractice>,
  });

  return (
    <FetchedDataTable
      columns={columns}
      data={gpData}
      isLoading={isLoading}
      filters={filters}
      onSortOrPageChange={setFilters}
      isFetching={isFetching}
    />
  );
};
