import { AxiosError } from 'axios';
import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card, CardDesc, CardDescWrapper, CardTitle } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { ConfirmDialog } from 'components/Popup/ConfirmDialog/ConfirmDialog';
import { TableActions } from 'components/Table/Table.styled';
import BankHolidayForm from 'components/views-components/staff/settings/timeplanning/BankHolidayForm';
import { BankHolidayTable } from 'components/views-components/staff/settings/timeplanning/BankHolidayTable';
import { StaffSettingsTimePlanningTree } from 'configs/RoutesConfig';
import {
  useSiteBooleanAttributes,
  useSiteStringAttributes,
} from 'hooks/useAttribute';
import React, { useCallback, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { BankHolidayData, BankHolidayFormValues } from 'types/bankHoliday';
import { errorToast, successToast } from 'utils/toast';
import { formatDateOnlySite, parseIsoDate } from '../../../../utils/dateUtil';
import { StaffLayout } from '../../StaffLayout';
import { usePaginatedFilters } from '../../../../hooks/usePaginatedFilters';
import { GPFilters } from '../../../../types/generalPractices';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  useBankHolidays,
  useMutateCreateBankHoliday,
  useMutateDeleteBankHoliday,
  useMutateImportBankHoliday,
} from '../../../../query/bankHolidays';
import CheckboxInput from '../../../../components/common/Forms/CheckboxInput';
import {
  HOLIDAY_IMPORT_DATE,
  IMPORT_HOLIDAYS,
} from '../../../../configs/siteAndTrustAttributes';
import { useMutateUpdateSite } from '../../../../query/sites';
import { getSiteById } from '../../../../services/sites';
import { useSite } from '../../../../context/SiteContext';
import { SiteAttribute } from '../../../../types/sites';

export const StaffBankHolidays = () => {
  const { t } = useCommonTranslation();

  const { filters, setFilters } = usePaginatedFilters<GPFilters>();
  const {
    isLoading: isListLoading,
    isFetching: isListFetching,
    data,
  } = useBankHolidays(filters);
  const { isLoading: isCreateLoading, mutate: createBankHoliday } =
    useMutateCreateBankHoliday({
      onSuccess: () => {
        closeFormHandler();
      },
    });
  const { isLoading: isImportLoading, mutateAsync: importBankHolidays } =
    useMutateImportBankHoliday({
      onSuccess: () => {
        successToast(t('holiday-import-succes'));
        closeFormHandler();
      },
    });
  const { isLoading: isDeleteLoading, mutate: deleteItem } =
    useMutateDeleteBankHoliday({
      onSuccess: () => {
        successToast(t('holiday-deleted'));
        closeFormHandler();
      },
    });

  const [showForm, setShowForm] = useState(false);
  const [confirmDeleteItem, setConfirmDeleteItem] =
    useState<BankHolidayData | null>();

  const [holidayImportDate] = useSiteStringAttributes(HOLIDAY_IMPORT_DATE);
  const [importHolidays] = useSiteBooleanAttributes(IMPORT_HOLIDAYS);
  const openFormHandler = () => setShowForm(true);
  const closeFormHandler = (err?: AxiosError | true) => {
    setShowForm(false);
    setConfirmDeleteItem(null);
    if (err) {
      errorToast(err);
    }
  };

  const submitNewBankHolidayHandler: (data: BankHolidayFormValues) => void = (
    data,
  ) => {
    const structuredData: BankHolidayFormValues = {
      ...data,
      holiday_date: data.holiday_date,
    };

    createBankHoliday(structuredData);
  };

  const { activeSite, setActiveSite } = useSite();
  const handleImportBankHoliday = useCallback(async () => {
    await importBankHolidays();
    //This is needed in order to refresh the holidayImportDate attribute after import
    await setActiveSite((await getSiteById(activeSite.id)).data);
  }, [importBankHolidays, setActiveSite, activeSite]);

  const handleDeleteBankHoliday = useCallback(
    (value: string) => {
      closeFormHandler();
      setConfirmDeleteItem(
        data?.data?.find((bankHoliday) => bankHoliday.id === value),
      );
    },
    [data],
  );

  const handleConfirmDeleteBankHoliday = () => {
    deleteItem(confirmDeleteItem?.id as string);
  };

  const { mutateAsync: updateSite } = useMutateUpdateSite();
  const [isUpdateSiteLoading, setUpdateSiteLoading] = useState(false);
  const updateActiveSite = useCallback(
    async (autoImport: boolean) => {
      setUpdateSiteLoading(true);
      try {
        const autoImportAttribute: SiteAttribute = {
          key: IMPORT_HOLIDAYS,
          value_int: autoImport ? 1 : 0,
          value_str: autoImport ? 'true' : 'false',
        };
        const siteUpdate = {
          ...activeSite,
          attributes: [
            ...activeSite.attributes.filter((attr) => {
              return attr.key !== IMPORT_HOLIDAYS;
            }),
            autoImportAttribute,
          ],
        };
        await updateSite(siteUpdate);
        const successMessage = autoImport
          ? t('bank-holiday-auto-import-enabled')
          : t('bank-holiday-auto-import-disabled');
        if (!holidayImportDate && autoImport) {
          await handleImportBankHoliday();
        } else {
          await setActiveSite((await getSiteById(activeSite.id)).data);
        }
        successToast(successMessage);
      } catch (err) {
        errorToast(err);
      } finally {
        setUpdateSiteLoading(false);
      }
    },
    [
      activeSite,
      updateSite,
      setActiveSite,
      t,
      handleImportBankHoliday,
      holidayImportDate,
    ],
  );

  const isLoading =
    isCreateLoading ||
    isListLoading ||
    isDeleteLoading ||
    isImportLoading ||
    isUpdateSiteLoading;

  return (
    <StaffLayout>
      <PageHelmet title={t('bank-holidays')} />
      <Grid>
        <Col md={8}>
          <Card>
            <LinkCardTabs tabs={StaffSettingsTimePlanningTree(t)} />
            <CardTitle>{t('bank-holidays')}</CardTitle>
            <CardDescWrapper>
              <CardDesc>
                {t('bank-holiday-valid')}:{' '}
                {holidayImportDate
                  ? formatDateOnlySite(parseIsoDate(holidayImportDate))
                  : t('n-a')}
              </CardDesc>
              <CommonButton
                variant="secondary"
                onClick={handleImportBankHoliday}
                disabled={isLoading}
              >
                {t('import-calendar')}
              </CommonButton>
              <CheckboxInput
                labelPosition="before"
                isToggle={true}
                style={{ marginLeft: 'var(--s3)' }}
                name={'autoImport'}
                label={t('bank-holiday-auto-import')}
                disabled={isLoading}
                onCheckedStateChange={(autoImportValue) =>
                  updateActiveSite(autoImportValue)
                }
                checked={importHolidays}
              />
            </CardDescWrapper>
            <TableActions>
              <CommonButton variant="primary" onClick={() => openFormHandler()}>
                {t('add-new')}
              </CommonButton>
            </TableActions>
            <BankHolidayTable
              bankHolidayData={data}
              handleDeleteBankHoliday={handleDeleteBankHoliday}
              isLoading={
                isListLoading || isImportLoading || isUpdateSiteLoading
              }
              setFilters={setFilters}
              filters={filters}
              isFetching={isListFetching}
            />
          </Card>
        </Col>
        <Col md={4}>
          {showForm && (
            <BankHolidayForm
              closeFormHandler={closeFormHandler}
              submitHandler={submitNewBankHolidayHandler}
              isLoading={isLoading}
            />
          )}
        </Col>
      </Grid>
      {Boolean(confirmDeleteItem) && (
        <ConfirmDialog
          isLoading={isLoading}
          handleClose={() => closeFormHandler()}
          icon={<FaTrashAlt />}
          title={t('confirm-delete-holiday')}
          description={t('confirm-delete-holiday-desc')}
          status="delete"
          actions={[
            <CommonButton
              key={confirmDeleteItem?.id + '-cancel'}
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {t('cancel')}
            </CommonButton>,
            <CommonButton
              variant="danger"
              onClick={handleConfirmDeleteBankHoliday}
              key={confirmDeleteItem?.id + '-delete'}
            >
              {t('delete')}
            </CommonButton>,
          ]}
        />
      )}
    </StaffLayout>
  );
};
