import { useReferrerOptions } from '../../../utils/options';
import { useMutateAppointmentOverflow } from '../../../query/appointments';
import { useValidatePatientAgeWarningDialog } from './hooks/useValidatePatientAgeWarningDialog';
import { useCallback, useState } from 'react';
import { getReferrerIds } from '../../../components/views-components/staff/appointments/AppointmentReferrersUtil';
import { UserData } from '../../../types/authentication';
import { BookAppointmentForm } from '../../../components/views-components/staff/appointments/EditAppointmentData';
import { useDuplicateUserCheck } from '../../../hooks/user/useDuplicateUserCheck';
import { UserFormValues } from '../../../types/users';
import { useCreateUserMutation } from '../../../query/users';
import { successToast } from '../../../utils/toast';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { useSite } from '../../../context/SiteContext';
import { usePatientRole } from '../../../context/RoleContext';
import { useAllGPSurgeries } from '../../../hooks/referrals/useAllGPSurgeries';
import { useGPSurgeriesOptions } from '../../../hooks/referrals/useGPSurgeriesOptions';
import { combineDateAndTime, formatISO } from '../../../utils/dateUtil';

export type OverflowAppointmentForm = BookAppointmentForm & {
  apptDate?: string;
  apptTime?: string;
};

export type OverflowAppointmentWithUser = {
  user: UserData;
  appointment: OverflowAppointmentForm;
};

export const useOverflowWithPatientValidation = ({
  closeForm,
}: {
  closeForm: () => void;
}) => {
  const { t } = useCommonTranslation();
  const { isLoading: isOverflowProcessing, mutate: appointmentOverflow } =
    useMutateAppointmentOverflow({
      onSuccess: () => {
        successToast(t('appointment-booked-success'));
        closeForm();
      },
    });

  const { showSitePatientAgeWarningIfNeeded } =
    useValidatePatientAgeWarningDialog();
  const overflowPatientWithAgeValidation = useCallback(
    ({ user, appointment }: OverflowAppointmentWithUser) => {
      showSitePatientAgeWarningIfNeeded({
        onSuccess: () => {
          let appt_time: string | undefined = undefined;
          if (appointment.apptDate && appointment.apptTime)
            appt_time = formatISO(
              combineDateAndTime(appointment.apptDate, appointment.apptTime),
            );
          return appointmentOverflow({
            ...appointment,
            ...getReferrerIds(appointment, user),
            client_id: user.id,
            appt_time,
          });
        },
        user: user,
      });
    },
    [showSitePatientAgeWarningIfNeeded, appointmentOverflow],
  );

  const [showApptTimeSelect, setShowApptTimeSelect] = useState(false);

  return {
    overflowPatientWithAgeValidation,
    isOverflowProcessing,
    showApptTimeSelect,
    setShowApptTimeSelect,
  };
};

export const useOverflowWithPatient = ({
  closeForm,
}: {
  closeForm: () => void;
}) => {
  const { isLoading: isReferrersLoading, referrerOptions } =
    useReferrerOptions();

  const { isLoading: isSurgeriesLoading, data: surgeriesResponse } =
    useAllGPSurgeries();

  const { isOverflowProcessing, ...overflowRest } =
    useOverflowWithPatientValidation({ closeForm });

  return {
    referrerOptions,
    surgeriesResponse,
    isOverflowProcessing,
    isLoading: isReferrersLoading || isOverflowProcessing || isSurgeriesLoading,
    ...overflowRest,
  };
};

export const useOverflowNoPatient = ({
  closeForm,
}: {
  closeForm: () => void;
}) => {
  const { isLoading: isReferrersLoading, referrerOptions } =
    useReferrerOptions();

  const { isLoading: isSurgeriesLoading, gpSurgeriesOptions: surgeryOptions } =
    useGPSurgeriesOptions({});

  const {
    isOverflowProcessing,
    overflowPatientWithAgeValidation,
    ...overflowRest
  } = useOverflowWithPatientValidation({ closeForm });

  const { t } = useCommonTranslation();
  const { activeSite } = useSite();
  const { id: patientRoleId } = usePatientRole();
  const { mutateAsync: createUserAsync, isLoading: isCreateUserLoading } =
    useCreateUserMutation({
      onSuccess: () => {
        successToast(t('patient-created'));
      },
    });

  const createUserAndOverflow = useCallback<
    (params: { appointment: BookAppointmentForm; user: UserFormValues }) => any
  >(
    async ({ appointment, user }) => {
      const savedUser = await createUserAsync({
        ...user,
        active_site_id: activeSite.id,
        role_id: patientRoleId,
      });
      await overflowPatientWithAgeValidation({
        user: savedUser.data,
        appointment: appointment,
      });
      closeForm();
    },
    [
      activeSite,
      overflowPatientWithAgeValidation,
      createUserAsync,
      patientRoleId,
      closeForm,
    ],
  );

  const {
    duplicateUsers,
    resolveAsDuplicate: duplicateResolveSelect,
    resolveAsNOTDuplicate: duplicateResolveCreateNew,
    actionWithDuplicateCheck: createAndOverflowPatientWithValidation,
  } = useDuplicateUserCheck<{
    user: UserFormValues;
    appointment: BookAppointmentForm;
  }>({
    onResolvedAsDuplicate: async (params) => {
      await overflowPatientWithAgeValidation(params);
    },
    onResolvedAsNOTDuplicate: (params) => {
      createUserAndOverflow(params);
    },
  });
  return {
    duplicateUsers,
    duplicateResolveSelect,
    duplicateResolveCreateNew,
    createAndOverflowPatientWithValidation,
    referrerOptions,
    surgeryOptions,
    isLoading:
      isReferrersLoading ||
      isOverflowProcessing ||
      isSurgeriesLoading ||
      isCreateUserLoading,
    ...overflowRest,
  };
};
