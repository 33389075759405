import { CommonButton } from 'components/common/Forms/Button';
import { ControlledDatePickerInput } from 'components/common/Forms/controlled/ControlledDatePickerInput';
import { ControlledSelect } from 'components/common/Forms/controlled/ControlledSelect';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { range } from 'lodash';
import { useReports } from 'query/reports';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { DATE_FNS_UK_DATE_FORMAT, formatDateFromISO } from 'utils/dateUtil';
import { DateCol, ReportSelectedDate, ReportsHeader } from './Reports.styled';
import { Report, REPORT_IDS } from 'types/reports';
import { ReportsLayout } from './ReportsLayout';
import { BulletTable } from 'components/BulletTable/BulletTable';
import { useSelectFromArrayViaId } from 'hooks/useSelectFromArrayViaId';
import { useReportsValidation } from './ReportsValidation';
import { ReportInfo } from './ReportInfo';
import { FaEye } from 'react-icons/fa';
import { useGenerateReport } from '../../../hooks/reports/useGenerateReport';
import { Loading } from '../../../components/common/Loading';

type ReportsFormValues = {
  fromDate: string;
  fromHour: number;
  toDate: string;
  toHour: number;
  report?: Report;
};

const hourOptions = range(0, 24).map((hour) => ({
  key: hour,
  label: String(hour),
  value: hour,
}));

export const Reports = () => {
  const { t } = useCommonTranslation();
  const { data: reportsResponse, isFetching } = useReports();

  const reports = useMemo(
    () =>
      reportsResponse?.data.data
        .filter((report) => report.id !== REPORT_IDS.DNA_REPORT)
        ?.map((report) => ({
          translatedLabel: t(`report_${report.id}`, {
            defaultValue: report.name,
          }),
          ...report,
        }))
        .sort((a, b) =>
          a.translatedLabel > b.translatedLabel
            ? 1
            : b.translatedLabel > a.translatedLabel
            ? -1
            : 0,
        ),
    [reportsResponse, t],
  );

  const { selectedId, selected, setSelectedId } = useSelectFromArrayViaId({
    items: reports,
  });

  const validateResolver = useReportsValidation();
  const {
    control,
    formState,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ReportsFormValues>({
    resolver: validateResolver,
  });
  const values = watch();
  const { onGenerate, isGenerating } = useGenerateReport({ ...values });

  return (
    <ReportsLayout
      isLoading={isFetching}
      title={t('reports')}
      leftColMD={8}
      rightColMD={4}
      rightCardTitle={selected?.translatedLabel}
      onRightCardClose={() => setSelectedId(undefined)}
      leftCardNode={
        <>
          <form>
            <ReportsHeader>
              <DateCol>
                <ControlledDatePickerInput
                  control={control}
                  formState={formState}
                  rules={{
                    required: t('must-not-empty') as string,
                  }}
                  helperText={errors.fromDate?.message}
                  hasError={Boolean(errors.fromDate)}
                  name="fromDate"
                  label={t('date-from')}
                />
                <ControlledSelect
                  control={control}
                  formState={formState}
                  name="fromHour"
                  label={t('hour-from')}
                  options={hourOptions}
                  noSpaceForHelperText
                />
              </DateCol>
              <DateCol>
                <ControlledDatePickerInput
                  control={control}
                  formState={formState}
                  rules={{
                    required: t('must-not-empty') as string,
                  }}
                  name="toDate"
                  label={t('date-to')}
                />
                <ControlledSelect
                  control={control}
                  formState={formState}
                  name="toHour"
                  label={t('hour-to')}
                  options={hourOptions}
                  noSpaceForHelperText
                />
              </DateCol>
              {values.fromDate && values.toDate && (
                <ReportSelectedDate>
                  {t('report-selected-date-range', {
                    dateFrom: formatDateFromISO(
                      values.fromDate,
                      DATE_FNS_UK_DATE_FORMAT,
                    ),
                    dateTo: formatDateFromISO(
                      values.toDate,
                      DATE_FNS_UK_DATE_FORMAT,
                    ),
                  })}
                </ReportSelectedDate>
              )}
              <CommonButton
                variant="danger"
                onClick={() => reset()}
                type="button"
              >
                {t('clear')}
              </CommonButton>
            </ReportsHeader>
          </form>
          {isGenerating && (
            <Loading
              text={t('report-generating')}
              style={{
                width: '100%',
              }}
            />
          )}
          <BulletTable
            headers={[{ text: t('report-name') }]}
            body={reports?.map((report) => ({
              selected: selectedId === report.id,
              cells: [
                {
                  bold: true,
                  text: report.translatedLabel,
                },
                {
                  btn: true,
                  text: (
                    <CommonButton
                      type="button"
                      variant="primary"
                      iconOnly
                      onClick={() => setSelectedId(report.id)}
                    >
                      <FaEye title={t('info')} />
                    </CommonButton>
                  ),
                },
                {
                  btn: true,
                  text: (
                    <CommonButton
                      type="button"
                      variant="primary"
                      size="large"
                      disabled={isGenerating}
                      onClick={async () => {
                        setValue('report', report);
                        await handleSubmit(() => onGenerate(report))();
                      }}
                    >
                      {t('generate-report')}
                    </CommonButton>
                  ),
                },
              ],
            }))}
          />
        </>
      }
      rightCardNode={selectedId && <ReportInfo reportId={selectedId} />}
    />
  );
};
