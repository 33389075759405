import React from 'react';
import { AppointmentCard } from '../../../components/views-components/staff/appointments/AppointmentCard';
import { FormBody } from '../../../components/common/Forms/Forms.styled';
import { useForm } from 'react-hook-form';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { EditAppointmentData } from '../../../components/views-components/staff/appointments/EditAppointmentData';
import { UserData } from '../../../types/authentication';
import { CommonButton as Button } from '../../../components/common/Forms/Button';
import { ButtonSetWithCloseButton } from '../../../components/Form/ButtonSetWithCloseButton';
import { FaUserClock } from 'react-icons/fa';
import { PatientReadOnlyDetails } from '../../common/patient/PatientReadOnlyDetails';
import { PatientAppointmentsAndEditBlock } from '../../../components/views-components/staff/appointments/patient/PatientAppointmentsAndEditBlock';
import {
  EDIT_PATIENT_MODE,
  PATIENT_APPOINTMENTS_LIST_MODE,
  usePatientAppointments,
} from '../../../hooks/staff/usePatientAppointments';
import { PatientAppointmentsAndEditPatientControl } from '../appointments/PatientAppointmentsAndEditPatientControl';
import { CardSubtitle } from '../../../components/common';
import {
  OverflowAppointmentForm,
  useOverflowWithPatient,
} from './overflowHooks';
import { OverflowTimeSelect } from './OverflowTimeSelect';

type IAppointmentFormProps = {
  closeForm: () => void;
  selectedUser: UserData;
};

type BookOverflowAppointmentForm = {
  appointment: OverflowAppointmentForm;
};

export const BookOverflowWithPatient: React.FunctionComponent<
  IAppointmentFormProps
> = ({ closeForm, selectedUser }) => {
  const { t } = useCommonTranslation();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BookOverflowAppointmentForm>();

  const {
    overflowPatientWithAgeValidation,
    surgeriesResponse,
    referrerOptions,
    isLoading: isOverflowLoading,
    setShowApptTimeSelect,
    showApptTimeSelect,
  } = useOverflowWithPatient({ closeForm });

  const {
    mode,
    isLoading: isUserAppointmentsLoading,
    bookedAppointments,
    cancelledAppointments,
    pastAppointments,
    setExistingAppointmentMode,
    ...rest
  } = usePatientAppointments({
    user: selectedUser,
  });

  const isLoading = isOverflowLoading || isUserAppointmentsLoading;

  return (
    <>
      {mode === 'closed' ? (
        <AppointmentCard
          isLoading={isLoading}
          title={t('appointment-detail-title')}
          onCloseClicked={closeForm}
          icon={<FaUserClock />}
        >
          <FormBody>
            <PatientReadOnlyDetails
              user={selectedUser}
              surgeries={surgeriesResponse}
              subtitleLabel={t('appointment-book-patient-information')}
            />
            <PatientAppointmentsAndEditBlock
              user={selectedUser}
              onPatientButtonClicked={(type) =>
                type === 'userEdit'
                  ? setExistingAppointmentMode(EDIT_PATIENT_MODE)
                  : setExistingAppointmentMode(PATIENT_APPOINTMENTS_LIST_MODE)
              }
            />
            <CardSubtitle>{t('appointment-detail-information')}</CardSubtitle>
            <EditAppointmentData
              register={register}
              control={control}
              errors={errors}
              referrers={referrerOptions}
            />
            {showApptTimeSelect && (
              <OverflowTimeSelect
                register={register}
                control={control}
                errors={errors}
              />
            )}
            <ButtonSetWithCloseButton onCloseClicked={closeForm}>
              <Button
                variant="primary"
                type={'button'}
                onClick={async () => {
                  await handleSubmit((data) => {
                    overflowPatientWithAgeValidation({
                      user: selectedUser,
                      appointment: data.appointment,
                    });
                  })();
                }}
              >
                {showApptTimeSelect
                  ? t('save')
                  : t('appointment-details-save-and-queue')}
              </Button>
              {!showApptTimeSelect && (
                <Button
                  variant="secondary"
                  type={'button'}
                  onClick={() => setShowApptTimeSelect(true)}
                >
                  {t('appointment-overflow-book-for-later')}
                </Button>
              )}
            </ButtonSetWithCloseButton>
          </FormBody>
        </AppointmentCard>
      ) : (
        <PatientAppointmentsAndEditPatientControl
          mode={mode}
          setExistingAppointmentMode={setExistingAppointmentMode}
          bookedAppointments={bookedAppointments}
          pastAppointments={pastAppointments}
          cancelledAppointments={cancelledAppointments}
          isLoading={isUserAppointmentsLoading}
          user={selectedUser}
          {...rest}
        />
      )}
    </>
  );
};
