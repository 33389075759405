import { useTrustsByIds } from '../../query/trusts';
import { UserData, UserProfileResponse } from '../../types/authentication';
import { getPatientTrustIds } from '../../utils/patient';

export const usePatientTrusts = (user?: UserProfileResponse | UserData) => {
  const trustIds = user && getPatientTrustIds(user);
  const { data: trusts, isLoading } = useTrustsByIds(trustIds);
  return {
    trusts,
    isLoading,
  };
};
