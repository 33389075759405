import { Appointment } from '../../../types/appointments';
import { useDayAvailableAppointments } from '../../appointments/useDayAvailableAppointments';
import { useEffect, useState } from 'react';
import { DateOnlyISOString, parseIsoDate } from '../../../utils/dateUtil';

const WILL_NOT_BE_USED_AS_QUERY_WILL_NOT_BE_ENABLED = '2020-01-01';
export const usePatientBookingFirstAppointments = ({
  availableDaysArray,
  idealNumberToShow,
}: {
  availableDaysArray?: DateOnlyISOString[];
  idealNumberToShow: number;
}) => {
  const [firstApptsAvailable, setFirstApptsAvailable] = useState<Appointment[]>(
    [],
  );
  const [availableIndexSearching, setAvailableIndexSearching] = useState(0);
  useEffect(() => {
    setFirstApptsAvailable([]);
    setAvailableIndexSearching(0);
  }, [availableDaysArray, setAvailableIndexSearching, setFirstApptsAvailable]);

  const shouldSearch =
    availableDaysArray &&
    firstApptsAvailable.length < idealNumberToShow &&
    availableIndexSearching < availableDaysArray.length;

  useDayAvailableAppointments(
    parseIsoDate(
      availableDaysArray?.[availableIndexSearching] ||
        WILL_NOT_BE_USED_AS_QUERY_WILL_NOT_BE_ENABLED,
    ),
    {
      enabled: shouldSearch,
      onSuccess: ({ data }) => {
        const apptsAvailable = firstApptsAvailable;
        data.data.forEach((foundAppointment) => {
          if (
            apptsAvailable.length < idealNumberToShow &&
            !apptsAvailable.some(
              ({ appt_time }) => appt_time === foundAppointment.appt_time,
            )
          ) {
            apptsAvailable.push(foundAppointment);
          }
        });
        setFirstApptsAvailable(apptsAvailable);
        if (apptsAvailable.length < idealNumberToShow) {
          setAvailableIndexSearching(availableIndexSearching + 1);
        }
      },
    },
  );
  return {
    firstApptsAvailable,
  };
};
