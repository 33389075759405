import { Card } from 'components/common';
import { Col, Grid } from 'components/common/Grid';
import { PageHelmet } from 'components/common/PageHelmet';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { AdminLayout } from './AdminLayout';
import {
  AdminAuditApptHeader,
  AuditDateRange,
} from './audit/AdminAuditApptHeader';
import React, { useState } from 'react';
import { AdminAuditApptTable } from './audit/AdminAuditApptTable';
import { useExistingAppointments } from '../../query/appointments';
import { successToast } from '../../utils/toast';
import { AppointmentAuditTableCard } from '../common/audit/AppointmentAuditTableCard';
import { useSelectFromArrayViaId } from '../../hooks/useSelectFromArrayViaId';
import { usePaginatedFilters } from '../../hooks/usePaginatedFilters';
import { AppointmentFilters } from '../../types/appointments';

export const AuditPage = () => {
  const { t } = useCommonTranslation();

  const [auditDateRange, setAuditDateRange] = useState<AuditDateRange>();

  const { filters, setFilters } = usePaginatedFilters<AppointmentFilters>();
  const {
    data: appointmentsData,
    isLoading: isAppointmentsLoading,
    isFetching: isAppointmentsFetching,
    refetch,
  } = useExistingAppointments(
    {
      ...filters,
      fromDate: auditDateRange?.from,
      toDate: auditDateRange?.to,
      statuses: [
        'TAKEN',
        'NOT_TAKEN',
        'MIA',
        'QUEUE',
        'PROCESSING',
        'CALLED',
        'BOOKED',
        'DNA',
        'CANCELLED',
        'NOT_PREPARED',
        'LOCKED',
      ],
    },
    {
      enabled: Boolean(auditDateRange),
      onSuccess: () => {
        successToast(t('appointments-loaded-success'));
      },
    },
  );
  const { setSelected: setSelectedAppointment, selected: selectedAppointment } =
    useSelectFromArrayViaId({
      items: appointmentsData?.data.data,
    });

  return (
    <AdminLayout>
      <PageHelmet title={t('audit')} />
      <Grid>
        <Col md={6}>
          <Card flex>
            <AdminAuditApptHeader
              onFindClicked={(range) => {
                if (
                  auditDateRange?.from === range.from &&
                  auditDateRange?.to === range.to
                ) {
                  refetch();
                } else setAuditDateRange(range);
              }}
            />
            <AdminAuditApptTable
              appointments={appointmentsData?.data}
              onAppointmentInfoClicked={setSelectedAppointment}
              filters={filters}
              setFilters={setFilters}
              isLoading={isAppointmentsLoading}
              isFetching={isAppointmentsFetching}
            />
          </Card>
        </Col>
        {selectedAppointment && (
          <Col md={6}>
            <AppointmentAuditTableCard
              selectedAppointment={selectedAppointment}
              onCloseClicked={() => setSelectedAppointment(undefined)}
            />
          </Col>
        )}
      </Grid>
    </AdminLayout>
  );
};
