import { PatientReadOnlyDetails } from '../../common/patient/PatientReadOnlyDetails';
import { PatientAppointmentsAndEditBlock } from '../../../components/views-components/staff/appointments/patient/PatientAppointmentsAndEditBlock';
import React from 'react';
import { UserData } from '../../../types/authentication';
import { Card } from '../../../components/common';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import {
  EDIT_PATIENT_MODE,
  PATIENT_APPOINTMENTS_LIST_MODE,
  usePatientAppointments,
} from '../../../hooks/staff/usePatientAppointments';
import { PatientAppointmentsAndEditPatientControl } from '../appointments/PatientAppointmentsAndEditPatientControl';
import { CardLoader } from '../../../components/common/Loading';
import { useAllGPSurgeries } from '../../../hooks/referrals/useAllGPSurgeries';
import { CloseButton } from '../../../components/common/Forms/Forms.styled';
import { FaTimes } from 'react-icons/fa';

interface PatientDetailProps {
  patient: UserData;
  onPatientSelectButtonClicked?: () => void;
  onPatientCloseButtonClicked?: () => void;
}

export const PatientDetailCard: React.FC<PatientDetailProps> = ({
  patient,
  onPatientSelectButtonClicked,
  onPatientCloseButtonClicked,
}) => {
  const { t } = useCommonTranslation();

  const { isLoading: isSurgeriesLoading, data: surgeriesResponse } =
    useAllGPSurgeries();
  const {
    mode,
    bookedAppointments,
    pastAppointments,
    cancelledAppointments,
    setExistingAppointmentMode,
    isLoading: isAppointmentsLoading,
    ...rest
  } = usePatientAppointments({
    user: patient,
  });
  const isLoading = isAppointmentsLoading || isSurgeriesLoading;

  return (
    <>
      {mode === 'closed' ? (
        <Card>
          {onPatientCloseButtonClicked && (
            <CloseButton
              size="auto"
              variant="primary"
              style={{
                top: 'var(--s3)',
                right: 'var(--s3)',
              }}
              iconOnly
              onClick={onPatientCloseButtonClicked}
            >
              <FaTimes />
            </CloseButton>
          )}
          {isLoading ? (
            <CardLoader />
          ) : (
            <>
              <PatientReadOnlyDetails
                user={patient}
                surgeries={surgeriesResponse}
                subtitleLabel={t('appointment-book-patient-information')}
              />

              <PatientAppointmentsAndEditBlock
                user={patient}
                onPatientButtonClicked={(type) =>
                  type === 'userEdit'
                    ? setExistingAppointmentMode(EDIT_PATIENT_MODE)
                    : setExistingAppointmentMode(PATIENT_APPOINTMENTS_LIST_MODE)
                }
                onPatientSelectButtonClicked={onPatientSelectButtonClicked}
              />
            </>
          )}
        </Card>
      ) : (
        <PatientAppointmentsAndEditPatientControl
          mode={mode}
          setExistingAppointmentMode={setExistingAppointmentMode}
          bookedAppointments={bookedAppointments}
          pastAppointments={pastAppointments}
          cancelledAppointments={cancelledAppointments}
          user={patient}
          isLoading={isAppointmentsLoading}
          firstSelected={'booked'}
          {...rest}
        />
      )}
    </>
  );
};
