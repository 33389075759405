import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppointmentsResponseData,
  UserAppointmentsTypes,
} from '../../types/appointments';
import {
  PATIENT_APPOINTMENT_DETAIL_MODE,
  PATIENT_APPOINTMENTS_LIST_MODE,
  usePatientAppointments,
} from './usePatientAppointments';
import { useUser } from '../../query/users';

const findAppointment = (
  appointmentsData?: AppointmentsResponseData,
  appointmentId?: string,
) => {
  return appointmentId
    ? appointmentsData?.data.find((a) => a.id === appointmentId)
    : undefined;
};

export type UserAppointmentSelect = {
  id: string;
  type: UserAppointmentsTypes;
  user_account_id?: string;
};

export const useFlowEditAppointment = ({
  appointmentsData,
}: {
  appointmentsData?: AppointmentsResponseData;
}) => {
  const [editAppointmentId, setEditAppointmentId] = useState<string>();
  const [isInFlow, setIsInFlow] = useState(true);

  const editFlowAppointment = useMemo(() => {
    return findAppointment(appointmentsData, editAppointmentId);
  }, [appointmentsData, editAppointmentId]);

  const { data: userData, isLoading: isUserLoading } = useUser(
    editFlowAppointment?.user_member_id,
  );

  const {
    appointment: selectedUserAppointment,
    isLoading: patientAppointmentsLoading,
    setSelectedFromUserAppointments,
    mode,
    close,
    setMode,
    ...patientAppointmentsRest
  } = usePatientAppointments({ user: userData?.data });

  const closeAndDeselect = useCallback(() => {
    setEditAppointmentId(undefined);
    close();
  }, [close, setEditAppointmentId]);

  useEffect(
    () => setSelectedFromUserAppointments(undefined),
    [editAppointmentId, setSelectedFromUserAppointments],
  );

  const appointment = selectedUserAppointment || editFlowAppointment;

  useEffect(() => {
    if (appointment && findAppointment(appointmentsData, appointment.id)) {
      setEditAppointmentId(appointment.id);
      setIsInFlow(true);
    } else {
      setIsInFlow(false);
    }
  }, [appointment, appointmentsData]);

  const setOrToggleEditFlowAppointmentID = useCallback(
    (appointmentId?: string) => {
      setIsInFlow(true);
      if (
        mode === PATIENT_APPOINTMENT_DETAIL_MODE &&
        appointment &&
        appointment.id === appointmentId
      ) {
        closeAndDeselect();
      } else {
        setMode(PATIENT_APPOINTMENT_DETAIL_MODE);
        setEditAppointmentId(appointmentId);
      }
    },
    [appointment, setEditAppointmentId, closeAndDeselect, setMode, mode],
  );

  return {
    user: userData?.data,
    appointment,
    setOrToggleEditFlowAppointmentID,
    close: closeAndDeselect,
    isLoading: isUserLoading || patientAppointmentsLoading,
    mode,
    editAppointmentId:
      mode !== PATIENT_APPOINTMENTS_LIST_MODE && isInFlow
        ? editAppointmentId
        : undefined,
    ...patientAppointmentsRest,
  };
};
