import { isEqual, omit } from 'lodash';
import { useEffect, useMemo } from 'react';
import { TFunction } from 'react-i18next';
import {
  Column,
  ColumnInstance,
  Filters,
  SortingRule,
  TableHeaderProps,
  useBlockLayout,
  useFilters,
  useResizeColumns,
  UseResizeColumnsState,
  useSortBy,
  useTable,
} from 'react-table';
import {
  AppTableResetProps,
  IObjectWithId,
  IObjectWithIdAndSelected,
  ISelectedIds,
  ISortFilters,
  OptionalIOffsetLimitFilters,
} from '../../types/table';
import { stripObjFalsyValues } from '../../utils/helpers';
import { getColumnFilterKey, getColumnSortKey } from '../../utils/table';
import { CommonButton as Button } from '../common/Forms/Button';
import { RowActions } from './Table.styled';
import {
  ColumnWidths,
  useStoredColumnResizing,
} from '../../hooks/storage/useStoredColumnResizing';
import { errorToast } from '../../utils/toast';

export const useHasFilters = <D extends object>(columns: Column<D>[]) => {
  return useMemo(() => {
    return columns.some((column) => column.Filter);
  }, [columns]);
};

export const getStyles = (
  props: Partial<TableHeaderProps>,
  align = 'left',
  style = {},
) => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      ...style,
    },
  },
];

export const TABLE_WIDTH_MULTIPLIER = 8;

export const DEFAULT_COLUMN_WIDTH_DATE = 12 * TABLE_WIDTH_MULTIPLIER;
export const DEFAULT_COLUMN_WIDTH = 120;

const DEFAULT_COLUMN = {
  minWidth: 30,
  maxWidth: 450,
  canFilter: false,
};

export const useBasicSangixTable = <D extends IObjectWithId>({
  columns,
  data,
  isLoading,
  resizingKey,
}: {
  columns: Column<D>[];
  data?: D[];
  isLoading?: boolean;
  resizingKey?: string;
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state: { columnResizing },
  } = useTable(
    {
      columns,
      data: data || [],
      defaultColumn: DEFAULT_COLUMN,
      manualFilters: true,
      manualSortBy: true,
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
  );

  useRememberTableResizing({ allColumns, columnResizing, resizingKey });

  return {
    tableProps: {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      columns,
      isLoading,
    },
  };
};

const getSortByFromFilters = <
  K extends Required<OptionalIOffsetLimitFilters> & ISortFilters,
>(
  currentFilters: K,
) =>
  currentFilters?.sortField
    ? {
        sortBy: [
          {
            id: currentFilters.sortField,
            desc: currentFilters?.sortDirection === 'desc' || false,
          },
        ],
      }
    : undefined;

export const useSangixTable = <
  D extends IObjectWithId,
  K extends Required<OptionalIOffsetLimitFilters> & ISortFilters,
>({
  columns,
  data,
  isLoading,
  currentFilters,
  resizingKey,
  selectedIds,
}: {
  columns: Column<D>[];
  data?: D[];
  isLoading?: boolean;
  onSortChange?: () => any;
  currentFilters: K;
  resizingKey?: string;
} & ISelectedIds) => {
  const initialState = useMemo(
    () => getSortByFromFilters(currentFilters),
    [currentFilters],
  );
  const dataWithIsSelected: Array<D & IObjectWithIdAndSelected> =
    useMemo(() => {
      return (
        data?.map((item) => {
          // @ts-ignore
          if (item.isSelected) {
            errorToast('isSelected property is reserved for table component');
          }
          return {
            ...item,
            isSelected: selectedIds?.includes(item.id),
          };
        }) || []
      );
    }, [data, selectedIds]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state: { sortBy, filters: tableFiltersState, columnResizing },
    setAllFilters,
  } = useTable(
    {
      columns,
      data: dataWithIsSelected,
      defaultColumn: DEFAULT_COLUMN,
      manualFilters: true,
      manualSortBy: true,
      initialState: initialState,
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
  );

  useRememberTableResizing({ allColumns, columnResizing, resizingKey });

  useUpdateFiltersBasedOnIncomingFilters({
    currentFilters,
    columns,
    tableFiltersState,
    setAllFilters,
  });

  const structuredFilters = useMemo(() => {
    return getStructuredFiltersAndSortAndPagination({
      newFilters: tableFiltersState,
      sortBy,
      filters: currentFilters,
    });
  }, [sortBy, tableFiltersState, currentFilters]);

  return {
    tableProps: {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      columns,
      isLoading,
    },
    sortBy,
    structuredFilters,
  };
};

const useUpdateFiltersBasedOnIncomingFilters = <
  D extends object,
  K extends Required<OptionalIOffsetLimitFilters> & ISortFilters,
>({
  columns,
  currentFilters,
  tableFiltersState,
  setAllFilters,
}: {
  currentFilters: K;
  columns: Column<D>[];
  tableFiltersState: Filters<D>;
  setAllFilters: (filters: Filters<D>) => any;
}) => {
  const changeCheck = JSON.stringify(currentFilters);
  useEffect(() => {
    const tableFilterStateExpected = convertToTableFilterState(
      currentFilters,
      columns,
    );
    if (!isEqual(tableFilterStateExpected, tableFiltersState)) {
      setAllFilters(tableFilterStateExpected);
    }
    /**
     * The table keeps it's own filter state and that is propragated to the
     * incoming filters by callback when "find" is clicked.
     * However we want to be able to update the table filters from outside
     * for example for filter buttons outside the table.For that purpose
     * this function will update internal filter state when incoming filters
     * change. If the change is based on "find" click, then incoming filters
     * will be same and not update will happen.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCheck]);
};

const convertToTableFilterState = <
  D extends object,
  K extends Required<OptionalIOffsetLimitFilters> & ISortFilters,
>(
  currentFilters: K,
  columns: Column<D>[],
) => {
  const filterKeys = Object.keys(currentFilters).filter(
    (key) =>
      key !== 'sortField' &&
      key !== 'sortDirection' &&
      key !== 'limit' &&
      key !== 'offset',
  );
  const columnIds = columns
    .filter((column) => column.disableFilters !== true)
    .map((column) => column.id || column.accessor)
    .reduce((acc, curr) => {
      if (typeof curr !== 'string') return acc;
      const filterId = getColumnFilterKey(curr as string);
      return {
        ...acc,
        [filterId]: curr,
      };
    }, {}) as { [key: string]: string };

  return filterKeys
    .filter((key) => !!columnIds[key])
    .map((key) => {
      const filterValue = currentFilters[key as keyof K];
      const filterKey = columnIds[key];
      return {
        id: filterKey,
        value: filterValue,
      };
    });
};

export const getStructuredFiltersAndSortAndPagination = <
  D extends object,
  K extends Required<OptionalIOffsetLimitFilters>,
>({
  newFilters,
  sortBy,
  filters,
}: {
  newFilters: Filters<D>;
  sortBy: Array<SortingRule<D>>;
  filters: K;
}) => {
  const newSortAndFilters = stripObjFalsyValues({
    ...newFilters.reduce((acc: {}, curr) => {
      return {
        ...acc,
        [getColumnFilterKey(curr.id)]: curr.value,
      };
    }, {}),
    ...sortBy.reduce((acc, curr) => {
      return {
        sortField: getColumnSortKey(curr.id),
        sortDirection: curr.desc ? 'desc' : 'asc',
      };
    }, {}),
  });
  if (
    isEqual(omit(filters, ['limit', 'offset', 'pageLimit']), newSortAndFilters)
  ) {
    return {
      ...newSortAndFilters,
      limit: filters.limit,
      offset: filters.offset,
      pageLimit: filters.pageLimit,
    };
  } else {
    /**
     * If filters have changed reset offset
     */
    return {
      ...newSortAndFilters,
      limit: filters.limit,
      offset: 0,
      pageLimit: filters.pageLimit,
    };
  }
};

export const tableRightHandSquishColumn = ({
  minWidth = 11,
  maxWidth = 'unset',
}: {
  minWidth?: number | string;
  maxWidth?: number | string;
}) => ({
  Header: '',
  disableResizing: true,
  align: 'right',
  disableFilters: true,
  minWidth:
    typeof minWidth === 'number' ? minWidth * TABLE_WIDTH_MULTIPLIER : minWidth,
  maxWidth:
    typeof maxWidth === 'number' ? maxWidth * TABLE_WIDTH_MULTIPLIER : maxWidth,
  cellStyle: {
    padding: 0,
    flex: 1,
  },
  style: {
    flex: 1,
  },
  disableSortBy: true,
});

export const getFilterControls = <
  K extends Required<OptionalIOffsetLimitFilters>,
>({
  filters,
  t,
  setFilters,
  minWidth,
  maxWidth,
  renderControlsOnSecondRow = false,
  renderControlsWithOverflow = false,
}: {
  t: TFunction<'common'>;
  filters: K; //Not available from table component - separate cmp was implemented
  setFilters: (filters: K) => any;
  minWidth?: number | string;
  maxWidth?: number | string;
  renderControlsOnSecondRow?: boolean;
  renderControlsWithOverflow?: boolean;
}) => {
  return {
    accessor: 'id',
    ...tableRightHandSquishColumn({ minWidth, maxWidth }),
    columnControls: ({
      setAllFilters,
      state: { filters: newFilters, sortBy },
    }: AppTableResetProps<any>) => (
      <RowActions
        style={{
          paddingBottom: 'var(--s1)',
          paddingTop: 'var(--s1)',
        }}
      >
        <Button
          variant="primary"
          onClick={() =>
            setFilters(
              getStructuredFiltersAndSortAndPagination({
                newFilters,
                sortBy,
                filters,
              }) as K,
            )
          }
        >
          {t('find')}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            setAllFilters([]);
            setFilters(
              getStructuredFiltersAndSortAndPagination({
                newFilters: [],
                sortBy: getSortByFromFilters(filters)?.sortBy || [],
                filters,
              }) as K,
            );
          }}
        >
          {t('clear')}
        </Button>
      </RowActions>
    ),
    renderOnSecondRow: renderControlsOnSecondRow,
    renderWithOverflow: renderControlsWithOverflow,
  };
};

const useRememberTableResizing = <D extends object>({
  allColumns,
  columnResizing,
  resizingKey,
}: {
  allColumns: ColumnInstance<D>[];
  columnResizing: UseResizeColumnsState<D>['columnResizing'];
  /**
   * Can be set to differentiate resizing storage for tables
   * with same set of columns and widths.
   * It is used as prefix for generated id based on columns
   * names and widths.
   */
  resizingKey?: string;
}) => {
  const generatedResizingId = useMemo(() => {
    return allColumns
      .map((column) => `${column.id}--${column.totalWidth}`)
      .join('__');
    // This is needed workaround to create the generated resizing ID only
    // on first render to have the initial untouched column widths for stable ID.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const resizingId =
    typeof resizingKey === 'string'
      ? `${resizingKey}__${generatedResizingId}`
      : generatedResizingId;

  const {
    resizedColumnsWidths: storedResizedColumnsWidths,
    setResizedColumWidths: setStoredResizedColumWidths,
  } = useStoredColumnResizing(resizingId);

  useEffect(() => {
    if (columnResizing.isResizingColumn) {
      setStoredResizedColumWidths((prevResizedColumnsWidths) => ({
        ...prevResizedColumnsWidths,
        // Omit undefined widths. They appear at the start of resizing.
        ...Object.fromEntries(
          Object.entries(columnResizing.columnWidths as ColumnWidths).filter(
            ([_, width]) => width !== undefined,
          ),
        ),
      }));
    }
  }, [columnResizing, setStoredResizedColumWidths]);

  // Set width for manually resized columns on every render
  allColumns
    .filter((column) => !column.disableResizing)
    .forEach((column) => {
      const width = storedResizedColumnsWidths[column.id];
      if (width !== undefined) {
        column.totalWidth = width;
      }
    });
};

export const isNOTCellSelected = (cell: {
  row: { original: IObjectWithIdAndSelected };
}) => {
  return !isCellSelected(cell);
};

export const isCellSelected = (cell: {
  row: { original: IObjectWithIdAndSelected };
}) => {
  return cell.row.original.isSelected;
};
