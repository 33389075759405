import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { IOffsetLimitFilters } from '../types/table';
import { usePagination } from './usePagination';

/**
 * This limit value informs backend to return all items.
 * It should be used for items only where we predict not
 * more than 100 items and it would be hard to implement
 * UI pagination.
 */
export const UNSAFE_NO_PAGINATION_LIMIT = -1000;

/**
 * This filter value informs backend to return all items.
 * See comment for `UNSAFE_NO_PAGINATION_LIMIT` for more
 * information about usage.
 */
export const UNSAFE_FILTER_WITH_NO_PAGINATION = {
  limit: UNSAFE_NO_PAGINATION_LIMIT,
  offset: 0,
  pageLimit: UNSAFE_NO_PAGINATION_LIMIT,
};

export interface FiltersWithSet<D extends IOffsetLimitFilters> {
  filters: D;
  setFilters: (newFilters: D) => any;
}

export const usePaginatedFilters = <D extends IOffsetLimitFilters>(
  initialFilters?: Partial<D>,
): FiltersWithSet<D> & {
  reset: (newInitialFilters?: Partial<D>) => any;
} => {
  const { limit, pageLimit } = usePagination();

  const [filters, setFilters] = useState<IOffsetLimitFilters>({
    limit: limit,
    offset: 0,
    pageLimit,
    ...initialFilters,
  });
  const reset = useCallback(
    (newInitialFilters?: Partial<D>) => {
      const merge = newInitialFilters ? newInitialFilters : initialFilters;
      setFilters({
        limit,
        pageLimit,
        offset: 0,
        ...merge,
      });
    },
    [setFilters, initialFilters, limit, pageLimit],
  );
  return {
    reset,
    filters: filters as D,
    setFilters: setFilters as Dispatch<SetStateAction<D>>,
  };
};
