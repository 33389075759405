import { ApptTemplate, ApptTemplateEntry } from 'types/apptTemplate';
import { formatHourMinutes } from 'utils/dateUtil';
import { TimePlannerState } from './types';

export const RESET_ONLINE_ACTION = ({ state }: { state: TimePlannerState }) => {
  const day: TimePlannerState['day'] = { ...state.day };
  day.cubicle = day.staff_only;
  day.hours.forEach((hr) => {
    hr.cubicle = hr.staff_only;
    hr.intervals = hr.intervals.map((interval) => ({
      cubicle: interval.staff_only,
      staff_only: interval.staff_only,
      available_cubicles: interval.available_cubicles,
      minute: interval.minute,
    }));
  });
  return day;
};

export const UPDATE_DAY_ACTION = ({
  state,
  value,
  staff_only,
}: {
  state: TimePlannerState;
  value: number;
  staff_only?: boolean;
}) => {
  const day: TimePlannerState['day'] = { ...state.day };
  if (!staff_only && value < day.staff_only) {
    day.cubicle = value;
  } else {
    day.staff_only = value;
    if (!staff_only || day.cubicle > value) {
      day.cubicle = value;
    }
  }
  day.hours.forEach((_, hourIndex) => {
    UPDATE_HOUR_ACTION({ state, hourIndex, value, staff_only });
  });
  return day;
};

export const UPDATE_HOUR_ACTION = ({
  state,
  hourIndex,
  value,
  staff_only,
}: {
  state: TimePlannerState;
  hourIndex: number;
  value: number;
  staff_only?: boolean;
}) => {
  const day: TimePlannerState['day'] = { ...state.day };
  if (!staff_only && value < day.hours[hourIndex].staff_only) {
    day.hours[hourIndex].cubicle = value;
  } else {
    day.hours[hourIndex].staff_only = value;
    if (!staff_only || day.hours[hourIndex].cubicle > value) {
      day.hours[hourIndex].cubicle = value;
    }
  }
  day.hours[hourIndex].intervals.forEach((_, intervalIndex) =>
    UPDATE_INTERVAL_ACTION({
      state,
      value,
      staff_only,
      intervalIndex,
      hourIndex,
    }),
  );
  return day;
};

const updateInterval = ({
  day,
  hourIndex,
  intervalIndex,
  value,
  staff_only,
}: {
  day: TimePlannerState['day'];
  hourIndex: number;
  intervalIndex: number;
  value: number;
  staff_only?: boolean;
}) => {
  const currentInterval = day.hours[hourIndex].intervals[intervalIndex];
  if (!staff_only && value < currentInterval.staff_only) {
    day.hours[hourIndex].intervals[intervalIndex] = {
      cubicle: value,
      staff_only: currentInterval.staff_only,
      available_cubicles: currentInterval.available_cubicles,
      minute: currentInterval.minute,
    };
  } else {
    day.hours[hourIndex].intervals[intervalIndex] = {
      cubicle:
        !staff_only || currentInterval.cubicle > value
          ? value
          : currentInterval.cubicle,
      staff_only: value,
      available_cubicles: currentInterval.available_cubicles,
      minute: currentInterval.minute,
    };
  }
};

export const UPDATE_INTERVAL_ACTION = ({
  state,
  ...rest
}: {
  state: TimePlannerState;
  hourIndex: number;
  intervalIndex: number;
  value: number;
  staff_only?: boolean;
}) => {
  const day: TimePlannerState['day'] = { ...state.day };
  updateInterval({
    day: day,
    ...rest,
  });
  return day;
};

export const formatEntries = ({
  state,
  showOnline,
}: {
  state: TimePlannerState;
  showOnline: boolean;
}): ApptTemplateEntry[] => {
  return state.day.hours
    .map((hour) => {
      return hour.intervals.map((interval, intervalIndex) => ({
        appt_time: formatHourMinutes(hour.hourNumber, interval.minute),
        cubicle_count: interval.staff_only,
        staff_only: showOnline ? interval.staff_only - interval.cubicle : 0,
        available_cubicles: interval.available_cubicles,
      }));
    })
    .flat();
};

export const getIntervalEntry = ({
  timeTemplate,
  hourIndex,
  minutes,
}: {
  timeTemplate: ApptTemplate;
  hourIndex: number;
  minutes: number;
}) => {
  return timeTemplate.entries.find(
    (entry) => formatHourMinutes(hourIndex, minutes) === entry.appt_time,
  );
};
