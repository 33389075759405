import { AxiosError } from 'axios';
import { Card } from 'components/common';
import {
  CloseButton,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormGroupTitle,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import { CommonInput } from 'components/common/Forms/Input';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FaTag, FaTimes } from 'react-icons/fa';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import {
  TrustMiniSite,
  TrustMiniSiteCategory,
  TrustMiniSiteCategoryFormValues,
} from '../../../../../types/trustMiniSites';
import { CommonButton } from '../../../../common/Forms/Button';
import CheckboxInput from '../../../../common/Forms/CheckboxInput';
import { CardLoader } from '../../../../common/Loading';
import { TrustMiniSiteCategorySitesError } from './TrustMiniSiteCategoryCard.styled';

interface Props {
  closeFormHandler: (err?: AxiosError) => void;
  submitFormHandler: (
    data: TrustMiniSiteCategoryFormValues,
    categoryId?: string,
  ) => void;
  isLoading: boolean;
  trustMiniSite: TrustMiniSite;
  prefilledData?: TrustMiniSiteCategory;
}

export const TrustMiniSiteCategoryCard = ({
  closeFormHandler,
  submitFormHandler,
  isLoading,
  trustMiniSite,
  prefilledData,
}: Props) => {
  const isEdit = Boolean(prefilledData);
  const { t } = useCommonTranslation();
  const assignedSiteIds = useMemo(() => {
    if (prefilledData) {
      return trustMiniSite.sites
        .filter(({ categories }) => categories.includes(prefilledData.id))
        .map(({ id }) => id);
    } else {
      return [];
    }
  }, [prefilledData, trustMiniSite]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TrustMiniSiteCategoryFormValues>({
    defaultValues: {
      ...prefilledData,
      sites: assignedSiteIds,
    },
  });

  return (
    <Card>
      <DialogFormWrapper>
        <CloseButton
          variant="primary"
          iconOnly
          onClick={() => closeFormHandler()}
        >
          <FaTimes />
        </CloseButton>
        <FormHeader>
          <FaTag />
          <FormTitle>{t('trust-mini-site-category')}</FormTitle>
        </FormHeader>
        <FormBody
          onSubmit={handleSubmit((data) =>
            submitFormHandler(data, prefilledData?.id),
          )}
        >
          <FormRow>
            <CommonInput
              label={t('trust-mini-site-category-name')}
              placeholder={t('trust-mini-site-category-name')}
              helperText={errors.name?.message}
              hasError={Boolean(errors.name)}
              {...register('name', {
                required: t('must-not-empty') as string,
              })}
              required
              autoFocus
            />
          </FormRow>
          <FormGroupTitle>{t('trust-mini-site-category-sites')}</FormGroupTitle>
          {/**
           * Hidden checkbox to force having `sites` field value as array even for single checkbox.
           * See https://github.com/orgs/react-hook-form/discussions/8465.
           */}
          <CheckboxInput
            style={{ display: 'none' }}
            label=""
            {...register('sites', { required: true })}
          />
          {trustMiniSite.sites.map(({ id, name }) => {
            return (
              <FormRow key={id}>
                <CheckboxInput
                  label={name}
                  onlyInRow={true}
                  {...register('sites', { required: true })}
                  value={id}
                  id={'site-' + id}
                />
              </FormRow>
            );
          })}
          {errors.sites && (
            <TrustMiniSiteCategorySitesError>
              {t('trust-mini-site-category-sites-error')}
            </TrustMiniSiteCategorySitesError>
          )}
          <FormActionsStyle align="center">
            <CommonButton variant="primary" type="submit">
              {isEdit ? t('save') : t('add')}
            </CommonButton>
            <CommonButton
              type="button"
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {t('cancel')}
            </CommonButton>
          </FormActionsStyle>
        </FormBody>
        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
};
