import React from 'react';
import { UserData } from '../../../types/authentication';
import { BookOverflowWithPatient } from './BookOverflowWithPatient';
import { BookOverflowNoPatient } from './BookOverflowNoPatient';

interface IAppointmentSlotCardProps {
  closeForm: () => void;
  selectedPatient?: UserData;
}

export const BookOverflow: React.FunctionComponent<
  IAppointmentSlotCardProps
> = ({ closeForm, selectedPatient }) => {
  return (
    <>
      {selectedPatient ? (
        <BookOverflowWithPatient
          closeForm={closeForm}
          selectedUser={selectedPatient}
        />
      ) : (
        <BookOverflowNoPatient closeForm={() => closeForm()} />
      )}
    </>
  );
};
