import { Card } from 'components/common';
import { Col, Grid } from 'components/common/Grid';
import { CardLoader } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { TimePlanningDayPicker } from 'components/views-components/staff/settings/timeplanning/TimePlanningDayPicker';
import { useMemo } from 'react';

import { TimePlanner } from '../../../../components/TimePlanner/TimePlanner';
import { timePlannerHasStaffOnlyItems } from '../../../../components/TimePlanner/timePlannerUtils';
import TimePlanningHeader from '../../../../components/views-components/staff/settings/timeplanning/TimePlanningHeader';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { hideIf } from '../../../../styles/displayUtils';
import { formatDateOnlyISO, parseIsoDate } from '../../../../utils/dateUtil';
import { StaffLayout } from '../../StaffLayout';
import { useStaffTimeDayPickerData } from './StaffTimeDayPickerHooks';

export const StaffTimeDayPicker = () => {
  const { t } = useCommonTranslation();

  const {
    selectedDate,
    updateDates,
    defaultTimePlanning: {
      showOnline,
      selectedResourceType,
      resourceTypesWithCubicleAssigned,
      defaultTemplate,
      setShowOnline,
      setSelectedResourceType,
      saveTimePlanning,
      selectedResourceCubiclesAssigned,
    },
    isPlannerLoading,
    isDayPickerLoading,
    plannerApptTemplatesData,
    dayPickerApptTemplatesData,
    plannerFilterDate,
    dayPickerFilterDate,
  } = useStaffTimeDayPickerData();

  const dayPickerDatesWithDefaultsChanged = useMemo(
    () =>
      dayPickerApptTemplatesData?.data?.map((template) =>
        parseIsoDate(template.template_date),
      ) || [],
    [dayPickerApptTemplatesData],
  );

  const plannerApptTemplate = useMemo(
    () =>
      plannerApptTemplatesData?.data?.find(
        (template) =>
          template.template_date === formatDateOnlyISO(selectedDate),
      ),
    [selectedDate, plannerApptTemplatesData],
  );

  const showOnlineMustBeShown = timePlannerHasStaffOnlyItems(
    defaultTemplate || plannerApptTemplate,
  );

  const minDate = new Date();

  return (
    <StaffLayout>
      <PageHelmet title={t('time-planning')} />
      <Grid>
        <Col md={5}>
          <Card>
            {isDayPickerLoading && <CardLoader />}
            <div style={hideIf(isDayPickerLoading)}>
              <TimePlanningDayPicker
                selectedDate={selectedDate}
                filterDate={dayPickerFilterDate}
                updateDates={updateDates}
                datesWithChangesFromDefault={dayPickerDatesWithDefaultsChanged}
                minDate={minDate}
              />
            </div>
          </Card>
        </Col>
        <Col md={7}>
          <Card>
            {isPlannerLoading ? (
              <CardLoader />
            ) : !resourceTypesWithCubicleAssigned || !selectedResourceType ? (
              'No Resource Type has Cubicles'
            ) : (
              <>
                <TimePlanningHeader
                  resourceTypes={resourceTypesWithCubicleAssigned}
                  selectedResourceType={selectedResourceType}
                  setSelectedResourceType={setSelectedResourceType}
                  setShowOnline={setShowOnline}
                  showOnline={showOnline}
                  showOnlineMustBeShown={showOnlineMustBeShown}
                />
                <TimePlanner
                  selectedResourceCubiclesAssigned={
                    selectedResourceCubiclesAssigned
                  }
                  selectedResourceType={selectedResourceType}
                  dateFormat="fullMonthWithYear"
                  filterDate={plannerFilterDate}
                  minDate={minDate}
                  showOnline={showOnline}
                  setShowOnline={setShowOnline}
                  selectedDate={selectedDate}
                  setSelectedDate={(date) =>
                    updateDates({ selectedDate: date })
                  }
                  submitTimePlanner={saveTimePlanning}
                  defaultTemplate={defaultTemplate}
                  apptTemplate={plannerApptTemplate}
                />
              </>
            )}
          </Card>
        </Col>
      </Grid>
    </StaffLayout>
  );
};
