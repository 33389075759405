import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { CardTitle } from 'components/common';
import { CommonButton } from 'components/common/Forms/Button';
import InfoMessage from 'components/common/InfoMessage';
import { SingleRow, Td, Th } from 'components/Table/Table.styled';
import { StaffSettingsTimePlanningTree } from 'configs/RoutesConfig';
import React, { Fragment, ReactElement } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { TableHeadStyle } from 'styles/Typography';
import { getOrderedWeekdayNames } from '../../../../../utils/dateUtil';
import {
  APPT_TEMPLATE_FULL_WEEK_DEFAULT_DATE,
  getDefaultTemplateDate,
} from '../../../../../containers/staff/settings/time-planning/DefaultTemplate';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';

interface Props {
  weekDays: number[];
  setDate: (date: Date) => void;
  fullWeekDefaultEditAvailable: boolean;
}

const DefaultPlanningRow = ({
  onClick,
  label,
}: {
  onClick: () => any;
  label: string;
}) => {
  const { t } = useCommonTranslation();
  return (
    <SingleRow>
      <Th style={{ ...(TableHeadStyle as any), textTransform: 'none' }}>
        {label}
      </Th>
      <Td>
        <CommonButton type="button" variant="primary" onClick={onClick}>
          {t('edit')}
        </CommonButton>
      </Td>
    </SingleRow>
  );
};

export const TimePlanningDefaultTable = ({
  weekDays,
  setDate,
  fullWeekDefaultEditAvailable,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  return (
    <Fragment>
      <LinkCardTabs tabs={StaffSettingsTimePlanningTree(t)} />
      <InfoMessage
        type="info"
        icon={<FaInfoCircle />}
        text={t('time-planning-info')}
      />
      <CardTitle>{t('master-template')}</CardTitle>
      {getOrderedWeekdayNames(t, weekDays).map((day) => (
        <DefaultPlanningRow
          key={`week_${day.backendDowIndex}`}
          onClick={() => setDate(getDefaultTemplateDate(day.backendDowIndex))}
          label={day.label}
        />
      ))}
      {fullWeekDefaultEditAvailable && (
        <DefaultPlanningRow
          key={`week_day_template`}
          onClick={() => setDate(APPT_TEMPLATE_FULL_WEEK_DEFAULT_DATE)}
          label={t('day-template')}
        />
      )}
    </Fragment>
  );
};
