import React, { useMemo, useState } from 'react';
import { Card } from '../../../components/common';
import AppointmentsHeader from './AppointmentsHeader/AppointmentsHeader';
import { todayInIso } from '../../../utils/dateUtil';
import { CardLoader } from '../../../components/common/Loading';
import { AppointmentTimes } from './AppointmentTimes';
import { DnaAndMiaDialog } from './DnaAndMiaDialog';
import { ResourceType } from '../../../types/resource-types';
import { AppointmentCardControl } from './SlotAppointment';
import { UseStaffAppointmentsType } from '../../../hooks/staff/useAppointmentsFlow';

type IStaffAppointmentsProps = {
  filteredResourceTypes?: ResourceType[];
  filteredResourceTypesFetching: boolean;
  style?: React.CSSProperties;
} & AppointmentCardControl &
  UseStaffAppointmentsType;

export const StaffAppointmentsCard: React.FunctionComponent<
  IStaffAppointmentsProps
> = ({
  filteredResourceTypes,
  filteredResourceTypesFetching,
  setOrToggleEditAppointmentId,
  editAppointmentId,
  style,
  ...useStaffAppointmentsData
}) => {
  const [dnaMiaOpen, setDnaMiaOpen] = useState(false);
  const {
    isFetching: appointmentsFetching,
    selectedResourceType,
    setSelectedResourceType,
    setSelectedDate,
    selectedDate,
    isUpdate,
    changeUpdateMode,
    appointmentsData,
    refetch,
  } = useStaffAppointmentsData;

  const filteredAppointments = useMemo(() => {
    return appointmentsData?.data.filter(
      ({ status }) =>
        status !== 'FREE' &&
        status !== 'CANCELLED' &&
        status !== 'LOCKED' &&
        status !== 'EXPIRED',
    );
  }, [appointmentsData]);

  const isFetching = appointmentsFetching || filteredResourceTypesFetching;

  return (
    <Card fillHeight={isFetching} style={style}>
      <AppointmentsHeader
        filteredResourceTypes={filteredResourceTypes}
        isResourceTypeFetching={isFetching}
        selectedResourceType={selectedResourceType}
        setSelectedResourceType={setSelectedResourceType}
        selectedDate={selectedDate}
        setSelectedDate={(date) =>
          date ? setSelectedDate(date) : setSelectedDate(todayInIso())
        }
        isUpdate={isUpdate}
        setIsUpdate={changeUpdateMode}
        onDnaMiaClicked={() => {
          setDnaMiaOpen(true);
        }}
        onRefreshClicked={() => refetch()}
      />
      {isFetching ? (
        <CardLoader
          style={{
            marginTop: 'var(--s4)',
          }}
        />
      ) : filteredResourceTypes ? (
        <AppointmentTimes
          setOrToggleEditAppointmentId={setOrToggleEditAppointmentId}
          editAppointmentId={editAppointmentId}
          isUpdate={isUpdate}
          resourceTypes={
            (selectedResourceType && [selectedResourceType]) ||
            filteredResourceTypes
          }
          appointments={filteredAppointments}
        />
      ) : (
        <div>No Resource Type Available</div>
      )}

      {dnaMiaOpen && (
        <DnaAndMiaDialog
          resourceType={selectedResourceType}
          appointments={filteredAppointments}
          onCloseClicked={() => setDnaMiaOpen(false)}
        />
      )}
    </Card>
  );
};
