import React from 'react';
import { Appointment } from '../../../types/appointments';
import { Popup } from '../../../components/Popup/Popup';
import { ProcessingAppointment } from '../cubicle/ProcessingAppointment';
import { UserData } from '../../../types/authentication';
import { GeneralPractice } from '../../../types/generalPractices';
import { QueuedAppointmentProcessCard } from '../cubicle/QueuedAppointmentProcess.styled';

type IAppointmentEditProps = {
  appointment: Appointment;
  onClose: () => unknown;
  user?: UserData;
  surgeries?: Array<GeneralPractice>;
};

export const AppointmentGracePeriodEdit: React.FC<IAppointmentEditProps> = ({
  appointment,
  onClose,
  surgeries,
  user,
}) => {
  return (
    <Popup
      size={'fullScreen'}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <QueuedAppointmentProcessCard>
        <ProcessingAppointment
          appointment={appointment}
          isUserOrSurgeriesLoading={false}
          onProcessingFinished={onClose}
          surgeries={surgeries}
          user={user}
          showCloseButton={true}
          showComment={true}
        />
      </QueuedAppointmentProcessCard>
    </Popup>
  );
};
