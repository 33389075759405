import { CommonButton } from 'components/common/Forms/Button';
import React, { ReactElement } from 'react';
import { UserMemberData, UserProfileResponse } from 'types/authentication';
import {
  AccBirthdate,
  AddFamilyMemberBtn,
  FamilyAccActions,
  FamilyAccWrapper,
  Title,
} from './SharedAccountsView.styled';
import {
  formatDateOnlySite,
  parseIsoDate,
} from '../../../../../utils/dateUtil';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';

type Props = {
  setSelectedMemberToPrimary?: (user: UserMemberData | undefined) => any;
  userProfile: UserProfileResponse | undefined;
  actionForm?: ReactElement;
  onAddMemberClicked: () => any;
  onEditMemberClicked: (user: UserMemberData) => any;
  onPrimaryMemberEditClicked: () => any;
  onAddExistingMemberClicked?: () => any;
};

export const SharedAccountsView = ({
  setSelectedMemberToPrimary,
  userProfile,
  actionForm,
  onAddMemberClicked,
  onEditMemberClicked,
  onPrimaryMemberEditClicked,
  onAddExistingMemberClicked,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();

  return (
    <>
      <div>
        <Title>{t('primary-account')}</Title>
        <FamilyAccWrapper>
          <div>
            <Title>
              {userProfile?.firstname} {userProfile?.lastname}
            </Title>
            <AccBirthdate>
              {userProfile?.birthdate &&
                formatDateOnlySite(parseIsoDate(userProfile.birthdate))}
            </AccBirthdate>
          </div>
          <FamilyAccActions>
            <CommonButton
              variant="primary"
              onClick={onPrimaryMemberEditClicked}
            >
              {t('edit')}
            </CommonButton>
            {setSelectedMemberToPrimary && (
              <CommonButton variant="primary" type="button" disabled>
                {t('make-primary')}
              </CommonButton>
            )}
          </FamilyAccActions>
        </FamilyAccWrapper>
      </div>
      <>
        <div>
          <Title>{t('family-accounts')}</Title>
          {Boolean(userProfile?.other_members.length) &&
            userProfile?.other_members.map((user: UserMemberData) => (
              <FamilyAccWrapper key={'Shared_' + user.id}>
                <div>
                  <Title>
                    {user?.firstname} {user?.lastname}
                  </Title>
                  <AccBirthdate>
                    {user?.birthdate &&
                      formatDateOnlySite(parseIsoDate(user.birthdate))}
                  </AccBirthdate>
                </div>
                <FamilyAccActions>
                  <CommonButton
                    type="button"
                    variant="primary"
                    onClick={() => onEditMemberClicked(user)}
                  >
                    {t('edit')}
                  </CommonButton>
                  {setSelectedMemberToPrimary && (
                    <CommonButton
                      variant="primary"
                      type="button"
                      onClick={() => setSelectedMemberToPrimary(user)}
                    >
                      {t('make-primary')}
                    </CommonButton>
                  )}
                </FamilyAccActions>
              </FamilyAccWrapper>
            ))}
        </div>
        <AddFamilyMemberBtn type="button" onClick={onAddMemberClicked}>
          <CommonButton as="div" iconOnly variant="secondary" size="auto">
            +
          </CommonButton>
          {t('add-shared-account')}
        </AddFamilyMemberBtn>
        {onAddExistingMemberClicked && (
          <AddFamilyMemberBtn
            type="button"
            onClick={onAddExistingMemberClicked}
          >
            <CommonButton as="div" iconOnly variant="secondary" size="auto">
              +
            </CommonButton>
            {t('add-existing-account-as-shared')}
          </AddFamilyMemberBtn>
        )}
      </>
      {actionForm}
    </>
  );
};
