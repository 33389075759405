import { AppointmentCounts } from '../../../../../hooks/apptTemplate/useDefaultTemplatesApptCounts';
import { Card, CardTitle } from '../../../../../components/common';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { CardLoader } from 'components/common/Loading';
import { ResourceType } from '../../../../../types/resource-types';
import CommonSelect from '../../../../../components/common/Forms/Select';
import { useWeeklyAppointmentsCounts } from './useWeeklyAppointmentsCounts';
import {
  WeekRowStyle,
  DayColumnStyle,
} from './StaffPlanningWeeklyAppointmentsCard.styled';

export const StaffPlanningWeeklyAppointmentsCard = ({
  resourceType,
  showOnlineMustBeShown,
}: {
  resourceType?: ResourceType;
  showOnlineMustBeShown: boolean;
}) => {
  const { t } = useCommonTranslation();

  const {
    weekCounts,
    selectedAppointmentType,
    appointmentTypeOptions,
    isLoading,
    setSelectedAppointmentType,
  } = useWeeklyAppointmentsCounts({ resourceType });

  return (
    <Card>
      <CardTitle>{t('time-planning-weekly-report')}</CardTitle>
      {isLoading ? (
        <CardLoader />
      ) : (
        <>
          {showOnlineMustBeShown && (
            <CommonSelect
              noSpaceForHelperText
              label={t('appointment-type')}
              placeholder={t('appointment-type')}
              disabled={!resourceType}
              options={appointmentTypeOptions}
              value={selectedAppointmentType}
              onChange={(data) => {
                setSelectedAppointmentType(data as keyof AppointmentCounts);
              }}
            />
          )}
          <WeekRowStyle>
            {weekCounts?.map(({ label, count }) => (
              <DayColumnStyle key={`dayColumn_${label}`}>
                <div>{label}</div>
                <div>{count}</div>
              </DayColumnStyle>
            ))}
          </WeekRowStyle>
        </>
      )}
    </Card>
  );
};
