import Axios from 'configs/Axios';
import { KioskAppointmentsResponseData } from 'types/appointments';
import { LCDEntry } from 'types/lcd';

export type KioskAppointmentsFilters = {
  siteId: string;
  input: string;
  isPhoneInput: boolean;
};

export const getKioskAppointments = (params: KioskAppointmentsFilters) => {
  return Axios.get<KioskAppointmentsResponseData>(
    `/kiosk/${params.siteId}/${params.input}` +
      (params.isPhoneInput ? `?phone=true` : ''),
  );
};

export type KioskAppointmentsCheckinData = {
  siteId: string;
  apptId: string;
};

export const checkInKioskAppointment = (
  params: KioskAppointmentsCheckinData,
) => {
  return Axios.post<void>(`/kiosk/${params.siteId}/${params.apptId}`);
};

export type KioskUpdateUserData =
  | {
      site_id: string;
      appt_id: string;
      firstname: string;
      lastname?: string;
    }
  | {
      site_id: string;
      appt_id: string;
      firstname?: string;
      lastname: string;
    };

export const updateKioskUser = (params: KioskUpdateUserData) => {
  return Axios.post<void>(`/kiosk/updateUser`, params);
};

export type LCDResponse = {
  code: string;
  rows: LCDEntry[];
};
export type GetLCDParams = {
  siteId: string;
};
export const getLCD = (params: GetLCDParams) => {
  return Axios.get<LCDResponse>(`/kiosk/${params.siteId}/lcd`);
};
