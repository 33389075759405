import {
  getMockServer,
  MockServer,
  wrapCountResponseCollection,
} from './server';
import { UserData, UserProfileResponse } from '../types/authentication';
import { DeepReadonly } from 'types/utils/DeepReadonly';
import { DeepPartial } from 'react-hook-form';
import { Factory, Model, Response } from 'miragejs';
import { DEFAULT_LOGGED_IN_TEST_USER, TEST_USER_2 } from './testUsers';

export type PartialUser = DeepPartial<UserProfileResponse & UserData>;
// export type PartialUserProfileResponse = DeepPartial<UserProfileResponse>;

export const userModel = Model.extend<PartialUser>({});

export const userFactory = Factory.extend<PartialUser>({
  id: (index) => `${index}`,
  birthdate: '1986-02-02',
});

export const seedUsers = (server: MockServer) => {
  server.create('user', DEFAULT_LOGGED_IN_TEST_USER);
  server.create('user', TEST_USER_2);
};

const loginResponse = {
  ...DEFAULT_LOGGED_IN_TEST_USER,
} as const;

export type MockUserData = DeepReadonly<DeepPartial<UserData>>;
export type MockUserProfileResponse = DeepReadonly<
  DeepPartial<UserProfileResponse>
>;

export const userRoutes = (server: MockServer) => {
  server.get('user/:id', (schema, request) => {
    const id = request.params.id;
    const found = schema.find('user', id);
    return found ? found.attrs : 404;
  });
  server.get('user', (schema) => {
    return wrapCountResponseCollection(schema.all('user'));
  });

  server.put('user/:id', (schema, request) => {
    const id = request.params.id;
    schema.find('user', id)?.update(JSON.parse(request.requestBody));
    return 200;
  });
  server.delete('user/:id', (schema, request) => {
    const id = request.params.id;
    schema.find('user', id)?.destroy();
    return 200;
  });
};

export const mockLogin = (
  user: MockUserProfileResponse | Response,
  mockServer = getMockServer(),
) => {
  mockServer.get('login', () => {
    return user;
  });
};

// possible overrides for the mocked user in REACT_APP_LOGIN_OVERRIDE
// for example: REACT_APP_LOGIN_OVERRIDE='{"background_graphics": true, "active_site_id": "c9bcdd6c057e46fd84da8f962a9a1421"}'
export const getDefaultMockUser = (): MockUserData => {
  let loginOverride: Partial<UserData> = {};
  try {
    if (process.env.REACT_APP_LOGIN_OVERRIDE)
      loginOverride = JSON.parse(process.env.REACT_APP_LOGIN_OVERRIDE);
  } catch (e) {
    console.error(e, process.env.REACT_APP_LOGIN_OVERRIDE);
  }
  return { ...loginResponse, ...loginOverride };
};
