import { useMemo, useState } from 'react';
import {
  AppointmentCounts,
  useDefaultTemplatesApptCounts,
} from '../../../../../hooks/apptTemplate/useDefaultTemplatesApptCounts';
import { ResourceType } from '../../../../../types/resource-types';
import { SelectOption } from '../../../../../types/common';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import {
  getWeekDay,
  getWeekDayConfig,
  parseIsoDate,
} from '../../../../../utils/dateUtil';

export const useWeeklyAppointmentsCounts = ({
  resourceType,
}: {
  resourceType?: ResourceType;
}) => {
  const { t } = useCommonTranslation();
  const { isLoading, resourceTypeTemplateCounts } =
    useDefaultTemplatesApptCounts({
      resourceTypeId: resourceType?.id,
    });

  const resourceTypeCounts = useMemo(() => {
    return resourceTypeTemplateCounts.find(
      ({ resource_type_id }) => resource_type_id === resourceType?.id,
    )?.templateDatesWithCounts;
  }, [resourceTypeTemplateCounts, resourceType]);

  const appointmentTypeOptions: SelectOption<keyof AppointmentCounts>[] = [
    {
      label: t('all-appointments-type'),
      value: 'allApptCount',
    },
    {
      label: t('online-only-appointments-type'),
      value: 'onlineCount',
    },
    {
      label: t('staff-only-appointments-type'),
      value: 'staffOnlyCount',
    },
  ];

  const [selectedAppointmentType, setSelectedAppointmentType] = useState(
    appointmentTypeOptions[0].value,
  );

  const weekCounts = useMemo(() => {
    const totalAppts = resourceTypeCounts?.reduce(
      (acc, { [selectedAppointmentType]: count }) => acc + count,
      0,
    );
    const labelCount = resourceTypeCounts?.map(
      ({ template_date, [selectedAppointmentType]: count }) => {
        return {
          label: getWeekDayConfig({
            t,
            weekdayDowIndex: getWeekDay(parseIsoDate(template_date)),
            shortLabel: true,
          }).label,
          count,
        };
      },
    );
    labelCount?.push({ label: t('total'), count: totalAppts || 0 });
    return labelCount;
  }, [resourceTypeCounts, selectedAppointmentType, t]);
  return {
    isLoading,
    weekCounts,
    appointmentTypeOptions,
    selectedAppointmentType,
    setSelectedAppointmentType,
  };
};
