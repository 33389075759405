import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { pick } from 'lodash';
import { useCallback } from 'react';
import { CubicleData, CubicleFormValues } from 'types/cubicles';
import { errorToast } from './toast';

// returns the object with the cubicle data in a format suitable for "PUT /cubicle"
export const useGetCubicleUpdateData = () => {
  const { t } = useCommonTranslation();
  return useCallback(
    (cubicle?: CubicleData): CubicleFormValues => {
      if (!cubicle) {
        errorToast(new Error(t('supervisor-cubicle-not-found')));
        throw new Error(`Cubicle not found`);
      }
      return {
        ...pick(
          cubicle,
          'id',
          'name',
          'description',
          'color',
          'sound',
          'user_member_id',
        ),
        cubicle_resource_type: cubicle.resource_types.map((c) => c.id),
      };
    },
    [t],
  );
};
