import { ISortAndPagination } from '../../types/table';
import { ItemsResponse } from '../../types/ItemsWithCountResponse';
import { useMemo } from 'react';

export const usePaginateResults = <D>({
  data,
  filters,
}: {
  data: ItemsResponse<D>;
  filters: ISortAndPagination;
}) => {
  return useMemo(() => {
    return {
      data: data.data.slice(filters.offset, filters.offset + filters.limit),
    };
  }, [data, filters]);
};
