import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { useAuth } from 'context/AuthContext';
import { Redirect, Route, Switch } from 'react-router-dom';
import SupportFAQ from '../containers/support/SupportFAQ';
import { UnAuthAndPatientSupportFormPage } from '../containers/support/UnAuthAndPatientSupportFormPage';
import GdprPage from './support/gdpr';
import Page500 from './status/Page500';
import Page404 from './status/Page404';
import { LCD } from '../containers/public/lcd/LCD';
import { EmailCancellation } from '../containers/public/emailCancellation/EmailCancellation';
import { UnAuthRouteOnly } from '../components/common/UnAuthRouteOnly';
import Login from './unauth/Login';
import { ResetPassword } from './unauth/ResetPassword';
import SignUp from './unauth/SignUp';
import { StaffRoutesAndMenu } from './staff/StaffRoutesAndMenu';
import {
  ALL_ROLES_BUT_PATIENT,
  PATIENT,
  PATIENT_URL,
  SUPERADMIN,
} from '../configs/constants';
import { PatientRoutes } from './patient/PatientRoutes';
import { AdminMenuAndRoutes } from './admin/AdminMenuAndRoutes';
import { useRedirectToKeepHash } from '../hooks/router/useRedirectToKeepHash';
import { EmailActionHandler } from './unauth/EmailActionHandler';
import { TrustMiniSitePage } from '../containers/public/trustMiniSite/TrustMiniSitePage';
import { addHashParamsToUrl } from '../utils/urlUtils';
import { Kiosk } from '../containers/public/kiosk/Kiosk';

export const kioskURL = '/public/kiosk';
export const lcdURL = '/public/lcd';
export const trustMiniSiteURL = '/public/mini-site';
export const patientURL = PATIENT_URL;
export const STAFF_URL = '/staff';
const STAFF_SETTINGS_URL = `${STAFF_URL}/settings`;
export const STAFF_SETTINGS_REFERRALS_URL = `${STAFF_SETTINGS_URL}/referrals`;

export const adminSitePreferencesUrl = (siteId: string) =>
  `/admin/site/preferences/${siteId}`;
export const adminTrustPreferencesUrl = (trustId: string) =>
  `/admin/trust/preferences/${trustId}`;
export const ADMIN_URL = '/admin';
export const ADMIN_STATISTICS_URL = `${ADMIN_URL}/statistics`;
export const ADMIN_STATISTICS_OVERVIEW_URL = `${ADMIN_STATISTICS_URL}/overview`;
export const ADMIN_STATISTICS_LAST_FIVE_YEARS_URL = `${ADMIN_STATISTICS_URL}/last_five_years`;

export const STAFF_GP_SURGERIES_URL = `${STAFF_SETTINGS_REFERRALS_URL}/gp_surgeries`;
export const STAFF_ICBS_URL = `${STAFF_SETTINGS_REFERRALS_URL}/icgs`;
export const STAFF_OTHER_REFERRERS_URL = `${STAFF_SETTINGS_REFERRALS_URL}/other_referrers`;
export const STAFF_OTHER_REFERRERS_MERGE_URL = `${STAFF_SETTINGS_REFERRALS_URL}/other_referrers_merge`;
export const STAFF_GP_SURGERY_MERGE_URL = `${STAFF_SETTINGS_REFERRALS_URL}/gp_surgeries_merge`;
export const STAFF_ICBS_MERGE_URL = `${STAFF_SETTINGS_REFERRALS_URL}/icgs_merge`;

export const SIGN_UP_ROUTE = '/sign-up';
export const SIGN_UP_HASH_SITE_ID_PARAM = 'siteId';
export const signUpToSiteUri = (siteId: string) =>
  addHashParamsToUrl(SIGN_UP_ROUTE, {
    [SIGN_UP_HASH_SITE_ID_PARAM]: siteId,
  });

export const LOGIN_ROUTE = '/login';
export const LOGIN_HASH_BOOK_AT_SITE_ID_PARAM = 'bookAtSiteId';
export const loginToBookAtSiteUri = (siteId: string) =>
  addHashParamsToUrl(LOGIN_ROUTE, {
    [LOGIN_HASH_BOOK_AT_SITE_ID_PARAM]: siteId,
  });

export const PATIENT_ADD_HOSPITAL_ROUTE = `${patientURL}/add-hospital`;
export const PATIENT_ADD_HOSPITAL_HASH_SITE_ID_PARAM = 'siteId';
export const patientAddToHospitalUri = (siteId: string) =>
  addHashParamsToUrl(PATIENT_ADD_HOSPITAL_ROUTE, {
    [PATIENT_ADD_HOSPITAL_HASH_SITE_ID_PARAM]: siteId,
  });

export const BOOKING_ROUTE = `${patientURL}/booking`;

export const AppRoutes = () => {
  const { need_complete_signup } = useAuth();
  const [loginTo, signUpTo] = useRedirectToKeepHash(LOGIN_ROUTE, SIGN_UP_ROUTE);
  return (
    <Route path="/">
      <Switch>
        <Route exact path="/support" component={SupportFAQ} />
        <Route
          exact
          path="/contact-us"
          component={UnAuthAndPatientSupportFormPage}
        />
        <Route exact path="/gdpr" component={GdprPage} />
        <Route exact path="/500" component={Page500} />
        <Route exact path="/404" component={Page404} />

        <UnAuthRouteOnly exact path={SIGN_UP_ROUTE}>
          <SignUp completeRegister={need_complete_signup} />
        </UnAuthRouteOnly>
        {!need_complete_signup && (
          /**
           * This with the last swith redirect ensures when signup needs to be
           * completed user gets navigated to signup. Not the most elegant solution,
           * but working
           */
          <UnAuthRouteOnly exact path="/login" component={Login} />
        )}
        <Route exact path={`${kioskURL}/:siteId`} component={() => <Kiosk />} />
        {/* LCD Calling Screen */}
        <Route exact path={`${lcdURL}/:siteId`} component={LCD} />
        {/* Trust mini-site */}
        <Route
          exact
          path={`${trustMiniSiteURL}/:trustId`}
          component={TrustMiniSitePage}
        />
        <Route
          exact
          path={`${trustMiniSiteURL}/:trustId/category/:categoryId`}
          component={TrustMiniSitePage}
        />
        <Route
          exact
          path={`/public/cancel/:token`}
          component={EmailCancellation}
        />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route
          exact
          path="/email-action-handler"
          component={EmailActionHandler}
        />
        <ProtectedRoute path={patientURL} hasAnyOfUserRoles={[PATIENT]}>
          <PatientRoutes />
        </ProtectedRoute>
        <ProtectedRoute path="/staff" hasAnyOfUserRoles={ALL_ROLES_BUT_PATIENT}>
          <StaffRoutesAndMenu />
        </ProtectedRoute>
        <ProtectedRoute path="/admin" hasAnyOfUserRoles={[SUPERADMIN]}>
          <AdminMenuAndRoutes />
        </ProtectedRoute>
        {need_complete_signup ? (
          <Redirect to={signUpTo} />
        ) : (
          <Redirect to={loginTo} />
        )}
      </Switch>
    </Route>
  );
};
