import React, { useCallback } from 'react';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { Appointment } from '../../../types/appointments';
import { PatientReadOnlyDetails } from '../../common/patient/PatientReadOnlyDetails';
import { CardLoader } from '../../../components/common/Loading';
import { FormBody } from '../../../components/common/Forms/Forms.styled';
import {
  AppointmentsProcessingData,
  ProcessingAppointmentSubformType,
} from '../../../components/views-components/staff/appointments/AppointmentsProcessingData';
import { useForm } from 'react-hook-form';
import { useReferrerOptions } from '../../../utils/options';
import { CommonButton } from '../../../components/common/Forms/Button';
import { UserData } from '../../../types/authentication';
import { GeneralPractice } from '../../../types/generalPractices';
import {
  useMutateAppointmentNotTaken,
  useMutateAppointmentTaken,
  useMutateMIAAppointment,
  useMutateNotPreparedAppointment,
} from '../../../query/appointments';
import { FaBriefcaseMedical } from 'react-icons/fa';
import { errorToast, successToast } from '../../../utils/toast';
import {
  getReferrerIds,
  isGeneralPractise,
} from '../../../components/views-components/staff/appointments/AppointmentReferrersUtil';
import { useSiteBooleanAttributes } from '../../../hooks/useAttribute';
import { useSite } from '../../../context/SiteContext';
import { FlexBoxSameFlex } from './QueuedAppointmentProcess.styled';
import { QueuedProcessingAppointmentHeading } from './QueuedProcessingAppointmentHeading';
import {
  MANDATORY_SOURCE,
  SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING,
} from '../../../configs/siteAndTrustAttributes';
import { gpSurgeryRequiredTrust } from '../../../utils/trustUtil';
import { INFO_LABEL_DESIGN_LARGER } from '../../../components/common/Forms/InfoLabelText';

interface IProcessingAppointmentBodyProps {
  appointment: Appointment;
  isUserOrSurgeriesLoading: boolean;
  user?: UserData;
  surgeries?: Array<GeneralPractice>;
  onProcessingFinished: () => void;
  showCloseButton: boolean;
  onShowCommentClicked?: () => any;
  showComment: boolean;
}

const processFormInput = (
  data: ProcessingAppointmentSubformType,
  user?: UserData,
) => {
  return {
    ...data.appointment,
    tubes: data.appointment.tubes ? Number(data.appointment.tubes) : undefined,
    forms: data.appointment.tubes ? Number(data.appointment.forms) : undefined,
    ...getReferrerIds(data.appointment, user!),
  };
};

const CommentButton = ({
  showComment,
  onShowCommentClicked,
  user,
  appointment,
}: {
  onShowCommentClicked?: () => any;
  showComment: boolean;
  appointment: Appointment;
  user?: UserData;
}) => {
  const { t } = useCommonTranslation();
  return (
    <>
      {!showComment && (
        <>
          {user?.comment || appointment.comment ? (
            <div
              style={{
                display: 'flex',
                gap: 'var(--s3)',
                flexDirection: 'column',
                flex: 0.3,
              }}
            >
              <CommonButton
                variant={'secondary'}
                size={'xlarge'}
                onClick={onShowCommentClicked}
                style={{
                  fontSize: 'var(--fontlg)',
                }}
                type={'button'}
              >
                {t('show-comments')}
              </CommonButton>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                gap: 'var(--s3)',
                flexDirection: 'column',
                flex: 0.3,
              }}
            >
              <CommonButton
                variant={'secondary'}
                size={'xlarge'}
                onClick={onShowCommentClicked}
                style={{
                  fontSize: 'var(--fontlg)',
                }}
                type={'button'}
              >
                {t('add-comment')}
              </CommonButton>
            </div>
          )}
        </>
      )}
    </>
  );
};

export const ProcessingAppointment: React.FunctionComponent<
  IProcessingAppointmentBodyProps
> = ({
  appointment,
  isUserOrSurgeriesLoading,
  user,
  surgeries,
  onProcessingFinished,
  showCloseButton,
  onShowCommentClicked,
  showComment,
}) => {
  const { t } = useCommonTranslation();
  const { isLoading: isReferrersLoading, referrerOptions } =
    useReferrerOptions();

  const { isLoading: isAppointmentTaken, mutateAsync: submitAppointmentTaken } =
    useMutateAppointmentTaken({
      onSuccess: () => {
        successToast(t('appointment-update-successful'));
        onProcessingFinished();
      },
    });
  const {
    isLoading: isAppointmentNotTaken,
    mutateAsync: submitAppointmentNotTaken,
  } = useMutateAppointmentNotTaken({
    onSuccess: () => {
      successToast(t('appointment-update-successful'));
      onProcessingFinished();
    },
  });

  const {
    isLoading: isAppointmentNotPreparedLoading,
    mutateAsync: submitAppointmentNotPrepared,
  } = useMutateNotPreparedAppointment({
    onSuccess: () => {
      successToast(t('appointment-update-successful'));
      onProcessingFinished();
    },
  });

  const [showMiaButton = false] = useSiteBooleanAttributes(
    SHOW_MIA_BUTTON_IN_APPOINTMENT_PROCESSING,
  );

  const {
    isLoading: isAppointmentMiaLoading,
    mutate: markAppointmentMiaAndCloseDialog,
  } = useMutateMIAAppointment({
    onSuccess: () => {
      successToast(t('appt-mia-submit-success'));
      onProcessingFinished();
    },
  });

  const isLoading =
    isReferrersLoading ||
    isUserOrSurgeriesLoading ||
    isAppointmentNotTaken ||
    isAppointmentTaken ||
    isAppointmentNotPreparedLoading ||
    isAppointmentMiaLoading;

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ProcessingAppointmentSubformType>({
    defaultValues: {
      appointment: {
        ...appointment,
        generalPractise: isGeneralPractise(appointment),
        otherRefererId: appointment.referrer_id,
      },
    },
  });

  const { activeTrust } = useSite();

  const [notReferrerMandatory] = useSiteBooleanAttributes(MANDATORY_SOURCE);

  const testTaken = useCallback(
    async (data: ProcessingAppointmentSubformType) => {
      const apptUpdate = data.appointment;
      if (
        !notReferrerMandatory &&
        !apptUpdate.otherRefererId &&
        !apptUpdate.generalPractise
      ) {
        !gpSurgeryRequiredTrust(activeTrust)
          ? errorToast({
              message: t('appointment-details-other-referrer-required'),
            })
          : errorToast({
              message: t('appointment-details-one-referrer-required'),
            });
      } else {
        await submitAppointmentTaken({
          id: appointment.id,
          ...processFormInput(data, user),
        });
      }
    },
    [
      submitAppointmentTaken,
      appointment,
      t,
      user,
      notReferrerMandatory,
      activeTrust,
    ],
  );

  return (
    <>
      <QueuedProcessingAppointmentHeading
        label={t('appointment-detail-title')}
        icon={<FaBriefcaseMedical />}
      />
      <FormBody>
        <AppointmentsProcessingData
          register={register}
          control={control}
          errors={errors}
          referrers={referrerOptions}
          showComment={showComment}
        />
        <FlexBoxSameFlex
          style={{
            marginBottom: 'var(--s3)',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 'var(--s3)',
              flexDirection: 'column',
              flex: 0.3,
            }}
          >
            <CommonButton
              variant="danger"
              size={'xlarge'}
              style={{
                fontSize: 'var(--fontlg)',
              }}
              type={'button'}
              onClick={async () => {
                await submitAppointmentNotTaken({
                  id: appointment.id,
                  ...processFormInput(getValues(), user),
                });
                onProcessingFinished();
              }}
            >
              {t('appointment-test-not-taken')}
            </CommonButton>
            <CommonButton
              variant="danger"
              size={'xlarge'}
              style={{
                fontSize: 'var(--fontlg)',
              }}
              type={'button'}
              onClick={async () => {
                await submitAppointmentNotPrepared({
                  id: appointment.id,
                  ...processFormInput(getValues(), user),
                });
                onProcessingFinished();
              }}
            >
              {t('appointment-not-prepared')}
            </CommonButton>
          </div>
          <div
            style={{
              maxWidth: '50%',
              display: 'flex',
              gap: 'var(--s3)',
              flexDirection: 'column',
            }}
          >
            <CommonButton
              type="button"
              size={'xlarge'}
              variant="primary"
              style={{
                flex: 1,
              }}
              onClick={async () => {
                await handleSubmit(async (data) => testTaken(data))();
              }}
            >
              {t('appointment-test-taken')}
            </CommonButton>
            {showCloseButton && (
              <CommonButton
                variant={'secondary'}
                size={'xlarge'}
                type={'button'}
                onClick={onProcessingFinished}
              >
                {t('close')}
              </CommonButton>
            )}
          </div>
        </FlexBoxSameFlex>

        <PatientReadOnlyDetails
          user={user}
          surgeries={surgeries}
          subtitleLabel={t('appointment-book-patient-information')}
          design={INFO_LABEL_DESIGN_LARGER}
          showComment={false}
        />
        {!showCloseButton && showMiaButton ? (
          <FlexBoxSameFlex
            style={{
              marginBottom: 'var(--s3)',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 'var(--s3)',
                flexDirection: 'column',
                flex: 0.3,
              }}
            >
              <CommonButton
                variant="danger"
                size={'xlarge'}
                style={{
                  fontSize: 'var(--fontlg)',
                }}
                type={'button'}
                onClick={async () => {
                  await markAppointmentMiaAndCloseDialog({
                    id: appointment.id,
                    comment: getValues().appointment.comment,
                    ...getReferrerIds(getValues().appointment, user!),
                  });
                }}
              >
                {t('mia')}
              </CommonButton>
            </div>
            <CommentButton
              appointment={appointment}
              showComment={showComment}
              onShowCommentClicked={onShowCommentClicked}
              user={user}
            />
          </FlexBoxSameFlex>
        ) : (
          <CommentButton
            appointment={appointment}
            showComment={showComment}
            onShowCommentClicked={onShowCommentClicked}
            user={user}
          />
        )}
      </FormBody>

      {isLoading && <CardLoader fillWrapper={true} />}
    </>
  );
};
