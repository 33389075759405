import React, { useMemo, useState } from 'react';
import {
  TimePlannerActions,
  TimePlannerHoursNav,
  TimePlannerScrollableArea,
  TimePlannerTitle,
  TimePlannerTitles,
  TimePlannerWrapper,
} from './TimePlanner.styled';
import { CommonButton } from '../common/Forms/Button';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import CustomScrollbar from '../common/CustomScrollbar';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { positionValues } from 'react-custom-scrollbars-2';
import { ApptTemplate, ApptTemplateEntry } from '../../types/apptTemplate';
import { ResourceType } from '../../types/resource-types';
import { useTimePlanner } from './timePlannerHooks';
import { TimePlannerSliders } from './TimePlannerSliders';
import {
  isDefaultFullWeekOrTemplateDate,
  isDefaultFullWeekTemplateDay,
  isDefaultTemplateDate,
} from '../../containers/staff/settings/time-planning/DefaultTemplate';
import { formatEntries } from './actions';
import { LIST_MAX_SCROLL_HEIGHT } from 'containers/staff/StaffLayout';
import { useSiteIntAttributes } from '../../hooks/useAttribute';
import {
  STAFF_CLOSE_HOUR_IN_SECONDS,
  STAFF_OPEN_HOUR_IN_SECONDS,
} from '../../configs/siteAndTrustAttributes';

export interface ITimePlannerBodyProps {
  defaultTemplate?: ApptTemplate;
  apptTemplate?: ApptTemplate;
  selectedResourceCubiclesAssigned: number;
  selectedResourceType: ResourceType;
  selectedDate: Date;
  setShowOnline: React.Dispatch<React.SetStateAction<boolean>>;
  showOnline: boolean;
  submitTimePlanner: (
    entries: ApptTemplateEntry[],
    templateId?: string,
  ) => void;
}

export const TimePlannerBody: React.FunctionComponent<
  ITimePlannerBodyProps
> = ({
  showOnline,
  submitTimePlanner,
  selectedDate,
  defaultTemplate,
  apptTemplate,
  ...rest
}) => {
  const { t } = useCommonTranslation();
  const scrollRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [scrollValues, setScrollValues] = useState<
    positionValues | undefined
  >();
  const [openHourInSeconds, closeHourInSeconds] = useSiteIntAttributes(
    STAFF_OPEN_HOUR_IN_SECONDS,
    STAFF_CLOSE_HOUR_IN_SECONDS,
  );

  const isDefaultTemplateDateMemo = useMemo(
    () => isDefaultTemplateDate(selectedDate),
    [selectedDate],
  );

  const templateEditing = isDefaultTemplateDateMemo
    ? defaultTemplate
    : apptTemplate;

  const allowResetToDefault = !isDefaultTemplateDateMemo && apptTemplate;

  const { dispatch, state, resetTimePlanner, resetTimePlannerToDefault } =
    useTimePlanner({
      showOnline,
      selectedDate,
      apptTemplate,
      defaultTemplate,
      openHourInSeconds: openHourInSeconds as number,
      closeHourInSeconds: closeHourInSeconds as number,
      ...rest,
    });
  return (
    <>
      {/*
      The widths are set to get exactly the same layout as the tiles within the scrollable area.
      This is not optimal, as the width should be either calculated from those elements, or layout
      redone, but since most items in time planning are set to pixel specific widths the refactor
      seemed to time-consuming at this moment and should be considered if needed later.
      */}
      <TimePlannerTitles>
        <div
          style={{
            minWidth: 24,
          }}
        />
        {showOnline && (
          <TimePlannerTitle
            style={{
              minWidth: 213,
            }}
          >
            {t('online')}
          </TimePlannerTitle>
        )}
        <TimePlannerTitle
          style={{
            minWidth: 213,
          }}
        >
          {t('all')}
        </TimePlannerTitle>

        <div
          style={{
            minWidth: 60,
          }}
        />
      </TimePlannerTitles>
      <TimePlannerWrapper>
        <TimePlannerHoursNav>
          <CommonButton
            variant="primary"
            iconOnly
            size="auto"
            disabled={scrollValues?.top === 0}
            onClick={() => {
              (scrollRef?.current as any).scrollTop(
                (scrollRef?.current as any).getScrollTop() -
                  LIST_MAX_SCROLL_HEIGHT,
              );
            }}
          >
            <FaChevronUp />
          </CommonButton>
          <CommonButton
            variant="primary"
            iconOnly
            size="auto"
            disabled={scrollValues && Math.trunc(scrollValues?.top) === 1}
            onClick={() => {
              (scrollRef?.current as any).scrollTop(
                (scrollRef?.current as any).getScrollTop() +
                  LIST_MAX_SCROLL_HEIGHT,
              );
            }}
          >
            <FaChevronDown />
          </CommonButton>
        </TimePlannerHoursNav>
        <CustomScrollbar
          style={{ width: 'auto' }}
          ref={scrollRef}
          setScrollValues={setScrollValues}
          autoHeight
          autoHeightMax={LIST_MAX_SCROLL_HEIGHT}
        >
          <TimePlannerScrollableArea>
            {showOnline && (
              <TimePlannerSliders
                dispatch={dispatch}
                reducerState={state}
                staffOnly={false}
              />
            )}
            <TimePlannerSliders
              dispatch={dispatch}
              reducerState={state}
              staffOnly={true}
            />
          </TimePlannerScrollableArea>
        </CustomScrollbar>
        <TimePlannerActions>
          <CommonButton
            variant="primary"
            onClick={() => {
              const entries = formatEntries({
                showOnline,
                state,
              });
              submitTimePlanner(entries, templateEditing?.id);
            }}
          >
            {isDefaultFullWeekTemplateDay(selectedDate)
              ? t('apply-to-all-days')
              : t('save')}
          </CommonButton>
          <CommonButton variant="danger" onClick={() => resetTimePlanner()}>
            {t('reset')}
          </CommonButton>
          {!isDefaultFullWeekOrTemplateDate(selectedDate) &&
            allowResetToDefault && (
              <CommonButton
                variant="danger"
                onClick={() => resetTimePlannerToDefault()}
              >
                {t('reset-to-default-template')}
              </CommonButton>
            )}
        </TimePlannerActions>
      </TimePlannerWrapper>
    </>
  );
};
