import { useMemo } from 'react';
import { useSiteNumericArrayAttribute } from '../useAttribute';
import { getDefaultTemplateDate } from '../../containers/staff/settings/time-planning/DefaultTemplate';
import { useApptTemplates } from '../../query/template';
import { addDays, formatDateOnlyISO } from '../../utils/dateUtil';

export const useResourceTypeAllDefaultTemplates = ({
  resourceTypeId,
}: {
  resourceTypeId?: string;
}) => {
  const weekdays = useSiteNumericArrayAttribute('weekDays')!;
  const defaultTemplatesDates = useMemo(
    () => weekdays.map((dayOfWeek) => getDefaultTemplateDate(dayOfWeek)),
    [weekdays],
  );

  const { isLoading, data: defaultTemplates } = useApptTemplates(
    {
      fromDate: formatDateOnlyISO(defaultTemplatesDates[0]),
      toDate: formatDateOnlyISO(
        addDays(defaultTemplatesDates[defaultTemplatesDates.length - 1], 1),
      ),
      resourceTypeID: resourceTypeId,
    },
    {
      enabled: !!resourceTypeId,
    },
  );
  const sortedDefaultTemplates = useMemo(
    () =>
      defaultTemplates?.data.sort((a, b) =>
        a.template_date.localeCompare(b.template_date),
      ),
    [defaultTemplates],
  );
  return {
    isLoading,
    defaultTemplates: sortedDefaultTemplates,
  };
};
