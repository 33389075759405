import { ResourceType } from '../../types/resource-types';
import { formatDateOnlyISO, getWeekDay, isSameDay } from '../../utils/dateUtil';
import {
  APPT_TEMPLATE_FULL_WEEK_DEFAULT_DATE,
  getDefaultTemplateDate,
} from '../../containers/staff/settings/time-planning/DefaultTemplate';
import { useApptTemplates, usePostApptTemplate } from '../../query/template';
import { useSiteFirstDefaultTemplateDate } from '../../containers/staff/settings/time-planning/StaffTimePlanningHooks';
import { useEffect, useState } from 'react';

export const useDefaultApptTemplate = ({
  selectedDate,
  selectedResourceType,
}: {
  selectedDate: Date;
  selectedResourceType?: ResourceType;
}) => {
  const [postTemplateTried, setPostTemplateTried] = useState(false);
  useEffect(() => {
    setPostTemplateTried(false);
  }, [selectedDate]);

  const firstSiteTemplateDay = useSiteFirstDefaultTemplateDate();
  /**
   * If we are getting a default template for a full week, any default template will do.
   * We just need to get an existing one from the site
   */
  const dateForGettingTemplate = isSameDay(
    selectedDate,
    APPT_TEMPLATE_FULL_WEEK_DEFAULT_DATE,
  )
    ? firstSiteTemplateDay
    : selectedDate;

  const defaultTemplateISODate = formatDateOnlyISO(
    getDefaultTemplateDate(getWeekDay(dateForGettingTemplate)),
  );

  const { mutate: postApptTemplate, isLoading: isPostApptTemplateLoading } =
    usePostApptTemplate();

  const { isLoading: isDefaultTemplateLoading, data: defaultTemplateData } =
    useApptTemplates(
      {
        resourceTypeID: selectedResourceType?.id,
        date: defaultTemplateISODate,
      },
      {
        enabled: Boolean(selectedResourceType),
        onSuccess: (response) => {
          if (
            !response.data?.find(
              (template) => template.template_date === defaultTemplateISODate,
            ) &&
            !postTemplateTried
          ) {
            /**
             * If there is no template we are supposed to create it on BE, so that it populates all the necessary fields
             */
            setPostTemplateTried(true);
            postApptTemplate({
              resource_type_id: selectedResourceType!!.id,
              template_date: defaultTemplateISODate,
            });
          }
        },
      },
    );

  const defaultTemplate = defaultTemplateData?.data?.find(
    (template) => template.template_date === defaultTemplateISODate,
  );

  return {
    isLoading: isDefaultTemplateLoading || isPostApptTemplateLoading,
    defaultTemplate,
  };
};
