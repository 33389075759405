import React from 'react';
import { CommonButton } from '../../../components/common/Forms/Button';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { FlexBoxSameFlex } from './QueuedAppointmentProcess.styled';
import { CardLoader } from '../../../components/common/Loading';
import {
  useMutateMIAAppointment,
  useMutateProcessAppointment,
} from '../../../query/appointments';
import { Appointment } from '../../../types/appointments';
import { UserData } from '../../../types/authentication';
import { PatientReadOnlyDetails } from '../../common/patient/PatientReadOnlyDetails';
import { GeneralPractice } from '../../../types/generalPractices';
import { successToast } from '../../../utils/toast';
import { QueuedProcessingAppointmentHeading } from './QueuedProcessingAppointmentHeading';
import { FaUserClock } from 'react-icons/fa';
import { INFO_LABEL_DESIGN_LARGE } from '../../../components/common/Forms/InfoLabelText';

interface ICalledAppointmentBodyProps {
  onClose: () => void;
  setCurrentAppointment: (appointment: Appointment) => void;
  currentAppointment: Appointment;
  isUserOrSurgeriesLoading: boolean;
  user?: UserData;
  surgeries?: Array<GeneralPractice>;
  onShowCommentClicked: () => any;
}

export const CalledAppointment: React.FunctionComponent<
  ICalledAppointmentBodyProps
> = ({
  onClose,
  setCurrentAppointment,
  currentAppointment,
  isUserOrSurgeriesLoading,
  user,
  surgeries,
  onShowCommentClicked,
}) => {
  const { t } = useCommonTranslation();

  const { isLoading: miaAppointmentLoading, mutateAsync: submitMia } =
    useMutateMIAAppointment({
      onSuccess: () => {
        onClose();
        successToast(t('appt-mia-submit-success'));
      },
    });
  const {
    isLoading: processAppointmentLoading,
    mutateAsync: moveToProcessing,
  } = useMutateProcessAppointment({
    onSuccess: () => {
      setCurrentAppointment({
        ...currentAppointment,
        status: 'PROCESSING',
      });
    },
    onErrorAfterToast: () => onClose(),
  });

  const isLoading =
    miaAppointmentLoading ||
    processAppointmentLoading ||
    isUserOrSurgeriesLoading;

  return (
    <>
      <QueuedProcessingAppointmentHeading
        label={t('cubicle-has-patient-arrived')}
        icon={<FaUserClock />}
      />
      <PatientReadOnlyDetails
        user={user}
        surgeries={surgeries}
        design={INFO_LABEL_DESIGN_LARGE}
        showComment={false}
      />
      {(user?.comment || currentAppointment.comment) && (
        <div
          style={{
            gap: 'var(--s3)',
            flexDirection: 'column',
          }}
        >
          <CommonButton
            variant={'secondary'}
            size={'xlarge'}
            onClick={onShowCommentClicked}
            style={{
              fontSize: 'var(--fontlg)',
            }}
          >
            {t('show-comments')}
          </CommonButton>
        </div>
      )}
      <FlexBoxSameFlex
        style={{
          marginTop: 'var(--s4)',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <CommonButton
            variant={'danger'}
            size={'xlarge'}
            onClick={() => submitMia({ id: currentAppointment.id })}
          >
            {t('mia')}
          </CommonButton>
        </div>
        <CommonButton
          variant={'primary'}
          size={'xlarge'}
          onClick={() => moveToProcessing(currentAppointment.id)}
        >
          {t('arrived')}
        </CommonButton>
      </FlexBoxSameFlex>
      {isLoading && <CardLoader fillWrapper={true} />}
    </>
  );
};
