import {
  CommonButton as Button,
  CommonButton,
} from 'components/common/Forms/Button';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import React, { ReactElement } from 'react';
import { FaEye } from 'react-icons/fa';
import { UserData } from 'types/authentication';
import { UsersFilters } from 'types/users';

import { TableActions } from '../../../components/Table/Table.styled';
import { SearchPatientTable } from '../user/SearchPatientTable';

interface Props {
  onClose: () => void;
  initialFilters?: Partial<UsersFilters>;
  onSelectPatient: (patient: UserData) => void;
  onPreviewPatient?: (patient: UserData) => void;
  stickyFilters?: Partial<UsersFilters>;
}

export const SearchSelectPatientTable = ({
  initialFilters = {},
  onClose,
  onSelectPatient,
  onPreviewPatient,
  stickyFilters,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  return (
    <SearchPatientTable
      stickyFilters={stickyFilters}
      initialFilters={{
        sortField: 'lastname',
        sortDirection: 'asc',
        ...initialFilters,
      }}
      filtersDisplayed={[
        'firstname',
        'lastname',
        'email',
        'phone',
        'birthDate',
      ]}
      rowActionsCreator={(patient) => (
        <>
          {onPreviewPatient && (
            <CommonButton
              type="button"
              variant="primary"
              iconOnly
              onClick={() => {
                onPreviewPatient(patient);
              }}
            >
              <FaEye title={t('show-patient-information')} />
            </CommonButton>
          )}
          <Button
            size="auto"
            variant="primary"
            onClick={() => {
              onSelectPatient(patient);
            }}
          >
            {t('select')}
          </Button>
        </>
      )}
      aboveTableElement={
        <TableActions>
          <CommonButton variant="danger" onClick={onClose}>
            {t('close-search-results')}
          </CommonButton>
        </TableActions>
      }
    />
  );
};
