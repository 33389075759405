import {
  CommonButton as Button,
  CommonLink as Link,
} from 'components/common/Forms/Button';
import DefaultInputFilter from 'components/Table/DefaultInputFilter';
import FilterByDatePicker from 'components/Table/FilterByDatePicker';
import { RowActions } from 'components/Table/Table.styled';
import { useSite } from 'context/SiteContext';
import React, { ReactElement, useMemo } from 'react';
import { FaCalendarAlt, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Column, FilterProps } from 'react-table';
import { adminSitePreferencesUrl, kioskURL, lcdURL } from 'routes/AppRoutes';
import { TableHeadStyle } from 'styles/Typography';
import { SiteData, SitesFilters } from 'types/sites';
import { TrustData } from 'types/trusts';
import { getNewColumnWidth, setColumnFilterAndSortKey } from 'utils/table';
import { formatISOStringToSiteLocaleDate } from '../../../../utils/dateUtil';
import { FetchedDataTable } from '../../../Table/FetchedDataTable';
import { getFilterControls } from '../../../Table/TableUtils';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { ItemsResponse } from '../../../../types/ItemsWithCountResponse';
import { FiltersWithSet } from '../../../../hooks/usePaginatedFilters';
import { errorToast } from '../../../../utils/toast';

type Props = {
  sitesDataWithCount?: ItemsResponse<SiteData>;
  trustsData?: TrustData[] | null;
  openFormHandler: (value?: string) => void;
  onDeleteClicked: (id: string) => void;
  isTableDataLoading: boolean;
  isTableDataFetching: boolean;
  selectedSite?: SiteData;
} & FiltersWithSet<SitesFilters>;

export const SitesTable = ({
  sitesDataWithCount,
  trustsData,
  openFormHandler,
  setFilters,
  isTableDataLoading,
  filters,
  selectedSite,
  onDeleteClicked,
  isTableDataFetching,
}: Props): ReactElement => {
  const sitesData = sitesDataWithCount?.data;
  const { t } = useCommonTranslation();
  const { setActiveSite: setActive } = useSite();

  const columns = useMemo(() => {
    return [
      {
        Header: t('site-name'),
        accessor: 'name',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(22),
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('site-name'),
            }}
          />
        ),
      },
      {
        Header: t('trust'),
        id: setColumnFilterAndSortKey('trustName', 'trust.Name'),
        accessor: (row: SiteData) =>
          trustsData?.find((trust) => trust.id === row.trust_id)?.name,
        width: getNewColumnWidth(18),
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              placeholder: t('trust'),
            }}
          />
        ),
      },
      {
        Header: t('expiry-date'),
        id: 'expireDate',
        accessor: (site: SiteData) =>
          formatISOStringToSiteLocaleDate(site.expire_date),
        width: getNewColumnWidth(14),
        Filter: (props: FilterProps<object>) => (
          <FilterByDatePicker
            {...props}
            inputProps={{
              placeholder: t('expiry-date'),
              icon: <FaCalendarAlt />,
            }}
          />
        ),
      },
      {
        Header: t('no-of-cubicles'),
        accessor: 'cubicle_limit',
        width: getNewColumnWidth(t('no-of-cubicles')),
        id: 'cubicleLimit',
        Filter: (props: FilterProps<object>) => (
          <DefaultInputFilter
            {...props}
            inputProps={{
              type: 'number',
              placeholder: t('cubicles'),
              min: 1,
            }}
          />
        ),
      },
      {
        ...getFilterControls({
          t,
          filters: filters,
          setFilters,
          minWidth: 11,
          maxWidth: 'unset',
        }),
        Cell: ({ value }: { value: string }) => {
          const activeSite = sitesData?.find((site) => site.id === value);
          return (
            <RowActions>
              <Link
                size="auto"
                variant="secondary"
                to={adminSitePreferencesUrl(activeSite!!.id)}
              >
                {t('preferences')}
              </Link>
              <Link
                size="auto"
                variant="secondary"
                to={`${lcdURL}/${activeSite?.id}`}
                target="_blank"
              >
                {t('screen')}
              </Link>
              <Link
                size="auto"
                variant="secondary"
                to={`${kioskURL}/${activeSite?.id}`}
                target="_blank"
              >
                {t('kiosk')}
              </Link>
              <Button
                size="auto"
                variant="secondary"
                disabled={!activeSite}
                onClick={async () => {
                  try {
                    activeSite && (await setActive(activeSite));
                    window.open('/staff/calendar', '_blank');
                  } catch (err) {
                    errorToast(err);
                  }
                }}
              >
                {t('staff')}
              </Button>
              <Button
                size="auto"
                iconOnly
                variant={selectedSite?.id === value ? 'selected' : 'primary'}
                title={t('edit-site')}
                onClick={() => openFormHandler(value)}
              >
                <FaPencilAlt />
              </Button>
              <Button
                size="auto"
                iconOnly
                variant="danger"
                title={t('remove-site')}
                onClick={() => onDeleteClicked(value)}
              >
                <FaTrashAlt />
              </Button>
            </RowActions>
          );
        },
      },
    ] as Column<SiteData>[];
  }, [
    t,
    sitesData,
    trustsData,
    openFormHandler,
    setActive,
    filters,
    setFilters,
    selectedSite,
    onDeleteClicked,
  ]);

  return (
    <FetchedDataTable
      data={sitesDataWithCount}
      columns={columns}
      isLoading={isTableDataLoading}
      isFetching={isTableDataFetching}
      onSortOrPageChange={setFilters}
      filters={filters}
      selectedIds={selectedSite && [selectedSite?.id]}
    />
  );
};
