import { useMemo } from 'react';
import { useSiteBooleanAttributes } from '../useAttribute';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { FORMS, TUBES } from '../../configs/siteAndTrustAttributes';

export const useActiveSiteValidationRules = () => {
  const [formsMandatory, tubesMandatory] = useSiteBooleanAttributes(
    FORMS,
    TUBES,
  );
  const { t } = useCommonTranslation();

  return useMemo(
    () => ({
      formsRules: {
        required: formsMandatory && t<string>('must-not-empty'),
        validate: (value?: number) =>
          value && value > 20
            ? t<string>('must-be-less-than', { count: 20 })
            : undefined,
      },
      tubesRules: {
        required: tubesMandatory && t<string>('must-not-empty'),
        validate: (value?: number) =>
          value && value > 20
            ? t<string>('must-be-less-than', { count: 20 })
            : undefined,
      },
      formsMandatory,
      tubesMandatory,
    }),
    [formsMandatory, tubesMandatory, t],
  );
};
