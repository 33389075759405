import React, { useState } from 'react';
import { Appointment } from '../../../types/appointments';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { PatientAppointmentsAndEditBlock } from '../../../components/views-components/staff/appointments/patient/PatientAppointmentsAndEditBlock';
import { UserData } from '../../../types/authentication';
import { useExistingAppointmentEditCard } from './ExistingAppointmentEditCardHooks';
import { AppointmentGracePeriodEdit } from './AppointmentGracePeriodEdit';
import { AppointmentCard } from '../../../components/views-components/staff/appointments/AppointmentCard';
import { FaUserClock } from 'react-icons/fa';
import { PatientReadOnlyDetails } from '../../common/patient/PatientReadOnlyDetails';
import { CardSubtitle } from '../../../components/common';
import { EditAppointmentData } from '../../../components/views-components/staff/appointments/EditAppointmentData';
import { ButtonSetWithCloseButton } from '../../../components/Form/ButtonSetWithCloseButton';
import { CommonButton } from '../../../components/common/Forms/Button';
import { getReferrerIds } from '../../../components/views-components/staff/appointments/AppointmentReferrersUtil';
import { UnderlineLinkStyle } from '../../../components/common/Forms/Forms.styled';
import {
  EDIT_PATIENT_MODE,
  EditAppointmentMode,
  PATIENT_APPOINTMENTS_LIST_MODE,
  VIEW_APPOINTMENT_AUDIT_MODE,
} from '../../../hooks/staff/usePatientAppointments';
import { ExistingAppointmentWorkflowDetails } from '../../../components/views-components/staff/appointments/ExistingAppointmentWorkflowDetails';
import { useShouldShowNotPreparedButton } from '../../../hooks/appointments/useShouldShowNotPreparedButton';

type IAppointmentEditProps = {
  appointment: Appointment;
  onClose: () => unknown;
  isUpstreamLoading: boolean;
  user?: UserData;
  setExistingAppointmentMode: (mode: EditAppointmentMode) => void;
};

export const ExistingAppointmentEditCard: React.FC<IAppointmentEditProps> = ({
  isUpstreamLoading,
  user,
  appointment,
  onClose,
  setExistingAppointmentMode,
}) => {
  const { t } = useCommonTranslation();

  const {
    updateAppointment,
    notPreparedAppointment,
    isLoading,
    surgeriesResponse,
    cancelWithConfirm,
    auditData,
    appointmentStatus,
    errors,
    register,
    control,
    referrerOptions,
    handleSubmit,
    isEditableWithinGracePeriod,
  } = useExistingAppointmentEditCard({
    onClose,
    appointment,
    isUpstreamLoading,
  });

  const [showGracePeriodEditDialog, setShowGracePeriodEditDialog] =
    useState(false);

  const shouldShowNotPreparedButton = useShouldShowNotPreparedButton();

  return (
    <>
      {showGracePeriodEditDialog && (
        <AppointmentGracePeriodEdit
          appointment={appointment}
          onClose={() => setShowGracePeriodEditDialog(false)}
          surgeries={surgeriesResponse || []}
          user={user}
        />
      )}
      <AppointmentCard
        isLoading={isLoading}
        icon={<FaUserClock />}
        title={t('appointment-detail-title')}
        onCloseClicked={onClose}
      >
        <PatientReadOnlyDetails
          user={user}
          surgeries={surgeriesResponse}
          subtitleLabel={t('appointment-book-patient-information')}
        />
        <PatientAppointmentsAndEditBlock
          user={user}
          onPatientButtonClicked={(patientCard) => {
            if (patientCard === 'userAppointments') {
              setExistingAppointmentMode(PATIENT_APPOINTMENTS_LIST_MODE);
            } else {
              setExistingAppointmentMode(EDIT_PATIENT_MODE);
            }
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CardSubtitle>{t('appointment-detail-information')}</CardSubtitle>
          <UnderlineLinkStyle
            onClick={() => {
              setExistingAppointmentMode(VIEW_APPOINTMENT_AUDIT_MODE);
            }}
          >
            {t('audit')}
          </UnderlineLinkStyle>
        </div>
        <EditAppointmentData
          onlyCommentEditable={appointmentStatus !== 'BOOKED'}
          register={register}
          control={control}
          errors={errors}
          referrers={referrerOptions}
          selectedAppointment={appointment}
          audit={auditData}
        />
        <ButtonSetWithCloseButton
          onCloseClicked={onClose}
          closeLabel={t('close')}
        >
          <CommonButton
            variant="primary"
            type={'button'}
            onClick={async () => {
              await handleSubmit((data) =>
                updateAppointment({
                  id: appointment.id,
                  ...data.appointment,
                  ...getReferrerIds(data.appointment, user),
                }),
              )();
            }}
          >
            {t('save')}
          </CommonButton>
          {appointmentStatus === 'BOOKED' && (
            <>
              <CommonButton
                variant="danger"
                type={'button'}
                onClick={() => {
                  cancelWithConfirm(appointment.id);
                }}
              >
                {t('appointment-cancel')}
              </CommonButton>
              {shouldShowNotPreparedButton(appointment) && (
                <CommonButton
                  variant="danger"
                  type={'button'}
                  onClick={async () => {
                    await handleSubmit((data) =>
                      notPreparedAppointment({
                        id: appointment.id,
                        ...data.appointment,
                        ...getReferrerIds(data.appointment, user),
                      }),
                    )();
                  }}
                >
                  {t('appointment-not-prepared')}
                </CommonButton>
              )}
            </>
          )}
          {isEditableWithinGracePeriod && (
            <CommonButton
              variant="warning"
              type={'button'}
              onClick={() => {
                setShowGracePeriodEditDialog(true);
              }}
            >
              {t('edit')}
            </CommonButton>
          )}
        </ButtonSetWithCloseButton>
        <ExistingAppointmentWorkflowDetails
          appointment={appointment}
          audit={auditData}
        />
      </AppointmentCard>
    </>
  );
};
