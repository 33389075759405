import { Card } from 'components/common';
import { PageHelmet } from 'components/common/PageHelmet';
import {
  StatusDesc,
  StatusTitle,
} from 'components/views-components/statuses/statuses.styled';
import { PatientLayout } from 'containers/PatientLayout';
import { Link } from 'react-router-dom';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { useRole } from '../../context/RoleContext';
import { PATIENT } from '../../configs/constants';
import { useAuth } from '../../context/AuthContext';

const Page404 = () => {
  const { t } = useCommonTranslation();
  const { hasAnyOfRoles } = useRole();
  const { sangixUser } = useAuth();
  let link = '/support';
  if (sangixUser) {
    link = hasAnyOfRoles(PATIENT) ? '/contact-us' : 'staff/contact-us';
  }
  return (
    <PatientLayout graphics="404" hideSupport>
      <PageHelmet title={t('page-not-found')} />
      <Card>
        <StatusTitle>{t('page-not-exist')}</StatusTitle>
        <StatusDesc>
          {t('go-back-404')} <Link to="/">{t('homepage')}</Link> {t('or')}{' '}
          <Link to={link}>{t('contact-us')}</Link>?
        </StatusDesc>
      </Card>
    </PatientLayout>
  );
};

export default Page404;
