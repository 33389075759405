import {
  differenceInYears,
  isInFuture,
  isInPast,
  parseIsoDate,
} from '../../utils/dateUtil';
import { useCommonTranslation } from '../i18n/useCommonTranslation';
import { NHSValidate } from '../../utils/nhsValidator';
import { TrustData } from '../../types/trusts';
import { useMemo } from 'react';
import {
  hookFromTrimBeforeValidation,
  NAME_PATTERN,
  optionalPhoneValidate,
  singleEmailPattern,
  UK_POST_CODE_REGEX,
} from '../../utils/helpers';
import {
  gpSurgeryRequiredTrusts,
  nhsNumberRequiredTrusts,
  postCodeRequiredTrusts,
} from '../../utils/trustUtil';
import { useRole } from '../../context/RoleContext';
import { PATIENT } from '../../configs/constants';
import { useAuth } from '../../context/AuthContext';

export type PatientValidationRules = ReturnType<typeof useUserValidationRules>;

export const useUserValidationRules = ({
  trusts = [],
  emailRequired = false,
}: {
  trusts?: TrustData[];
  emailRequired?: boolean;
}) => {
  const { t } = useCommonTranslation();
  const { sangixUser } = useAuth();
  const { hasAnyOfRolesInAllSites } = useRole();
  const phoneRequiredOrNotRule = useMemo(
    () =>
      !sangixUser || hasAnyOfRolesInAllSites(PATIENT)
        ? {
            required: t('must-not-empty') as string,
          }
        : {},
    [hasAnyOfRolesInAllSites, sangixUser, t],
  );

  return useMemo(() => {
    return {
      nhsNumberRules: {
        ...hookFromTrimBeforeValidation,
        required:
          nhsNumberRequiredTrusts(trusts) && t<string>('must-not-empty'),
        validate: (value: string) => {
          return (
            !value || NHSValidate(value) || (t('nhs-validation') as string)
          );
        },
        setValueAs: (value: string) => {
          // remove whitespaces
          return value.replace(/\s/g, '');
        },
      },
      postcodeRules: {
        ...hookFromTrimBeforeValidation,
        required: postCodeRequiredTrusts(trusts) && t<string>('must-not-empty'),
        pattern: {
          value: UK_POST_CODE_REGEX,
          message: t('postcode-validation') as string,
        },
      },
      birthdateRules: {
        ...hookFromTrimBeforeValidation,
        required: t<string>('must-not-empty'),
        validate: (value: string) => {
          const date = parseIsoDate(value);
          if (isInFuture(date)) return t('birthday-validation-before');
          if (isInPast(date) && differenceInYears(new Date(), date) > 150) {
            return t('birthday-validation-maxAge', {
              age: 150,
            });
          }
        },
      },
      firstnameRules: {
        ...hookFromTrimBeforeValidation,
        required: t('must-not-empty') as string,
        pattern: {
          value: NAME_PATTERN,
          message: t('must-valid-name') as string,
        },
      },
      surnameRules: {
        ...hookFromTrimBeforeValidation,
        required: t('must-not-empty') as string,
        pattern: {
          value: NAME_PATTERN,
          message: t('must-valid-name') as string,
        },
      },
      emailRules: {
        ...hookFromTrimBeforeValidation,
        required: emailRequired ? (t('must-not-empty') as string) : undefined,
        pattern: {
          value: singleEmailPattern,
          message: t('must-valid-address') as string,
        },
      },
      phoneRules: {
        ...phoneRequiredOrNotRule,
        validate: (value: string) => optionalPhoneValidate(t, value),
      },
      gpRules: {
        ...hookFromTrimBeforeValidation,
        required:
          gpSurgeryRequiredTrusts(trusts) && (t('must-not-empty') as string),
      },
      commentRules: {
        ...hookFromTrimBeforeValidation,
        maxLength: {
          value: 10240,
          message: t('max-char-input', { count: 10240 }),
        },
      },
    };
  }, [trusts, t, emailRequired, phoneRequiredOrNotRule]);
};
