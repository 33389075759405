import { FaList } from 'react-icons/fa';
import { UseMutateFunction } from 'react-query';
import { THIRD_PARTY, UserRoleId } from '../../../../../configs/constants';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { UserData } from '../../../../../types/authentication';
import { SelectOption } from '../../../../../types/common';
import { RoleUpdateObject, ThirdPartyLimit } from '../../../../../types/users';
import { DEFAULT_THIRD_PARTY_LIMIT } from '../../../../../utils/user';
import { CommonButton } from '../../../../common/Forms/Button';
import { FormRow } from '../../../../common/Forms/Forms.styled';
import CommonSelect from '../../../../common/Forms/Select';
import { StaffSiteRoleRowLabel } from './StaffSiteRoleRowLabel';
import { StaffSiteRoleRowSelectWrapper } from './StaffForm.styled';

interface Props {
  userData: UserData;
  siteRole: StaffSiteRole;
  mutateAddOrUpdateUserRole: UseMutateFunction<
    unknown,
    unknown,
    RoleUpdateObject,
    unknown
  >;
  mutateRemoveUserRole: UseMutateFunction<
    unknown,
    unknown,
    RoleUpdateObject,
    unknown
  >;
  setThirdPartyRoleUpdate: React.Dispatch<
    React.SetStateAction<
      | {
          thirdPartyLimit: ThirdPartyLimit;
          roleUpdate: RoleUpdateObject;
          rerenderSiteRolesOnCancel: boolean;
        }
      | undefined
    >
  >;
  disabled?: boolean;
}

export interface StaffSiteRole {
  label: string;
  site_id: string;
  roleOptions: SelectOption[];
  selectedRole: string;
  thirdPartyLimit: ThirdPartyLimit | undefined;
}

export const NO_ROLE = 'no-role';

export const StaffSiteRoleRow = ({
  userData,
  siteRole,
  mutateAddOrUpdateUserRole,
  mutateRemoveUserRole,
  setThirdPartyRoleUpdate,
  disabled,
}: Props) => {
  const { t } = useCommonTranslation();
  const { user_account_id } = userData;
  const { label, roleOptions, selectedRole, site_id, thirdPartyLimit } =
    siteRole;

  return (
    <FormRow>
      <StaffSiteRoleRowLabel label={label} />
      <StaffSiteRoleRowSelectWrapper>
        <CommonSelect
          style={{
            flex: '1 1 auto',
          }}
          options={roleOptions}
          value={selectedRole}
          required={true}
          disabled={disabled}
          name={`site_${site_id}`}
          onChange={(newRole) => {
            if (newRole === NO_ROLE) {
              mutateRemoveUserRole({
                site_id: site_id,
                role_id: selectedRole as UserRoleId,
                user_account_id,
              });
            } else {
              const roleUpdate: RoleUpdateObject = {
                site_id: site_id,
                role_id: newRole as UserRoleId,
                user_account_id,
              };
              if (newRole === THIRD_PARTY) {
                setThirdPartyRoleUpdate({
                  thirdPartyLimit: thirdPartyLimit || DEFAULT_THIRD_PARTY_LIMIT,
                  roleUpdate,
                  rerenderSiteRolesOnCancel: true,
                });
              } else {
                mutateAddOrUpdateUserRole(roleUpdate);
              }
            }
          }}
        />
        {selectedRole === THIRD_PARTY && (
          <CommonButton
            size="auto"
            iconOnly
            title={t('set-third-party-limit')}
            variant="secondary"
            onClick={() => {
              setThirdPartyRoleUpdate({
                thirdPartyLimit: thirdPartyLimit || DEFAULT_THIRD_PARTY_LIMIT,
                roleUpdate: {
                  site_id: site_id,
                  role_id: selectedRole as UserRoleId,
                  user_account_id,
                },
                rerenderSiteRolesOnCancel: false,
              });
            }}
          >
            <FaList />
          </CommonButton>
        )}
      </StaffSiteRoleRowSelectWrapper>
    </FormRow>
  );
};
