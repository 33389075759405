import { AxiosError } from 'axios';
import { DialogFormWrapper } from 'components/common/Forms/Forms.styled';
import { CardLoader } from 'components/common/Loading';
import { useAuth } from 'context/AuthContext';
import { ReactElement, useCallback, useState } from 'react';
import { getUserProfile, updateUserProfile } from 'services/userProfile';
import { UserMemberData } from 'types/authentication';
import { UserFormValues, UserMemberFormValues } from 'types/users';
import { errorToast } from 'utils/toast';
import { useGPSurgeriesOptions } from '../../../../hooks/referrals/useGPSurgeriesOptions';
import { MemberAccountFormBody } from './MemberAccountFormBody';
import { usePatientTrusts } from '../../../../hooks/user/usePatientTrusts';
import { getPatientTrustIds } from '../../../../utils/patient';

type Props = {
  prefilledData?: UserMemberData;
  setPrefilledData: (user?: UserMemberData) => any;
  setShowForm: (show: boolean) => any;
  setIsFormSuccess: (isSuccess: boolean) => any;
  setIsFormDeleteMember: (isDelete: boolean) => any;
};

export const MemberAccountForm = ({
  prefilledData,
  setPrefilledData,
  setShowForm,
  setIsFormSuccess,
  setIsFormDeleteMember,
}: Props): ReactElement => {
  const isEditUserMember = Boolean(prefilledData);
  const [isLoading, setIsLoading] = useState(false);
  const { sangixUser: primaryUser, updateAuthUser } = useAuth();

  const { trusts } = usePatientTrusts(primaryUser);
  const { gpSurgeriesOptions } = useGPSurgeriesOptions({
    gpFilters: {
      trustIDs: primaryUser && getPatientTrustIds(primaryUser),
    },
    options: {
      enabled: Boolean(primaryUser),
    },
  });

  const saveProfileData = useCallback(
    (structuredData: UserMemberFormValues) => {
      const memberIndex =
        isEditUserMember &&
        primaryUser?.other_members &&
        prefilledData?.id &&
        primaryUser.other_members.findIndex(
          (member) => member.id === prefilledData.id,
        );
      const other_members = primaryUser?.other_members.length
        ? isEditUserMember
          ? typeof memberIndex === 'number' && memberIndex !== -1
            ? primaryUser.other_members.map(
                (member: UserMemberData, index: number) =>
                  index === memberIndex ? structuredData : member,
              )
            : [...primaryUser.other_members, structuredData]
          : [...primaryUser.other_members, structuredData]
        : [structuredData];

      updateUserProfile({
        ...(primaryUser as UserFormValues),
        other_members,
      })
        .then(() => {
          getUserProfile()
            .then(({ data }) => {
              updateAuthUser({
                sangixUser: data,
              });
              setIsFormSuccess(true);
              setPrefilledData(undefined);
              setShowForm(false);
            })
            .catch((err: AxiosError) => {
              errorToast(err);
            });
        })
        .catch((err: AxiosError) => {
          errorToast(err);
        })
        .finally(() => setIsLoading(false));
    },
    [
      isEditUserMember,
      primaryUser,
      prefilledData?.id,
      setIsFormSuccess,
      setPrefilledData,
      setShowForm,
      updateAuthUser,
    ],
  );

  return (
    <DialogFormWrapper style={{ paddingTop: 'var(--s2)' }}>
      {trusts && (
        <MemberAccountFormBody
          onSaveMember={saveProfileData}
          trusts={trusts}
          onBackClicked={() => {
            setPrefilledData(undefined);
            setTimeout(() => {
              setShowForm(false);
            }, 50);
          }}
          gpSurgeriesOptions={gpSurgeriesOptions}
          onDeleteMember={() => setIsFormDeleteMember(true)}
          member={prefilledData}
        />
      )}
      {isLoading && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
