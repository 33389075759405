import { isAfter, isBefore, isSameHour } from 'date-fns';
import { usePrimaryResourceType } from 'hooks/usePrimaryResourceType';
import { useMutateSaveWaitingList } from 'query/waitingList';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Appointment } from 'types/appointments';
import {
  addDays,
  DateOnlyISOString,
  formatDate,
  parseDate,
  parseDateKeepTZ,
  startOfDay,
} from 'utils/dateUtil';
import { useBookPatientAppointmentBase } from '../booking/useBookPatientAppointment';
import { isWaitingListRowFilled } from './useValidateWaitingList';
import {
  WaitingListFormItemFilled,
  WaitingListFormValues,
} from './useWaitingList';
import { useWaitingListMemberId } from './useWaitingListMemberId';
import { useWaitingListURL } from './useWaitingListURL';
import { getAvailableAppointments } from '../../../services/appointments';
import { patientURL } from '../../../routes/AppRoutes';

const isApptWithingRange = (
  range: WaitingListFormItemFilled,
  appt: Appointment,
) => {
  const timeFrom = parseDate(range.date);
  timeFrom.setHours(range.hourFrom);
  const timeTo = parseDate(range.date);
  timeTo.setHours(range.hourTo);
  const apptTime = parseDateKeepTZ(appt.appt_time);
  return (
    isSameHour(apptTime, timeFrom) ||
    (isAfter(apptTime, timeFrom) && isBefore(apptTime, timeTo))
  );
};

const isApptWithingRanges = (
  ranges: WaitingListFormItemFilled[],
  appt: Appointment,
) => {
  return !!ranges.find((range) => isApptWithingRange(range, appt));
};

const useFilledFormItems = () => {
  const { getValues } = useFormContext<WaitingListFormValues>();
  const values = getValues();
  const filledItems = useMemo(() => {
    return values.list.filter(isWaitingListRowFilled);
  }, [values]);
  return filledItems;
};

export const findAvailableAppointment = async (
  filledRanges: WaitingListFormItemFilled[],
) => {
  const dateRanges = filledRanges
    .map((item) => item.date)
    .filter((date): date is DateOnlyISOString => !!date)
    .map((date) => {
      const fromDate = startOfDay(parseDate(date));
      const toDate = addDays(fromDate, 1);
      return {
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
      };
    });
  // We are actually looking for fist good result, bu
  const resolved = await Promise.all(
    dateRanges.map((query) => getAvailableAppointments(query)),
  );
  const apptsAvailableAcrossDates = resolved
    .map((resolved) => resolved.data.data)
    .flat();

  return apptsAvailableAcrossDates.find((appt) =>
    isApptWithingRanges(filledRanges, appt),
  );
};

export const useSaveWaitingList = () => {
  const history = useHistory();
  const memberId = useWaitingListMemberId();
  const successURL = useWaitingListURL('success');
  const waitingListFilled = useFilledFormItems();
  const { mutate: postWaitingList } = useMutateSaveWaitingList();

  const { primaryResourceType } = usePrimaryResourceType();
  const bookAppt = useBookPatientAppointmentBase();
  const [isSaving, setIsSaving] = useState(false);

  const save = useCallback(async () => {
    setIsSaving(true);
    try {
      if (primaryResourceType && waitingListFilled.length !== 0) {
        const apptFound = await findAvailableAppointment(waitingListFilled);
        if (apptFound) {
          await bookAppt(apptFound);
          history.push(`${patientURL}/booking`);
          return;
        }
        const bodyList = waitingListFilled.map((item) => {
          return {
            from_hour: item.hourFrom,
            to_hour: item.hourTo,
            reservation_date: item.date,
            user_member_id: memberId,
            resource_type_id: primaryResourceType.id,
          };
        });
        await postWaitingList(bodyList, {
          onSuccess: () => history.push(successURL),
        });
      }
    } finally {
      setIsSaving(false);
    }
  }, [
    waitingListFilled,
    history,
    primaryResourceType,
    memberId,
    postWaitingList,
    successURL,
    bookAppt,
  ]);

  return { save, isLoading: isSaving };
};
