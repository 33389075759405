import {
  SignupFormValues,
  StaffSignupFormValues,
  ThirdPartyLimit,
  UserFormValues,
  UserMemberFormValues,
} from 'types/users';
import { v4 as uuidv4 } from 'uuid';
import { bitwiseArrayToNumber } from './helpers';
import {
  SiteRoleResponse,
  UserMemberRelation,
  UserProfileResponse,
} from '../types/authentication';
import { uniq } from 'lodash';

export const DEFAULT_SMS_OPT_IN = true;

export const generateNewUserId = () => 'sx' + uuidv4().replace(/-/gi, '');

export function getUserFormData(
  formData:
    | SignupFormValues
    | StaffSignupFormValues
    | UserFormValues
    | UserMemberFormValues,
) {
  const { password, confirmPassword, ...other } = formData as SignupFormValues;
  const restFormData:
    | SignupFormValues
    | StaffSignupFormValues
    | UserFormValues
    | UserMemberFormValues = other;
  const firebaseUser = {
    email: restFormData.email,
    password,
  };

  const userInfo = {
    ...restFormData,
    disabilities: bitwiseArrayToNumber(formData.disabilities),
    gdpr_confirmation: '2021-06-01T17:53:12.623249Z', // the date that the user had confirmed the GDPR message for allowing cookies and save some information about the user in the website
  };

  return {
    firebaseUser,
    userInfo,
  };
}

export const getUserFullname = (user?: {
  firstname: string;
  lastname: string;
}) => {
  if (!user) return '';
  return `${user.firstname} ${user.lastname}`;
};

export const isTelephonePatientFromFullName = (fullName: string) => {
  const parts = fullName.split(' ');
  const lastname = parts.pop();
  return !!lastname && isTelephonePatient(parts.join(' '), lastname);
};

/**
 * Just by checking the default assigned firstname and lastname for telephone users
 */
export const isTelephonePatient = (firstName: string, lastName: string) => {
  return (
    firstName.toLowerCase() === 'telephone' &&
    lastName.toLowerCase() === 'patient'
  );
};

export const UserMemberRelationKeys: { [key in string]: UserMemberRelation } = {
  MainMember: 0,
  Other: 5,
};

export const isMainMember = (user?: { relation: UserMemberRelation }) => {
  return user && user.relation === UserMemberRelationKeys.MainMember;
};

export const getSiteRolesTrustIds = (siteRoles: SiteRoleResponse[]) => {
  return uniq(siteRoles.map(({ trust_id }) => trust_id));
};

export const DEFAULT_THIRD_PARTY_LIMIT = new Array(7).fill(
  0,
) as ThirdPartyLimit;

export const thirdPartyLimitFromApiValue = (
  value: string | undefined,
  options: { useDefault?: boolean } = {},
): ThirdPartyLimit | undefined => {
  if (!value || value.length === 0) {
    return options.useDefault ? DEFAULT_THIRD_PARTY_LIMIT : undefined;
  }
  let dailyLimits = value
    .split(',')
    .map((v) => parseInt(v, 10))
    .slice(0, 7);
  if (dailyLimits.length < 7) {
    dailyLimits = dailyLimits.concat(
      new Array(7 - dailyLimits.length).fill(0),
    ) as ThirdPartyLimit;
  }
  return dailyLimits as ThirdPartyLimit;
};

export const thirdPartyLimitToApiValue = (
  value: ThirdPartyLimit | undefined,
  options: { useDefault?: boolean } = {},
): string | undefined => {
  if (!value) {
    return options.useDefault ? DEFAULT_THIRD_PARTY_LIMIT.join(',') : undefined;
  }
  return value.join(',');
};

export const canChangePassword = (
  user: UserProfileResponse | null | undefined,
) => {
  if (!user) return false;
  /**
   * Only users created with password can change their password.
   * Users created with google or other providers cannot change their password.
   * We are having some old users with no provider id, but they are created with password.
   */
  return !user.provider_id || user.provider_id === 'password';
};
