import { CommonButton as Button } from 'components/common/Forms/Button';
import React from 'react';
import { UserData } from 'types/authentication';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { SearchPatientDialog } from '../user/SearchPatientDialog';
import { BasicTable } from '../../../components/Table/BasicTable';
import { usePatientsColumns } from '../user/SearchPatientTableHooks';
import { ItemsResponse } from '../../../types/ItemsWithCountResponse';

interface Props {
  onCreateNewPatientSelected: () => void;
  onPatientSelected: (patient: UserData) => void;
  duplicateUsers: ItemsResponse<UserData>;
}

export const DuplicatePatientDialog = ({
  onCreateNewPatientSelected,
  onPatientSelected,
  duplicateUsers,
}: Props) => {
  const { t } = useCommonTranslation();

  const columns = usePatientsColumns({
    usersData: duplicateUsers,
    disableSortBy: true,
    rowActionsCreator: (patient) => (
      <Button
        size="auto"
        variant="primary"
        onClick={() => {
          onPatientSelected(patient);
        }}
      >
        {t('select')}
      </Button>
    ),
  });
  return (
    <SearchPatientDialog infoText={t('appointment-duplicate-info-text')}>
      <>
        <BasicTable columns={columns} data={duplicateUsers.data} />
        <div
          style={{
            marginTop: 'auto',
          }}
        >
          <Button
            variant={'primary'}
            onClick={onCreateNewPatientSelected}
            style={{
              marginTop: 'var(--s3)',
            }}
          >
            {t('appointment-duplicate-create-user')}
          </Button>
        </div>
      </>
    </SearchPatientDialog>
  );
};
