import React, { useState } from 'react';
import {
  UserData,
  UserMemberRelation,
} from '../../../../../types/authentication';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { useMergeMemberMutation } from '../../../../../query/users';
import { SearchSelectPatientDialog } from '../../../calendar/SearchSelectPatientDialog';
import { UserMemberRelationKeys } from '../../../../../utils/user';
import { errorToast, successToast } from '../../../../../utils/toast';
import { PatientReadOnlyDetails } from '../../../../common/patient/PatientReadOnlyDetails';
import { Popup } from '../../../../../components/Popup/Popup';
import { Card, CardTitle } from '../../../../../components/common';
import { CardLoader } from '../../../../../components/common/Loading';
import { FaUserAlt } from 'react-icons/fa';
import {
  AutoCompleteLabel,
  CheckboxesWithErrorWrapper,
  GreenBoxWrapper,
} from '../../../../../components/views-components/un-auth/SignupForm/SignupForm.styled';
import { RELATIONSHIP } from '../../../../../configs/constants';
import CheckboxInput from '../../../../../components/common/Forms/CheckboxInput';
import {
  CommonInputHelperText,
  FormActionsStyle,
  FormBody,
} from '../../../../../components/common/Forms/Forms.styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toRem } from '../../../../../utils/helpers';
import { CommonButton } from '../../../../../components/common/Forms/Button';

type IProps = {
  onClose: () => void;
  user: UserData;
};

export const StaffSelectMemberToMerge: React.FC<IProps> = ({
  user,
  onClose,
}) => {
  const { t } = useCommonTranslation();
  const [selectedPatient, setSelectedPatient] = useState<UserData>();

  const { mutate: mergeMember, isLoading: isMergeLoading } =
    useMergeMemberMutation({
      onSuccess: () => {
        successToast(t('add-existing-account-as-shared-success'));
        onClose();
      },
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ relation: number }>();

  const submitProfileData: SubmitHandler<{ relation: number }> = (data) => {
    if (!selectedPatient) {
      errorToast('No Patient Selected');
      return;
    }
    mergeMember({
      target_account_id: user.user_account_id,
      source_member_id: selectedPatient.id,
      relation: Number(data.relation) as UserMemberRelation,
    });
  };

  return (
    <>
      {!selectedPatient ? (
        <SearchSelectPatientDialog
          stickyFilters={{
            relation: UserMemberRelationKeys.MainMember,
          }}
          onClose={onClose}
          onSelectPatient={(patient) => setSelectedPatient(patient)}
        />
      ) : (
        <Popup onClose={onClose} size={'wrap'}>
          <Card>
            {isMergeLoading && <CardLoader fillWrapper={true} />}
            <CardTitle
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <FaUserAlt
                style={{
                  color: 'var(--primary)',
                  marginRight: 'var(--s2)',
                }}
              />
              {t('add-existing-account-as-shared-select-relationship')}
            </CardTitle>
            <PatientReadOnlyDetails user={selectedPatient} />
            <FormBody onSubmit={handleSubmit(submitProfileData)}>
              <CheckboxesWithErrorWrapper
                style={{
                  marginTop: 'var(--s2)',
                }}
              >
                <GreenBoxWrapper>
                  <AutoCompleteLabel as="span">
                    {t('relationship')}:
                  </AutoCompleteLabel>
                  {RELATIONSHIP(t).map((relation, i) => (
                    <CheckboxInput
                      key={relation.value + i}
                      {...register('relation', {
                        required: t('must-not-empty') as string,
                      })}
                      label={relation.label}
                      id={'new_relation' + i}
                      value={relation.value}
                      type="radio"
                    />
                  ))}
                </GreenBoxWrapper>
                {errors.relation && (
                  <CommonInputHelperText hasError={true}>
                    {errors.relation.message}
                  </CommonInputHelperText>
                )}
              </CheckboxesWithErrorWrapper>
              <FormActionsStyle align={'center'}>
                <CommonButton
                  variant="primary"
                  type="submit"
                  disabled={Boolean(Object.keys(errors).length)}
                  style={{ minWidth: `${toRem(160)}rem` }}
                >
                  {t('add-member')}
                </CommonButton>
                <CommonButton
                  type="button"
                  variant="secondary"
                  style={{ minWidth: `${toRem(160)}rem` }}
                  onClick={() => setSelectedPatient(undefined)}
                >
                  {t('back')}
                </CommonButton>
              </FormActionsStyle>
            </FormBody>
          </Card>
        </Popup>
      )}
    </>
  );
};
