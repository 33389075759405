import React from 'react';
import { IObjectWithId, ISortAndPagination } from '../../types/table';
import { FetchedDataTable, FetchedDataTableProps } from './FetchedDataTable';

type StaticDataTableProps<
  D extends object,
  K extends ISortAndPagination,
> = Omit<FetchedDataTableProps<D, K>, 'isLoading' | 'isFetching'>;

export const StaticDataTable = <
  D extends IObjectWithId,
  K extends ISortAndPagination,
>(
  allProps: StaticDataTableProps<D, K>,
) => {
  return (
    <FetchedDataTable {...allProps} isLoading={false} isFetching={false} />
  );
};
