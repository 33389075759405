import React, { useEffect } from 'react';
import {
  Appointment,
  UserAppointmentsTypes,
} from '../../../../../types/appointments';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { Card } from 'components/common';
import { useStateTabs } from '../../../../../hooks/useStateTabs';
import { PatientAppointmentsHeader } from './PatientAppointmentsHeader';
import { UserData } from '../../../../../types/authentication';
import { usePatientAppointmentsColumns } from './patientAppointmentsCardHooks';
import { CardLoader } from '../../../../common/Loading';
import { UserAppointmentSelect } from '../../../../../hooks/staff/useFlowEditAppointment';
import { BasicTable } from '../../../../Table/BasicTable';

export interface IPatientAppointmentsCardProps {
  onClose: () => unknown;
  user?: UserData;
  onAppointmentInfoClicked: (appointment: UserAppointmentSelect) => unknown;
  onAppointmentDeleteClicked: (appointmentId: string) => unknown;
  isLoading: boolean;
  firstSelected?: 'booked' | 'past' | 'cancelled';
  setSelectedAppointmentsTab: (newTab: UserAppointmentsTypes) => any;
  bookedAppointments?: Appointment[];
  pastAppointments?: Appointment[];
  cancelledAppointments?: Appointment[];
}

export const PatientAppointmentsCard: React.FunctionComponent<
  IPatientAppointmentsCardProps
> = ({
  onClose,
  bookedAppointments,
  pastAppointments,
  cancelledAppointments,
  user,
  onAppointmentDeleteClicked,
  onAppointmentInfoClicked,
  isLoading,
  firstSelected = 'past',
  setSelectedAppointmentsTab,
}) => {
  const { t } = useCommonTranslation();

  const { tabsConfig, selectedTabKey } = useStateTabs({
    tabs: {
      booked: {
        label: t('future'),
      },
      past: {
        label: t('past'),
      },
      cancelled: {
        label: t('cancelled'),
      },
    },
    firstSelected,
  });

  useEffect(() => {
    if (selectedTabKey) {
      setSelectedAppointmentsTab(selectedTabKey);
    }
  }, [selectedTabKey, setSelectedAppointmentsTab]);

  const appointments =
    selectedTabKey === 'booked'
      ? bookedAppointments
      : selectedTabKey === 'past'
      ? pastAppointments
      : cancelledAppointments;

  const appointmentColumns = usePatientAppointmentsColumns({
    type: selectedTabKey,
    bookedAppointments,
    pastAppointments,
    cancelledAppointments,
    onAppointmentInfoClicked,
    onAppointmentDeleteClicked,
  });

  return (
    <Card>
      <PatientAppointmentsHeader
        onClose={onClose}
        tabsConfig={tabsConfig}
        pastAppointments={pastAppointments}
        user={user}
      />
      {
        <BasicTable
          columns={appointmentColumns}
          data={appointments}
          resizingKey={selectedTabKey}
        />
      }
      {isLoading && <CardLoader fillWrapper={true} />}
    </Card>
  );
};
