import { Appointment } from '../../../../types/appointments';
import { IReferrerForm } from './AppointmentReferrers';
import { UserData } from '../../../../types/authentication';

export const getReferrerIds = (
  referrerValues: IReferrerForm,
  user?: UserData,
): Pick<Appointment, 'referrer_id' | 'gp_surgery_id'> => {
  return {
    gp_surgery_id: referrerValues.generalPractise
      ? user?.gp_surgery_id
      : undefined,
    referrer_id: referrerValues.otherRefererId,
  };
};

export const isGeneralPractise = (appointemnt: Appointment): boolean => {
  return Boolean(appointemnt.gp_surgery_id);
};
