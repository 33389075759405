import { AxiosError } from 'axios';
import { LinkCardTabs } from 'components/Card/CardTabs/LinkCardTabs';
import { Card, CardHeader } from 'components/common';
import { CommonButton, CommonLink } from 'components/common/Forms/Button';
import {
  FormActionsStyle,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import { PageHelmet } from 'components/common/PageHelmet';
import { MemberAccountForm } from 'components/views-components/patient/profile/MemberAccountForm';
import { SharedAccountsView } from 'components/views-components/patient/profile/SharedAccountsView/SharedAccountsView';
import { ProfileTabsTree } from 'configs/RoutesConfig';
import { PatientLayout } from 'containers/PatientLayout';
import { useAuth } from 'context/AuthContext';
import React, { ReactElement, useCallback, useState } from 'react';
import {
  FaCheck,
  FaTimes,
  FaUserCheck,
  FaUserCog,
  FaUserEdit,
} from 'react-icons/fa';
import { useHistory } from 'react-router';
import { getUserProfile, updateUserProfile } from 'services/userProfile';
import { UserMemberData } from 'types/authentication';
import { UserFormValues } from 'types/users';
import { errorToast } from 'utils/toast';
import { InlineConfirm } from '../../../../components/Popup/ConfirmDialog/InlineConfirm';
import { toRem } from '../../../../utils/helpers';
import { patientURL } from '../../../AppRoutes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';

export default function SharedAccountsPage(): ReactElement {
  const { sangixUser, updateAuthUser } = useAuth();
  const [prefilledData, setPrefilledData] = useState<UserMemberData>();
  const [showForm, setShowForm] = useState(false);
  const [showLimitDialogue, setShowLimitDialogue] = useState(false);
  const [isFormDeleteMember, setIsFormDeleteMember] = useState(false);
  const [selectedMemberToPrimary, setSelectedMemberToPrimary] = useState<
    UserMemberData | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const { t } = useCommonTranslation();
  const { push } = useHistory();

  const showSuccessDialogue = useCallback(
    () =>
      getUserProfile()
        .then(({ data }) => {
          updateAuthUser({
            sangixUser: data,
          });
          setIsLoading(false);
          setPrefilledData(undefined);
          setShowForm(false);
          setIsFormSuccess(true);
          setIsFormDeleteMember(false);
          setSelectedMemberToPrimary(undefined);
        })
        .catch((err: AxiosError) => {
          setIsLoading(false);
          errorToast(err);
        }),
    [updateAuthUser],
  );

  const handleDeleteMember = useCallback(
    (e) => {
      e.preventDefault();
      setIsLoading(true);

      const memberIndex =
        sangixUser?.other_members &&
        prefilledData?.id &&
        sangixUser.other_members.findIndex(
          (member) => member.id === prefilledData.id,
        );
      const other_members = sangixUser?.other_members || [];
      if (typeof memberIndex === 'number' && memberIndex !== -1) {
        other_members.splice(memberIndex, 1);
      }

      updateUserProfile({
        ...(sangixUser as UserFormValues),
        other_members,
      })
        .then(() => showSuccessDialogue())
        .catch((err) => {
          setIsLoading(false);
          errorToast(err);
        });
    },
    [prefilledData, sangixUser, showSuccessDialogue],
  );

  const confirmMakePrimary = useCallback(
    (selectedMember: UserMemberData) => {
      if (sangixUser) {
        const {
          email,
          gdpr_confirmation,
          registration,
          roles,
          other_members,
          active_site_id,
          id,
          ...theMemberFromPrimary
        } = sangixUser;

        const arrOfMembers: UserMemberData[] = sangixUser.other_members.map(
          (member: UserMemberData) =>
            member.id === selectedMember.id
              ? { ...theMemberFromPrimary, relation: 5, id: selectedMember.id }
              : member,
        );

        const structuredData: UserFormValues = {
          ...sangixUser,
          ...selectedMember,
          id: sangixUser.id,
          other_members: arrOfMembers,
        };

        setIsLoading(true);
        updateUserProfile(structuredData)
          .then(() => showSuccessDialogue())
          .catch((err) => {
            setIsLoading(false);
            errorToast(err);
          });
      }
    },
    [showSuccessDialogue, sangixUser],
  );

  const onAddMemberClicked = useCallback(() => {
    if (sangixUser?.other_members && sangixUser.other_members.length > 4) {
      setShowLimitDialogue(true);
    } else {
      setPrefilledData(undefined);
      setShowForm(true);
    }
  }, [sangixUser, setShowLimitDialogue, setShowForm]);

  const onEditMemberClicked = useCallback(
    (user: UserMemberData) => {
      setPrefilledData(user);
      setShowForm(true);
    },
    [setPrefilledData, setShowForm],
  );

  return (
    <PatientLayout graphics="patient">
      <PageHelmet title={t('shared-accounts')} />
      {isFormSuccess ? (
        <InlineConfirm
          isLoading={false}
          icon={<FaUserCheck />}
          inlineIcon
          title={t('profile-updated-successfully')}
          status="success"
          actions={[
            <CommonButton
              variant="primary"
              type="button"
              key={'ok-confirmation'}
              onClick={() => setIsFormSuccess(false)}
            >
              {t('ok')}
            </CommonButton>,
          ]}
        />
      ) : showLimitDialogue ? (
        <InlineConfirm
          icon={<FaTimes />}
          isLoading={isLoading}
          title={t('max-shared-accounts')}
          status="delete"
          actions={[
            <CommonButton
              variant="danger"
              type="button"
              key={'ok-max-accounts'}
              onClick={() => setShowLimitDialogue(false)}
            >
              {t('ok')}
            </CommonButton>,
          ]}
        />
      ) : !showForm && !isFormDeleteMember && !selectedMemberToPrimary ? (
        <Card>
          <CardHeader>
            <FaUserCog />
            <FormTitle>{t('my-profile')}</FormTitle>
          </CardHeader>
          <LinkCardTabs tabs={ProfileTabsTree(t)} fluid />
          <SharedAccountsView
            userProfile={sangixUser}
            setSelectedMemberToPrimary={setSelectedMemberToPrimary}
            onAddMemberClicked={onAddMemberClicked}
            onEditMemberClicked={onEditMemberClicked}
            onPrimaryMemberEditClicked={() => {
              push(`${patientURL}/profile`);
            }}
            actionForm={
              <FormActionsStyle>
                <CommonLink
                  type="button"
                  variant="secondary"
                  style={{ minWidth: `${toRem(160)}rem` }}
                  to={patientURL}
                >
                  {t('back-to-menu')}
                </CommonLink>
              </FormActionsStyle>
            }
          />
        </Card>
      ) : selectedMemberToPrimary ? (
        <InlineConfirm
          icon={<FaCheck />}
          isLoading={isLoading}
          title={t('confirm-make-user-primary')}
          status="delete"
          actions={[
            <CommonButton
              variant="danger"
              type="button"
              key={'yes-confirmation-primary'}
              onClick={() => confirmMakePrimary(selectedMemberToPrimary)}
            >
              {t('yes')}
            </CommonButton>,
            <CommonButton
              variant="secondary"
              type="button"
              key={'no-confirmation-primary'}
              onClick={() => setSelectedMemberToPrimary(undefined)}
            >
              {t('no')}
            </CommonButton>,
          ]}
        />
      ) : isFormDeleteMember ? (
        <InlineConfirm
          icon={<FaCheck />}
          isLoading={isLoading}
          title={t('delete-member-confirm-title')}
          description={t('delete-member-confirm-desc')}
          status="delete"
          actions={[
            <CommonButton
              variant="danger"
              type="button"
              key={'yes-confirmation-settings'}
              onClick={handleDeleteMember}
            >
              {t('yes')}
            </CommonButton>,
            <CommonButton
              variant="secondary"
              type="button"
              key={'no-confirmation-delete'}
              onClick={() => setIsFormDeleteMember(false)}
            >
              {t('no')}
            </CommonButton>,
          ]}
        />
      ) : (
        <Card>
          <CardHeader>
            <FaUserEdit />
            <FormTitle>
              {prefilledData?.id ? t('edit-shared-user') : t('add-shared-user')}
            </FormTitle>
          </CardHeader>
          <MemberAccountForm
            prefilledData={prefilledData}
            setPrefilledData={setPrefilledData}
            setShowForm={setShowForm}
            setIsFormSuccess={setIsFormSuccess}
            setIsFormDeleteMember={setIsFormDeleteMember}
          />
        </Card>
      )}
    </PatientLayout>
  );
}
