import { useExistingAppointments } from '../../../query/appointments';
import { Appointment } from '../../../types/appointments';
import { CubicleData } from '../../../types/cubicles';
import { Refetch } from '../../../types/query';
import {
  MILLIS_IN_SECOND,
  todayInIso,
  tomorrowInIso,
} from '../../../utils/dateUtil';

export const useQueuedAppointments = ({
  cubicle,
}: {
  cubicle: CubicleData;
}): {
  isLoading: boolean;
  appointments: Appointment[] | null | undefined;
  dataUpdatedAt: number;
  refetch: Refetch;
} => {
  const today = todayInIso();
  const tomorrow = tomorrowInIso();
  const { data, isLoading, dataUpdatedAt, refetch } = useExistingAppointments(
    {
      statuses: ['QUEUE'],
      fromDate: today,
      toDate: tomorrow,
      resourceTypeIDs: cubicle.resource_types.map((r) => r.id),
    },
    {
      refetchInterval: 5 * MILLIS_IN_SECOND,
    },
  );

  return {
    isLoading,
    /**
     * Optimistic cache only update ignore filters, so this is useful for that case
     */
    appointments: data?.data.data.filter(({ status }) => {
      return status === 'QUEUE';
    }),
    dataUpdatedAt,
    refetch,
  };
};
