import React, { useCallback, useState } from 'react';
import { UserData, UserMemberData } from '../../../../../types/authentication';
import { AppointmentCard } from '../../../../../components/views-components/staff/appointments/AppointmentCard';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { FaUserAlt } from 'react-icons/fa';
import { useGetUserAccount } from '../../../../../query/users';
import { SharedAccountsView } from '../../../../../components/views-components/patient/profile/SharedAccountsView/SharedAccountsView';
import { ButtonSetWithCloseButton } from '../../../../../components/Form/ButtonSetWithCloseButton';
import { CommonButton } from '../../../../../components/common/Forms/Button';
import { StaffMemberEditCard } from './StaffMemberEditCard';
import { StaffSelectMemberToMerge } from './StaffSelectMemberToMerge';
import { isMainMember } from '../../../../../utils/user';

type IProps = {
  closeFormHandler: (userAfterChange?: UserData) => void;
  onBackClicked: () => any;
  user: UserData;
};

export const StaffPatientMembers: React.FC<IProps> = ({
  user,
  closeFormHandler,
  onBackClicked,
}) => {
  const { t } = useCommonTranslation();
  const { data, isLoading: isUserAccountLoading } = useGetUserAccount(
    user.user_account_id,
  );
  const [searchToPatientToMerge, setSearchPatientToMerge] = useState(false);

  const [memberFormConfig, setMemberFormConfig] = useState<{
    show: boolean;
    userToEdit?: UserMemberData;
  }>({
    show: false,
  });

  const onEditMemberClicked = useCallback(
    (userToEdit: UserMemberData) => {
      if (isMainMember(userToEdit)) {
        onBackClicked();
      } else {
        setMemberFormConfig({
          show: true,
          userToEdit,
        });
      }
    },
    [onBackClicked],
  );

  const onAddMemberClicked = useCallback(() => {
    setMemberFormConfig({
      show: true,
    });
  }, [setMemberFormConfig]);

  return (
    <>
      {searchToPatientToMerge ? (
        <StaffSelectMemberToMerge
          onClose={() => setSearchPatientToMerge(false)}
          user={user}
        />
      ) : memberFormConfig.show ? (
        <StaffMemberEditCard
          onBackClicked={() => setMemberFormConfig({ show: false })}
          closeFormHandler={closeFormHandler}
          userToEdit={memberFormConfig.userToEdit}
          primaryUser={user}
        />
      ) : (
        <AppointmentCard
          isLoading={isUserAccountLoading}
          title={t('members')}
          onCloseClicked={closeFormHandler}
          icon={<FaUserAlt />}
        >
          <SharedAccountsView
            userProfile={data?.data}
            onEditMemberClicked={onEditMemberClicked}
            onAddMemberClicked={onAddMemberClicked}
            onPrimaryMemberEditClicked={onBackClicked}
            onAddExistingMemberClicked={() => setSearchPatientToMerge(true)}
            actionForm={
              <ButtonSetWithCloseButton
                onCloseClicked={closeFormHandler}
                closeLabel={t('close')}
              >
                <CommonButton
                  variant="primary"
                  type={'button'}
                  onClick={onBackClicked}
                >
                  {t('back')}
                </CommonButton>
              </ButtonSetWithCloseButton>
            }
          />
        </AppointmentCard>
      )}
    </>
  );
};
