import { useUser } from '../../query/users';
import { UserData } from '../../types/authentication';
import { useState } from 'react';

/**
 * If user comes from dialog for example it won't get updated if
 * the react query key is invalidated. This makes sure it happens in the background.
 */
export const useSelectUserWithHiddenRefetch = () => {
  const [selectedUserInner, setSelectedInnerUser] = useState<UserData>();
  const { data: userDetail } = useUser(selectedUserInner?.id);
  return {
    selectedUser: userDetail?.data || selectedUserInner,
    setSelectedUser: setSelectedInnerUser,
  };
};
