import { CommonButton as Button } from 'components/common/Forms/Button';
import {
  RowActions,
  TableActionNote,
  TableActions,
} from 'components/Table/Table.styled';
import React, { ReactElement, useMemo } from 'react';
import { useHistory } from 'react-router';
import { CellProps, Column } from 'react-table';
import { TableHeadStyle } from 'styles/Typography';
import { CubicleData } from 'types/cubicles';
import { getNewColumnWidth } from 'utils/table';

import { BasicTable } from '../../../components/Table/BasicTable';
import { SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE } from '../../../configs/siteAndTrustAttributes';
import { useSiteBooleanAttributes } from '../../../hooks/useAttribute';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { useMutateCallAppointment } from '../../../query/appointments';
import { queuedProcessingApptRoute } from '../../../routes/staff/StaffRoutesAndMenu';
import { Appointment } from '../../../types/appointments';
import {
  formatDateOnlySite,
  formatHourMinutes,
  parseDateKeepTZ,
  parseIsoDate,
} from '../../../utils/dateUtil';
import { useQueuedAppointments } from './CubicleQueueHooks';
import { PatientInfoRow } from './PatientInfoRow';

interface Props {
  cubicle: CubicleData;
  clearSelectedCubicle: () => void;
}

type QueueItem = {
  apptTime: string;
  checkinTime: string;
  user_full_name?: string;
  resourceAbbr: string;
} & Pick<
  Appointment,
  'appt_flags' | 'id' | 'user_birthdate' | 'user_disabilities'
>;

export const CubicleQueue = ({
  cubicle,
  clearSelectedCubicle,
}: Props): ReactElement => {
  const { t } = useCommonTranslation();
  const {
    isLoading: isQueuedApptsLoading,
    appointments,
    dataUpdatedAt,
    refetch,
  } = useQueuedAppointments({ cubicle });
  const [showBirthDate] = useSiteBooleanAttributes(
    SHOW_BIRTH_DATE_IN_CUBICLE_QUEUE,
  );

  const { replace } = useHistory();

  const { isLoading: callAppointmentLoading, mutate: callAppointment } =
    useMutateCallAppointment({
      /**
       * Needs to navigate after the cache has been changed and correct queries invalidated.
       */
      successAfterSettled: (apptId) =>
        replace(queuedProcessingApptRoute(apptId)),
    });

  const isLoading = isQueuedApptsLoading || callAppointmentLoading;

  const data: QueueItem[] | undefined = appointments?.map((appt) => {
    const apptTime = parseDateKeepTZ(appt.appt_time);
    const checkinTime = parseDateKeepTZ(appt.checkin_time);
    return {
      apptTime: formatHourMinutes(apptTime.getHours(), apptTime.getMinutes()),
      checkinTime: formatHourMinutes(
        checkinTime.getHours(),
        checkinTime.getMinutes(),
      ),
      user_full_name: appt.user_full_name,
      resourceAbbr: appt.resource_type_name,
      appt_flags: appt.appt_flags,
      id: appt.id,
      user_birthdate: appt.user_birthdate,
      user_disabilities: appt.user_disabilities,
    };
  });

  const columns = useMemo(() => {
    const columnConfig = [
      {
        Header: t('appt-time'),
        accessor: 'apptTime',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(12),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t('check-in-time'),
        accessor: 'checkinTime',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(12),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: t('patient-name'),
        accessor: 'user_full_name',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(22),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t('cal-template'),
        accessor: 'resourceAbbr',
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(15),
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: t('patient-info'),
        cellStyle: TableHeadStyle,
        accessor: (row) => row,
        width: getNewColumnWidth(20),
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }: CellProps<QueueItem>) => {
          return <PatientInfoRow {...value} />;
        },
      },
    ] as Column<QueueItem>[];
    if (showBirthDate)
      columnConfig.push({
        Header: t('date-of-birth'),
        cellStyle: TableHeadStyle,
        width: getNewColumnWidth(15),
        accessor: (item: QueueItem) =>
          item.user_birthdate
            ? formatDateOnlySite(parseIsoDate(item.user_birthdate))
            : '',
        disableSortBy: true,
        disableFilters: true,
        disableResizing: true,
      } as Column<QueueItem>);
    columnConfig.push({
      Header: '',
      accessor: 'id',
      align: 'right',
      disableResizing: true,
      disableFilters: true,
      disableSortBy: true,
      style: {
        flex: 1,
      },
      Cell: ({ value }: { value: string }) => {
        const calledAppointment = appointments?.find((a) => a.id === value);
        return (
          <RowActions>
            <Button
              variant="primary"
              onClick={() => {
                calledAppointment && callAppointment(calledAppointment.id);
              }}
            >
              {t('call')}
            </Button>
          </RowActions>
        );
      },
    } as Column<QueueItem>);
    return columnConfig;
    /**
     * Appointment response is shallow compared, that's why we
     * need the dataUpdatedAt also to verify if cached data items
     * in appointments response have changed
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments, callAppointment, dataUpdatedAt, t, showBirthDate]);

  return (
    <>
      <TableActions>
        <TableActionNote isStatic={true}>
          {t('cubicle')}: {cubicle.name}
        </TableActionNote>
      </TableActions>
      <BasicTable columns={columns} data={data} isLoading={isLoading} />
      <TableActions belowForm={true}>
        <Button variant="primary" type="button" onClick={clearSelectedCubicle}>
          {t('leave-cubicle')}
        </Button>
        <Button variant="secondary" type="button" onClick={() => refetch()}>
          {t('refresh')}
        </Button>
      </TableActions>
    </>
  );
};
