import { Card } from 'components/common';
import { PageHelmet } from 'components/common/PageHelmet';
import {
  StatusDesc,
  StatusTitle,
} from 'components/views-components/statuses/statuses.styled';
import { PatientLayout } from 'containers/PatientLayout';
import { Link } from 'react-router-dom';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';

const Page500 = () => {
  const { t } = useCommonTranslation();
  return (
    <PatientLayout graphics="500" hideSupport>
      <PageHelmet title={t('internal-server-error')} />
      <Card>
        <StatusTitle>{t('we-have-internal-server-error')}</StatusTitle>
        <StatusDesc>
          {t('try-again-later-or')}{' '}
          <Link to="/contact-us">{t('contact-us')}</Link>
        </StatusDesc>
      </Card>
    </PatientLayout>
  );
};

export default Page500;
