import React, { useMemo } from 'react';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { CardHeader } from 'components/common';
import { IStateTab, StateTabs } from '../../../../Card/CardTabs/StateTabs';
import { CloseButton, FormTitle } from '../../../../common/Forms/Forms.styled';
import { FaRegListAlt, FaTimes } from 'react-icons/fa';
import { AppointmentRow } from '../appointmentStyles';
import { InfoLabelText } from '../../../../common/Forms/InfoLabelText';
import { UserData } from '../../../../../types/authentication';
import { Appointment } from '../../../../../types/appointments';

export interface IPatientAppointmentsCardHeaderProps {
  tabsConfig: IStateTab[];
  onClose: () => unknown;
  user?: UserData;
  pastAppointments?: Appointment[];
}

export const PatientAppointmentsHeader: React.FC<
  IPatientAppointmentsCardHeaderProps
> = ({ onClose, tabsConfig, user, pastAppointments }) => {
  const { t } = useCommonTranslation();

  const dnaToTotal = useMemo(() => {
    if (pastAppointments) {
      return `${
        pastAppointments.filter(({ status }) => status === 'DNA').length
      } /
        ${pastAppointments.length}`;
    }
  }, [pastAppointments]);

  return (
    <>
      <StateTabs tabs={tabsConfig} />
      <CloseButton
        size="auto"
        variant="primary"
        style={{
          top: 'var(--s3)',
          right: 'var(--s3)',
        }}
        iconOnly
        onClick={onClose}
      >
        <FaTimes />
      </CloseButton>
      <CardHeader oneLine>
        <FaRegListAlt />
        <FormTitle>{t('appointment-list')}</FormTitle>
      </CardHeader>
      <AppointmentRow>
        <InfoLabelText label={t('first-name')} text={user?.firstname} />
        <InfoLabelText label={t('surname')} text={user?.lastname} />
        <InfoLabelText
          label={t('appointemnt-dna-to-total')}
          text={dnaToTotal}
        />
      </AppointmentRow>
    </>
  );
};
