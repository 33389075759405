import React from 'react';
import {
  FutureAppointments,
  PatientButtons,
} from '../../../../../containers/staff/calendar/BookAppointmentWithPatient.styled';
import { CommonButton, CommonLink } from '../../../../common/Forms/Button';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { UserData } from '../../../../../types/authentication';
import { ThreeDotLoader } from '../../../../common/DotLoader';
import { useUserMemberAppointments } from '../../../../../hooks/appointments/useUserMemberAppointments';
import { USER_APPOINTMENTS_TYPES_BOOKED } from '../../../../../utils/appointmentUtil';
import { useSiteStringAttributes } from '../../../../../hooks/useAttribute';
import { SITE_ATTRIBUTE_SALUTARE_INTEGRATION_BASE_URL } from '../../../../../configs/siteAndTrustAttributes';
import { generateSalutareIntegrationAppointmentLink } from '../../../../../utils/salutareIntegrationUtil';

export interface IPatientEditAndAppointmentsProps {
  onPatientButtonClicked: (buttonType: 'userEdit' | 'userAppointments') => any;
  onPatientSelectButtonClicked?: () => void;
  user?: UserData;
}

export const PatientAppointmentsAndEditBlock = ({
  onPatientButtonClicked,
  onPatientSelectButtonClicked,
  user,
}: IPatientEditAndAppointmentsProps) => {
  const { t } = useCommonTranslation();

  const [salutareIntegrationUrl] = useSiteStringAttributes(
    SITE_ATTRIBUTE_SALUTARE_INTEGRATION_BASE_URL,
  );

  const { data, isLoading } = useUserMemberAppointments({
    user,
    apptType: USER_APPOINTMENTS_TYPES_BOOKED,
  });
  return (
    <>
      <FutureAppointments>
        {t('appointment-detail-future-appointments')}
        {': '}
        {isLoading || !user ? (
          <ThreeDotLoader />
        ) : data ? (
          data.length
        ) : (
          /**
           *  The empty string is really unexpected error state that should not happen without an error message
           */
          ''
        )}
      </FutureAppointments>
      <PatientButtons>
        <CommonButton
          variant={'primary'}
          onClick={() => {
            onPatientButtonClicked('userEdit');
          }}
          type={'button'}
        >
          {t('edit-patient')}
        </CommonButton>
        <CommonButton
          variant={'primary'}
          onClick={() => {
            onPatientButtonClicked('userAppointments');
          }}
          type={'button'}
        >
          {t('appointments')}
        </CommonButton>
        {salutareIntegrationUrl && user && (
          <CommonLink
            variant={'primary'}
            to={{
              pathname: generateSalutareIntegrationAppointmentLink({
                salutareIntegrationUrl,
                user,
              }),
              target: '_blank',
            }}
            target={'_blank'}
          >
            {t('salutare')}
          </CommonLink>
        )}
        {onPatientSelectButtonClicked && (
          <CommonButton
            variant={'primary'}
            onClick={() => {
              onPatientSelectButtonClicked();
            }}
            type={'button'}
          >
            {t('select')}
          </CommonButton>
        )}
      </PatientButtons>
    </>
  );
};
