import React, { useCallback, useEffect, useReducer } from 'react';
import { TimePlannerReducer } from './reducers';
import { timePlannerDefaultState } from './state';
import { formatDateOnlyISO, getWeekDay } from '../../utils/dateUtil';
import { TimePlannerActions, TimePlannerActionType } from './types';
import { getIntervalEntry } from './actions';
import { ApptTemplate } from '../../types/apptTemplate';
import { ResourceType } from '../../types/resource-types';
import { timePlannerHasStaffOnlyItems } from './timePlannerUtils';
import { getDaySlotsByHour } from '../../utils/appointmentUtil';
import { BankHolidayData } from '../../types/bankHoliday';

export const useTimePlanner = ({
  selectedDate,
  selectedResourceType,
  selectedResourceCubiclesAssigned,
  apptTemplate,
  defaultTemplate,
  setShowOnline,
  showOnline,
  openHourInSeconds,
  closeHourInSeconds,
}: {
  defaultTemplate?: ApptTemplate;
  apptTemplate?: ApptTemplate;
  selectedResourceCubiclesAssigned: number;
  openHourInSeconds: number;
  closeHourInSeconds: number;
  selectedResourceType: ResourceType;
  selectedDate: Date;
  setShowOnline: React.Dispatch<React.SetStateAction<boolean>>;
  showOnline: boolean;
}) => {
  const dayNumber = getWeekDay(selectedDate);
  const renderIdRef = React.useRef(0);
  const [state, dispatch] = useReducer(TimePlannerReducer, {
    ...timePlannerDefaultState({
      openHourInSeconds,
      closeHourInSeconds,
      selectedResourceCubiclesAssigned,
    }),
  });
  const hoursLength = closeHourInSeconds - openHourInSeconds;

  const selectedTemplate = apptTemplate || defaultTemplate;

  const resetTimePlannerToDefault = useCallback(() => {
    defaultTemplate &&
      dispatch(
        getSetDefaultAction(
          {
            selectedResourceCubiclesAssigned,
            closeHourInSeconds,
            openHourInSeconds,
            selectedResourceType,
            setShowOnline,
            selectedDate,
            showOnline,
            dayNumber,
            hoursLength,
          },
          defaultTemplate,
        ),
      );
  }, [
    dayNumber,
    hoursLength,
    showOnline,
    defaultTemplate,
    selectedResourceCubiclesAssigned,
    closeHourInSeconds,
    openHourInSeconds,
    selectedResourceType,
    setShowOnline,
    selectedDate,
  ]);
  const resetTimePlanner = useCallback(() => {
    if (selectedTemplate) {
      dispatch(
        getSetDefaultAction(
          {
            selectedResourceCubiclesAssigned,
            closeHourInSeconds,
            openHourInSeconds,
            selectedResourceType,
            setShowOnline,
            selectedDate,
            showOnline,
            dayNumber,
            hoursLength,
          },
          selectedTemplate,
        ),
      );
    } else {
      // No template selected. This can happen temporarily when changing site.
    }
  }, [
    selectedTemplate,
    showOnline,
    selectedResourceType,
    setShowOnline,
    openHourInSeconds,
    closeHourInSeconds,
    selectedResourceCubiclesAssigned,
    dayNumber,
    selectedDate,
    hoursLength,
  ]);

  useEffect(() => {
    if (renderIdRef.current > 1) {
      dispatch({
        type: TimePlannerActionType.SET_ONLINE_ACTION,
      });
    }
    renderIdRef.current++;
  }, [showOnline]);

  useEffect(() => {
    resetTimePlanner();
  }, [resetTimePlanner]);
  return {
    dispatch,
    state,
    resetTimePlanner,
    resetTimePlannerToDefault,
  };
};

const getSetDefaultAction = (
  {
    selectedDate,
    selectedResourceType,
    selectedResourceCubiclesAssigned,
    setShowOnline,
    showOnline,
    openHourInSeconds,
    closeHourInSeconds,
    dayNumber,
    hoursLength,
  }: {
    selectedResourceCubiclesAssigned: number;
    openHourInSeconds: number;
    closeHourInSeconds: number;
    selectedResourceType: ResourceType;
    selectedDate: Date;
    setShowOnline: React.Dispatch<React.SetStateAction<boolean>>;
    showOnline: boolean;
    dayNumber: number;
    hoursLength: number;
  },
  selectedTemplate: ApptTemplate,
): TimePlannerActions => {
  const isShowOnline = timePlannerHasStaffOnlyItems(selectedTemplate);
  if (isShowOnline) setShowOnline(true);
  const dayAvailable = Math.max(
    ...selectedTemplate.entries.map((entry) => entry.available_cubicles),
  );
  return {
    type: TimePlannerActionType.SET_DEFAULT_STATE,
    payload: {
      closeHourInSeconds: closeHourInSeconds,
      openHourInSeconds: openHourInSeconds,
      selectedResourceCubiclesAssigned: selectedResourceCubiclesAssigned,
      timeInterval: selectedResourceType.length,
      day: {
        cubicle: 0,
        staff_only: 0,
        available_cubicles: dayAvailable,
        dayNumber: dayNumber,
        selected_date: selectedDate,
        hours: getDaySlotsByHour({
          openHourInSeconds,
          closeHourInSeconds,
          slotLengthInMinutes: selectedResourceType.length,
        }).map(({ hour, minutes }) => {
          const intervals = minutes.map((minute) => {
            const intervalEntry = getIntervalEntry({
              timeTemplate: selectedTemplate,
              hourIndex: hour,
              minutes: minute,
            });
            return {
              cubicle:
                (intervalEntry?.cubicle_count as number) -
                  (intervalEntry?.staff_only as number) || 0,
              staff_only: intervalEntry?.cubicle_count || 0,
              available_cubicles: intervalEntry?.available_cubicles || 0,
              minute: minute,
            };
          });
          const hourAvailable =
            intervals.length > 0
              ? Math.max(...intervals.map((entry) => entry.available_cubicles))
              : 0;

          return {
            hourNumber: hour,
            cubicle: 0,
            staff_only: 0,
            available_cubicles: hourAvailable,
            intervals: intervals,
          };
        }),
      },
    },
  };
};

export const useFilterDateWeekDayAndNotBankHoliday = ({
  weekDays,
  bankHolidays,
}: {
  weekDays: number[] | undefined;
  bankHolidays?: BankHolidayData[];
}) => {
  return useCallback(
    (date: Date) => {
      const day = getWeekDay(date);
      const holidaysDates =
        bankHolidays?.map((holiday) => holiday.holiday_date) || [];
      return (
        Boolean(weekDays?.includes(day)) &&
        !holidaysDates.includes(formatDateOnlyISO(date))
      );
    },
    [weekDays, bankHolidays],
  );
};
