import React, { useMemo } from 'react';
import { Appointment, UserAppointmentsTypes } from 'types/appointments';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import {
  formatDateTimeToMinutesSite,
  getDateTimeOnlyFromISO,
  parseIsoDate,
} from '../../../../../utils/dateUtil';
import { getColumn } from '../../../../../utils/table';
import { RowActions } from '../../../../Table/Table.styled';
import { CommonButton } from '../../../../common/Forms/Button';
import { Column } from 'react-table';
import { BsEyeFill } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import { useAppointmentStateName } from '../../../../../hooks/appointments/useAppointmentStateName';
import { TFunction } from 'i18next';
import { UserAppointmentSelect } from '../../../../../hooks/staff/useFlowEditAppointment';
import { useQuerySites } from '../../../../../query/sites';

const controlColumn = ({
  onAppointmentInfoClicked,
  onAppointmentDeleteClicked,
  type,
  appointments,
}: {
  type: UserAppointmentsTypes;
  onAppointmentInfoClicked: (appointmentId: UserAppointmentSelect) => void;
  onAppointmentDeleteClicked: (appointmentId: string) => void;
  appointments?: Appointment[];
}) =>
  ({
    Header: '',
    disableResizing: true,
    accessor: 'id',
    align: 'right',
    disableFilters: true,
    minWidth: 0,
    cellStyle: {
      padding: 0,
      flex: 1,
    },
    width: 'auto',
    style: {
      flex: 1,
    },
    disableSortBy: true,
    Cell: ({ value }: { value: string }) => {
      const selectedAppointment = appointments?.find((a) => a.id === value);
      return (
        <RowActions>
          {type === 'booked' && (
            <CommonButton
              iconOnly
              variant={'danger'}
              onClick={() => onAppointmentDeleteClicked(value)}
            >
              <FaTrashAlt />
            </CommonButton>
          )}
          <CommonButton
            iconOnly
            variant={'primary'}
            onClick={() =>
              selectedAppointment &&
              onAppointmentInfoClicked({
                type: type,
                id: selectedAppointment.id,
                user_account_id: selectedAppointment.user_account_id,
              })
            }
          >
            <BsEyeFill />
          </CommonButton>
        </RowActions>
      );
    },
  } as Column<Appointment>);

const cancelledDateAndUser = (t: TFunction): Column<Appointment>[] => {
  return [
    getColumn({
      Header: t('appointment-cancel-date-and-time'),
      accessor: (a) =>
        a.cancel_time &&
        formatDateTimeToMinutesSite(
          parseIsoDate(getDateTimeOnlyFromISO(a.cancel_time)),
        ),
      width: 14,
      disableSortBy: true,
    }),
    getColumn({
      Header: t('appointment-cancel-user'),
      accessor: 'cancelled_by_member_full_name',
      disableSortBy: true,
      disableResizing: true,
      align: 'right',
    }),
  ] as Column<Appointment>[];
};

export const usePatientAppointmentsColumns = ({
  type,
  onAppointmentInfoClicked,
  onAppointmentDeleteClicked,
  pastAppointments,
  cancelledAppointments,
  bookedAppointments,
}: {
  type: 'booked' | 'past' | 'cancelled';
  onAppointmentInfoClicked: (appointmentId: UserAppointmentSelect) => void;
  onAppointmentDeleteClicked: (appointmentId: string) => void;
  bookedAppointments?: Appointment[];
  pastAppointments?: Appointment[];
  cancelledAppointments?: Appointment[];
}) => {
  const appointments =
    type === 'booked'
      ? bookedAppointments
      : type === 'past'
      ? pastAppointments
      : cancelledAppointments;
  const { t } = useCommonTranslation();
  const { data: siteResponseData } = useQuerySites();
  const stateName = useAppointmentStateName();
  return useMemo(() => {
    const columns = [
      getColumn({
        Header: t('date-and-time'),
        accessor: (a) =>
          formatDateTimeToMinutesSite(
            parseIsoDate(getDateTimeOnlyFromISO(a.appt_time)),
          ),
        disableSortBy: true,
        width: 14,
      }),
      getColumn({
        Header: t('site'),
        accessor: (a) => {
          const site = siteResponseData?.data.find((s) => s.id === a.site_id);
          return site?.short_name;
        },
        disableSortBy: true,
      }),
    ] as Column<Appointment>[];

    if (type === 'past') {
      columns.push(
        getColumn({
          Header: t('appointment-result'),
          accessor: (a) => stateName(a),
          disableSortBy: true,
        }),
      );
    }
    if (type === 'cancelled') {
      columns.push(...cancelledDateAndUser(t));
    } else {
      columns.push(
        controlColumn({
          onAppointmentInfoClicked,
          onAppointmentDeleteClicked,
          type,
          appointments,
        }),
      );
    }
    return columns;
  }, [
    t,
    onAppointmentInfoClicked,
    onAppointmentDeleteClicked,
    type,
    stateName,
    appointments,
    siteResponseData,
  ]);
};
