import Axios from 'configs/Axios';
import { UserData, UserProfileResponse } from 'types/authentication';
import { UserFormValues, UserUpgradeApi } from 'types/users';

export const getConfig = (token: string) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const register = (token: string, user: UserFormValues) =>
  Axios.post<UserProfileResponse>(`/register`, user, getConfig(token));

export const upgradeUser = (userUpgrade: UserUpgradeApi) =>
  Axios.post(`/upgrade`, userUpgrade);

export const loginUser = () => Axios.get<UserProfileResponse>('/login');

/**
 * This is called before firebase logout as a request from the BE
 * to be able to generate some reports needed.
 */
export const logoutUser = () => Axios.get('/logout');

export const getUserProfile = (account_id?: string) =>
  Axios.get<UserProfileResponse>(
    `/userProfile${account_id ? `/${account_id}` : ''}`,
  );

type UserServerUpdate = Omit<UserFormValues, 'birthdate'> & {
  birthDate: string;
};

export const updateUserProfile = (data: UserFormValues) =>
  Axios.put<UserServerUpdate>(`/userProfile`, data);

export const deleteUserProfile = () => Axios.delete(`/userProfile`);

export const updateUserActiveSite = (siteId: string) =>
  Axios.put<UserData>(`/userProfile/site/${siteId}`);

export const ping = () => Axios.get('/ping');
