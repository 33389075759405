import { UserData } from '../types/authentication';

export const generateSalutareIntegrationAppointmentLink = ({
  salutareIntegrationUrl,
  user,
}: {
  salutareIntegrationUrl: string;
  user: UserData;
}) => {
  const searchParams = new URLSearchParams();
  if (user.nhs_number) {
    searchParams.append('patientNumber', user.nhs_number);
  } else {
    if (user.birthdate) {
      searchParams.append('dob', user.birthdate);
    }
    if (user.firstname) {
      searchParams.append('forename', user.firstname);
    }
    if (user.lastname) {
      searchParams.append('surname', user.lastname);
    }
  }
  return `${salutareIntegrationUrl}/print?${searchParams.toString()}`;
};
