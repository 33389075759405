import { AxiosResponse } from 'axios';
import { BulletTable } from 'components/BulletTable/BulletTable';
import { CommonButton } from 'components/common/Forms/Button';
import { useConfirmDialog } from 'components/Popup/ConfirmDialog/confirmDialogHook';
import { useSelectFromArrayViaId } from 'hooks/useSelectFromArrayViaId';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { find } from 'lodash';
import { useCubicles, useMutateUpdateCubicle } from 'query/cubicles';
import {
  useReportsSupervisor,
  useReportsSupervisorDetails,
} from 'query/reports';
import { useCallback, useMemo } from 'react';
import { FaEye } from 'react-icons/fa';
import { CubicleResponseData } from 'types/cubicles';
import { ReportCubicle } from 'types/reports';
import { successToast } from 'utils/toast';
import { useGetCubicleUpdateData } from 'utils/useGetCubicleUpdateData';

import { UNSAFE_FILTER_WITH_NO_PAGINATION } from '../../../hooks/usePaginatedFilters';
import { useSiteResourceTypes } from '../../../query/resourceTypes';
import { ReportsLayout } from './ReportsLayout';

const useMutateLogOffWithConfirm = (
  cubiclesData: AxiosResponse<CubicleResponseData> | undefined,
) => {
  const { confirm } = useConfirmDialog();
  const { t } = useCommonTranslation();
  const { mutateAsync: updateCubicle, ...rest } = useMutateUpdateCubicle();
  const getCubicleUpdateData = useGetCubicleUpdateData();
  const logOffWithConfirm = useCallback(
    (reportCubicle: ReportCubicle, afterConfirm: () => void) => {
      confirm({
        onConfirm: async () => {
          const cubicle = find(cubiclesData?.data.data, {
            id: reportCubicle.cubicle_id,
          });
          await updateCubicle({
            ...getCubicleUpdateData(cubicle),
            user_member_id: null,
          });
          afterConfirm();
          successToast(t('phlebotomist-log-off-success'));
        },
        title: t('phlebotomist-log-off-confirm-title'),
        confirmButtonVariant: 'danger',
      });
    },
    [confirm, updateCubicle, getCubicleUpdateData, cubiclesData, t],
  );
  return {
    logOffWithConfirm,
    ...rest,
  };
};

export const Supervisor = () => {
  const { t } = useCommonTranslation();
  const { data: supervisor, isFetching: isSupervisorFetching } =
    useReportsSupervisor();

  const { data: cubiclesData, isFetching: isCubicleDataFetching } =
    useCubicles();

  const { data: resourceTypesData, isLoading: isResourceTypesLoading } =
    useSiteResourceTypes({ ...UNSAFE_FILTER_WITH_NO_PAGINATION });
  const { logOffWithConfirm, isLoading: isLoggingOff } =
    useMutateLogOffWithConfirm(cubiclesData);

  const supervisorCubicles = useMemo(
    () => supervisor?.data || [],
    [supervisor],
  );

  const selectItems = useMemo(
    () =>
      supervisorCubicles.map((cubicle) => ({
        ...cubicle,
        id: cubicle.cubicle_id,
      })),
    [supervisorCubicles],
  );
  const { selected, setSelectedId } = useSelectFromArrayViaId({
    items: selectItems,
  });

  const { data: patients, isFetching: isPatientsFetching } =
    useReportsSupervisorDetails(selected?.id || '');

  const isLoading =
    isSupervisorFetching ||
    isPatientsFetching ||
    isLoggingOff ||
    isCubicleDataFetching ||
    isResourceTypesLoading;

  return (
    <ReportsLayout
      isLoading={isLoading}
      title={t('supervisor')}
      leftColMD={7}
      rightColMD={5}
      rightCardTitle={t('patient-list')}
      onRightCardClose={() => setSelectedId('')}
      leftCardNode={
        <BulletTable
          headers={[
            { text: t('cubicle-name') },
            { text: t('phlebotomist') },
            { text: t('seen-patients') },
            ...(resourceTypesData?.data?.map((resourceType) => ({
              text: resourceType.name,
            })) || []),
          ]}
          body={supervisorCubicles.map((cubicle) => {
            const isFilled = !!cubicle.user_member_name;
            return {
              selected: cubicle.cubicle_id === selected?.id,
              cells: [
                { bold: true, text: cubicle.cubicle_name },
                { text: cubicle.user_member_name },
                { text: isFilled ? cubicle.seen : '' },

                ...(resourceTypesData?.data?.map((resourceType) => ({
                  text: isFilled
                    ? cubicle.resource_types[resourceType.name] || 0
                    : '',
                })) || []),
                {
                  btn: true,
                  text: isFilled && (
                    <CommonButton
                      type="button"
                      variant="primary"
                      iconOnly
                      onClick={() => setSelectedId(cubicle.cubicle_id)}
                    >
                      <FaEye title={t('patient-list')} />
                    </CommonButton>
                  ),
                },
                ...(!cubicle.user_member_name
                  ? []
                  : [
                      {
                        btn: true,
                        text: (
                          <CommonButton
                            type="button"
                            variant="danger"
                            onClick={() =>
                              logOffWithConfirm(cubicle, () =>
                                setSelectedId(''),
                              )
                            }
                          >
                            {t('log-off')}
                          </CommonButton>
                        ),
                      },
                    ]),
              ],
            };
          })}
        />
      }
      rightCardNode={
        selected && (
          <BulletTable
            sort
            headers={[
              { text: t('name') },
              { text: t('appt') },
              { text: t('checked') },
              { text: t('called') },
              { text: t('arrived') },
              { text: t('processed') },
            ]}
            body={patients?.data.map((patient) => ({
              cells: [
                { bold: true, text: patient.name },
                { text: patient.appt_time },
                { text: patient.checked },
                { text: patient.called },
                { text: patient.arrived },
                { text: patient.processed },
              ],
            }))}
          />
        )
      }
    />
  );
};
