import { useCallback, useMemo, useState } from 'react';
import { UserAppointmentsTypes } from '../../types/appointments';
import { usePreviousDistinct } from 'react-use';
import { UserData } from '../../types/authentication';
import {
  USER_APPOINTMENTS_TYPES_BOOKED,
  USER_APPOINTMENTS_TYPES_CANCELLED,
  USER_APPOINTMENTS_TYPES_PAST,
} from '../../utils/appointmentUtil';
import { useUserMemberAppointments } from '../appointments/useUserMemberAppointments';

export type UserAppointmentSelect = {
  id: string;
  type: UserAppointmentsTypes;
};

export type EditAppointmentMode =
  | 'closed'
  | 'patientAppointmentsList'
  | 'patientAppointmentDetail'
  | 'editPatient'
  | 'viewAppointmentAudit';

export const EDIT_PATIENT_MODE = 'editPatient';
export const PATIENT_APPOINTMENT_DETAIL_MODE = 'patientAppointmentDetail';
export const PATIENT_APPOINTMENTS_LIST_MODE = 'patientAppointmentsList';
export const VIEW_APPOINTMENT_AUDIT_MODE = 'viewAppointmentAudit';

export const usePatientAppointments = ({ user }: { user?: UserData }) => {
  const [mode, setMode] = useState<EditAppointmentMode>('closed');
  const previousMode = usePreviousDistinct(mode);
  const [selectedFromUserAppointments, setSelectedFromUserAppointments] =
    useState<UserAppointmentSelect>();
  const [selectedAppointmentsTab, setSelectedAppointmentsTab] =
    useState<UserAppointmentsTypes>();

  const {
    isLoading: isUserBookedAppointmentsLoading,
    data: bookedAppointments,
  } = useUserMemberAppointments({
    user,
    apptType: USER_APPOINTMENTS_TYPES_BOOKED,
    enabled: selectedAppointmentsTab === USER_APPOINTMENTS_TYPES_BOOKED,
  });

  const {
    isLoading: isUserCancelledAppointmentsLoading,
    data: cancelledAppointments,
  } = useUserMemberAppointments({
    user,
    apptType: USER_APPOINTMENTS_TYPES_CANCELLED,
    enabled: selectedAppointmentsTab === USER_APPOINTMENTS_TYPES_CANCELLED,
  });

  const { isLoading: isUserPastAppointmentsLoading, data: pastAppointments } =
    useUserMemberAppointments({
      user,
      apptType: USER_APPOINTMENTS_TYPES_PAST,
      enabled:
        selectedAppointmentsTab === USER_APPOINTMENTS_TYPES_PAST ||
        // List mode has DNA/total in the header, so we need to load the past appointments
        mode === PATIENT_APPOINTMENTS_LIST_MODE,
    });

  const selectedUserAppointment = useMemo(() => {
    if (!selectedFromUserAppointments) {
      return undefined;
    }
    if (selectedFromUserAppointments.type === USER_APPOINTMENTS_TYPES_BOOKED) {
      return bookedAppointments?.find(
        (a) => a.id === selectedFromUserAppointments.id,
      );
    }
    if (selectedFromUserAppointments.type === USER_APPOINTMENTS_TYPES_PAST) {
      return pastAppointments?.find(
        (a) => a.id === selectedFromUserAppointments.id,
      );
    }
    if (
      selectedFromUserAppointments.type === USER_APPOINTMENTS_TYPES_CANCELLED
    ) {
      return cancelledAppointments?.find(
        (a) => a.id === selectedFromUserAppointments.id,
      );
    }
  }, [
    selectedFromUserAppointments,
    bookedAppointments,
    cancelledAppointments,
    pastAppointments,
  ]);

  const selectPatientAppointment = useCallback(
    (selected?: UserAppointmentSelect) => {
      setMode(PATIENT_APPOINTMENT_DETAIL_MODE);
      setSelectedFromUserAppointments(selected);
    },
    [setMode, setSelectedFromUserAppointments],
  );

  const setExistingAppointmentMode = useCallback(
    (mode: EditAppointmentMode) => {
      setMode(mode);
    },
    [setMode],
  );

  const close = useCallback(() => {
    setSelectedFromUserAppointments(undefined);
    setMode('closed');
  }, [setMode]);

  const closeEditPatient = useCallback(
    () => (previousMode ? setMode(previousMode) : close()),
    [setMode, close, previousMode],
  );

  return {
    setExistingAppointmentMode,
    appointment: selectedUserAppointment,
    bookedAppointments,
    pastAppointments,
    cancelledAppointments,
    selectPatientAppointment,
    close,
    mode,
    isLoading:
      isUserBookedAppointmentsLoading ||
      isUserPastAppointmentsLoading ||
      isUserCancelledAppointmentsLoading,
    closeEditPatient,
    setMode,
    setSelectedFromUserAppointments,
    setSelectedAppointmentsTab,
  };
};
