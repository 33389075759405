import React, { useMemo, useState } from 'react';

import { useExistingAppointments } from '../../query/appointments';
import { Appointment } from '../../types/appointments';
import { ItemsResponse } from '../../types/ItemsWithCountResponse';
import { Refetch } from '../../types/query';
import { ResourceType } from '../../types/resource-types';
import {
  addDays,
  DateOnlyISOString,
  formatDateOnlyISO,
  parseIsoDate,
  todayInIso,
} from '../../utils/dateUtil';
import { isTelephonePatientFromFullName } from '../../utils/user';

export type AppointmentForFlow = Appointment & { flowChipLabel: string };

export type AppointmentsForFlow = ItemsResponse<AppointmentForFlow>;

export type UseStaffAppointmentsType = {
  isFetching: boolean;
  setSelectedResourceType: React.Dispatch<
    React.SetStateAction<ResourceType | undefined>
  >;
  changeUpdateMode: (updateMode: boolean) => void;
  selectedDate: DateOnlyISOString;
  setSelectedDate: (date: DateOnlyISOString) => void;
  isUpdate: boolean;
  appointmentsData?: AppointmentsForFlow;
  selectedResourceType?: ResourceType;
  refetch: Refetch;
};

export const getAbbreviatedName = (name: string) => {
  const nameParts = name.trim().split(' ');
  let moreThan2CharsFound = false;
  return nameParts
    .reverse()
    .map((part, index) => {
      if (index === 0 || !moreThan2CharsFound) {
        moreThan2CharsFound = part.length > 2;
        return part;
      } else {
        return part.substring(0, 1);
      }
    })
    .reverse()
    .join(' ');
};

const formatPhoneNumber = (user_phone: string) =>
  user_phone.substring(0, user_phone.length - 6) +
  ' ' +
  user_phone.substring(user_phone.length - 6, user_phone.length - 3) +
  ' ' +
  user_phone.substring(user_phone.length - 3, user_phone.length);

const getFlowLabel = (showAbbreviatedNames: boolean, appt: Appointment) => {
  if (isTelephonePatientFromFullName(appt.user_full_name) && appt.user_phone) {
    return formatPhoneNumber(appt.user_phone);
  }
  return showAbbreviatedNames
    ? getAbbreviatedName(appt.user_full_name)
    : appt.user_full_name;
};

export const useAppointmentsFlow = ({
  showAbbreviatedNames,
}: {
  showAbbreviatedNames: boolean;
}): UseStaffAppointmentsType => {
  const [isUpdate, setIsUpdate] = useState(true);
  const [selectedDate, setSelectedDate] = useState<DateOnlyISOString>(
    todayInIso(),
  );

  const setSelectedDateClearUpdate = (date: DateOnlyISOString) => {
    setIsUpdate(false);
    setSelectedDate(date);
  };

  const changeUpdateMode = (updateMode: boolean) => {
    if (updateMode) {
      setSelectedDate(todayInIso());
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  };
  const dateRange = {
    fromDate: parseIsoDate(selectedDate),
    toDate: addDays(parseIsoDate(selectedDate), 1),
  };

  const [selectedResourceType, setSelectedResourceType] =
    useState<ResourceType>();

  const {
    data: appointmentsResponse,
    isLoading: isAppointmentLoading,
    dataUpdatedAt,
    refetch,
  } = useExistingAppointments(
    {
      fromDate: formatDateOnlyISO(dateRange.fromDate),
      toDate: formatDateOnlyISO(dateRange.toDate),
      resourceTypeIDs: selectedResourceType
        ? [selectedResourceType.id]
        : undefined,
    },
    {
      refetchInterval: 30_000,
      refetchIntervalInBackground: true,
    },
  );

  const appointmentsData = useMemo(() => {
    const responseData = appointmentsResponse?.data;
    if (responseData) {
      const dataWithLabels = responseData?.data.map((appt: Appointment) => ({
        ...appt,
        flowChipLabel: getFlowLabel(showAbbreviatedNames, appt),
      }));
      return {
        ...responseData,
        data: dataWithLabels.sort((a, b) =>
          a.flowChipLabel.localeCompare(b.flowChipLabel),
        ),
      };
    }
    /**
     * Appointment response is shallow compared, that's why we
     * need the dataUpdatedAt also to verify if cached data items
     * in appointments response have changed
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentsResponse, showAbbreviatedNames, dataUpdatedAt]);

  return {
    isFetching: isAppointmentLoading,
    setSelectedResourceType,
    changeUpdateMode,
    selectedDate,
    setSelectedDate: setSelectedDateClearUpdate,
    isUpdate,
    appointmentsData,
    selectedResourceType,
    refetch,
  };
};
