import {
  DialogFormWrapper,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import { CardLoader } from 'components/common/Loading';
import { ATTRIBUTE_SEPARATOR } from 'configs/constants';
import React, { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { FaUsers } from 'react-icons/fa';

import {
  MAX_AGE_ATTRIBUTE,
  MIN_AGE_ATTRIBUTE,
  OVER_MAX_AGE_MESSAGE,
  UNDER_MIN_AGE_MESSAGE,
} from '../../../../configs/siteAndTrustAttributes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { UseGeneralSitePreferencesFormReturn } from './GeneralSitePreferencesHooks';

interface Props {
  isFetching: boolean;
  attributesForm: UseGeneralSitePreferencesFormReturn;
}

export const AgeRestrictions: React.FC<Props> = ({
  isFetching,
  attributesForm,
}): ReactElement => {
  const { t } = useCommonTranslation();

  const {
    formState: { errors },
    attributeRegister,
    control,
  } = attributesForm;
  const watchedValues = useWatch({ control });

  const greaterThanZeroValidation = {
    min: {
      value: 0,
      message: t('must-greater-zero'),
    },
  };

  return (
    <DialogFormWrapper>
      <FormHeader>
        <FaUsers />
        <FormTitle>{t('age-restrictions')}</FormTitle>
      </FormHeader>
      <FormRow>
        <Input
          type="number"
          {...attributeRegister(
            MIN_AGE_ATTRIBUTE,
            (e) => e.target.value,
            greaterThanZeroValidation,
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === MIN_AGE_ATTRIBUTE,
            )?.value_str
          }
          label={t('min-age')}
          placeholder={t('min-age')}
          hasError={Boolean(
            errors[(ATTRIBUTE_SEPARATOR + MIN_AGE_ATTRIBUTE) as 'attributes'],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + MIN_AGE_ATTRIBUTE) as 'id']?.message
          }
        />
        <Input
          type="number"
          {...attributeRegister(
            MAX_AGE_ATTRIBUTE,
            (e) => e.target.value,
            greaterThanZeroValidation,
          )}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === MAX_AGE_ATTRIBUTE,
            )?.value_str
          }
          label={t('max-age')}
          placeholder={t('max-age')}
          hasError={Boolean(
            errors[(ATTRIBUTE_SEPARATOR + MAX_AGE_ATTRIBUTE) as 'attributes'],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + MAX_AGE_ATTRIBUTE) as 'id']?.message
          }
        />
      </FormRow>
      <FormRow>
        <Input
          {...attributeRegister(UNDER_MIN_AGE_MESSAGE, (e) => e.target.value)}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === UNDER_MIN_AGE_MESSAGE,
            )?.value_str
          }
          label={t('under-min-age-message')}
          placeholder={t('under-min-age-message')}
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR + UNDER_MIN_AGE_MESSAGE) as 'attributes'
            ],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + UNDER_MIN_AGE_MESSAGE) as 'id']
              ?.message
          }
        />
      </FormRow>
      <FormRow>
        <Input
          {...attributeRegister(OVER_MAX_AGE_MESSAGE, (e) => e.target.value)}
          value={
            watchedValues.attributes?.find(
              (atr) => atr.key === OVER_MAX_AGE_MESSAGE,
            )?.value_str
          }
          label={t('over-max-age-message')}
          placeholder={t('over-max-age-message')}
          hasError={Boolean(
            errors[
              (ATTRIBUTE_SEPARATOR + OVER_MAX_AGE_MESSAGE) as 'attributes'
            ],
          )}
          helperText={
            errors[(ATTRIBUTE_SEPARATOR + OVER_MAX_AGE_MESSAGE) as 'id']
              ?.message
          }
        />
      </FormRow>
      {isFetching && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
};
