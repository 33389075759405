import { useAuth } from 'context/AuthContext';
import React, { ReactElement, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import { SignupFormValues } from 'types/users';
import { errorToast } from 'utils/toast';
import { DEFAULT_SMS_OPT_IN, getUserFormData } from 'utils/user';
import { FormWrapper } from './SignupForm.styled';
import { useTrust } from '../../../../query/trusts';
import { useUserRegisterToSangixBE } from '../../../../hooks/user/useUserRegisterToSangixBE';
import { useQuerySite } from '../../../../query/sites';
import { useGPSurgeriesOptions } from '../../../../hooks/referrals/useGPSurgeriesOptions';
import { SignupStep1HospitalSelect } from './SignupStep1HospitalSelect';
import { CardLoader } from '../../../common/Loading';
import { SignupStep2ContactDetails } from './SignupStep2ContactDetails';
import { SignupStep3PatientInfo } from './SignupStep3PatientInfo';
import { FormActionsStyle } from '../../../common/Forms/Forms.styled';
import {
  CommonButton,
  CommonButton as Button,
} from '../../../common/Forms/Button';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { useHistory } from 'react-router';
import { useRedirectToKeepHash } from '../../../../hooks/router/useRedirectToKeepHash';
import {
  LOGIN_HASH_BOOK_AT_SITE_ID_PARAM,
  LOGIN_ROUTE,
  SIGN_UP_HASH_SITE_ID_PARAM,
} from '../../../../routes/AppRoutes';
import { useHashParams } from '../../../../hooks/router/useHashParams';
import { useRegisterWithFirebaseSignUp } from '../../../../hooks/user/useRegisterWithFirebaseSignUp';
import { gpSurgeryRequiredTrust } from '../../../../utils/trustUtil';

interface Props {
  completeRegister?: boolean;
}

export const SignupForm = ({ completeRegister }: Props): ReactElement => {
  const { firebaseUser } = useAuth();
  const auth = useAuth();
  const { t } = useCommonTranslation();
  const [loginTo] = useRedirectToKeepHash(LOGIN_ROUTE);
  const { push } = useHistory();
  const [isLoadingState, setIsLoading] = useState(false);

  const [siteIdForSignUp, siteIdForBook] = useHashParams(
    SIGN_UP_HASH_SITE_ID_PARAM,
    LOGIN_HASH_BOOK_AT_SITE_ID_PARAM,
  );
  const siteId = siteIdForSignUp || siteIdForBook;
  const [step, setStep] = useState<number>(siteId ? 2 : 1);

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState,
    trigger,
    watch,
    getValues,
  } = useForm<SignupFormValues>({
    defaultValues: {
      active_site_id: siteId,
      sms_optin: DEFAULT_SMS_OPT_IN,
    },
  });

  const activeSiteId = watch('active_site_id');

  const { data: activeSite, isLoading: isSiteLoading } = useQuerySite(
    activeSiteId,
    {
      enabled: !!activeSiteId,
      onError: (err) => {
        errorToast(err);
        setStep(1);
      },
    },
  );

  const { data: activeTrust, isLoading: isTrustLoading } = useTrust(
    activeSite?.data?.trust_id,
  );

  const { gpSurgeriesOptions: gpOptions } = useGPSurgeriesOptions({
    gpFilters: {
      trustIDs: activeTrust && [activeTrust.id],
    },
    options: {
      enabled: !!activeTrust && gpSurgeryRequiredTrust(activeTrust),
    },
    noToken: true,
    sortWithGPIdFirst: activeTrust?.unknown_gp_surgery_id,
  });

  const isLoading = isLoadingState || isSiteLoading || isTrustLoading;

  const { registerToSangixBe } = useUserRegisterToSangixBE();
  const { registerWithFirebaseSignup } = useRegisterWithFirebaseSignUp({
    registerToSangixBe,
  });

  const step3AndRegister = useCallback(
    async (values: SignupFormValues) => {
      setIsLoading(true);
      const { firebaseUser: emailPwd, userInfo } = getUserFormData({
        ...values,
        active_site_id: activeSiteId,
      });
      const completeSignupEmail = {
        email: completeRegister
          ? (firebaseUser as any)?.email || (firebaseUser as any)?.user?.email
          : userInfo.email,
      };
      try {
        if (firebaseUser) {
          await registerToSangixBe(
            {
              ...userInfo,
              ...completeSignupEmail,
            },
            firebaseUser,
          );
        } else {
          await registerWithFirebaseSignup(emailPwd, userInfo);
        }
      } catch (error) {
        errorToast(error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      setIsLoading,
      activeSiteId,
      completeRegister,
      firebaseUser,
      registerToSangixBe,
      registerWithFirebaseSignup,
    ],
  );

  const handleStepSubmit = useCallback(
    async (values: SignupFormValues) => {
      if (step === 1) {
        if (watch('active_site_id')) {
          setStep(2);
        }
      } else if (step === 2) {
        const isValid = await trigger([
          'firstname',
          'lastname',
          'email',
          'phone',
        ]);
        const isPasswordsValid = completeRegister
          ? true
          : await trigger(['password', 'confirmPassword']);

        if (isValid && isPasswordsValid) {
          setStep(3);
        }
      } else {
        await step3AndRegister(values);
      }
    },
    [trigger, completeRegister, setStep, step, step3AndRegister, watch],
  );

  useEffectOnce(() => {
    if (completeRegister) {
      setValue('email', firebaseUser.email);
    }
  });

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(handleStepSubmit)} autoComplete={'false'}>
        {isLoading ? (
          <CardLoader />
        ) : (
          <>
            {step === 1 && (
              <SignupStep1HospitalSelect
                onSiteSelected={(siteId) => {
                  setValue('active_site_id', siteId);
                  handleSubmit(handleStepSubmit)();
                }}
              />
            )}
            {activeSite && activeTrust && (
              <>
                {step === 2 && (
                  <SignupStep2ContactDetails
                    errors={formState.errors}
                    isLoading={isLoading}
                    register={register}
                    control={control}
                    completeRegister={completeRegister}
                    trust={activeTrust}
                    setValue={setValue}
                    watch={watch}
                    trigger={trigger}
                    getValues={getValues}
                    site={activeSite.data}
                  />
                )}
                {step === 3 && (
                  <SignupStep3PatientInfo
                    control={control}
                    errors={formState.errors}
                    register={register}
                    isLoading={isLoading}
                    gpOptions={gpOptions}
                    trust={activeTrust}
                    site={activeSite.data}
                  />
                )}
              </>
            )}
          </>
        )}
        <FormActionsStyle>
          {step !== 1 && (
            <Button variant="primary" type="submit" disabled={isLoading}>
              {step === 3 ? t('signup') : t('next')}
            </Button>
          )}
          <CommonButton
            type={'button'}
            variant="secondary"
            onClick={async () => {
              if (completeRegister && step === 1) {
                await auth.logout();
                push(loginTo);
              } else {
                if (step === 1) {
                  push(loginTo);
                }

                setStep(step - 1);
              }
            }}
          >
            {t('back')}
          </CommonButton>
        </FormActionsStyle>
      </form>
    </FormWrapper>
  );
};
