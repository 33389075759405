import { useCallback, useState } from 'react';
import {
  DuplicateUserResult,
  getDuplicateUsers,
} from '../../containers/staff/calendar/hooks/appointmentFormHooks';
import { UserData } from '../../types/authentication';
import { UserFormValues } from '../../types/users';

export const useDuplicateUserCheck = <T extends { user: UserFormValues }>({
  onResolvedAsDuplicate,
  onResolvedAsNOTDuplicate,
}: {
  onResolvedAsDuplicate: (params: T & { user: UserData }) => any;
  onResolvedAsNOTDuplicate: (params: T) => any;
}) => {
  const [duplicateUsers, setDuplicateUsers] = useState<DuplicateUserResult>();

  const [params, setParams] = useState<T>();

  const actionWithDuplicateCheck = useCallback<(params: T) => any>(
    async (params) => {
      setParams(params);
      const duplicateUsers = await getDuplicateUsers(params.user);
      if (duplicateUsers) {
        setDuplicateUsers(duplicateUsers);
      } else {
        await onResolvedAsNOTDuplicate(params);
      }
    },
    [setDuplicateUsers, setParams, onResolvedAsNOTDuplicate],
  );
  const resolveAsNOTDuplicate = useCallback(async () => {
    setDuplicateUsers(undefined);
    params && (await onResolvedAsNOTDuplicate(params));
  }, [onResolvedAsNOTDuplicate, setDuplicateUsers, params]);

  const resolveAsDuplicate = useCallback(
    ({ selectedPatient }: { selectedPatient: UserData }) => {
      setDuplicateUsers(undefined);
      params && onResolvedAsDuplicate({ ...params, user: selectedPatient });
    },
    [onResolvedAsDuplicate, setDuplicateUsers, params],
  );
  return {
    actionWithDuplicateCheck,
    duplicateUsers,
    resolveAsNOTDuplicate,
    resolveAsDuplicate,
  };
};
