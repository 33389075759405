import { useMemo } from 'react';
import { useResourceTypeAllDefaultTemplates } from './useResourceTypeAllDefaultTemplates';
import { ApptTemplate } from '../../types/apptTemplate';

export type AppointmentCounts = {
  allApptCount: number;
  staffOnlyCount: number;
  onlineCount: number;
};

type ResourceTypeTemplateCount = {
  resource_type_id: string;
  templateDatesWithCounts: Array<
    {
      template_date: string;
    } & AppointmentCounts
  >;
};

export const useDefaultTemplatesApptCounts = ({
  resourceTypeId,
}: {
  resourceTypeId?: string;
}) => {
  const { defaultTemplates, isLoading } = useResourceTypeAllDefaultTemplates({
    resourceTypeId,
  });

  const resourceTypeTemplateCounts = useMemo<
    ResourceTypeTemplateCount[]
  >(() => {
    if (!defaultTemplates) {
      return [];
    }

    const groupedByResourceType = defaultTemplates.reduce(
      (grouped, template) => {
        const index = grouped.findIndex(
          (group) => group.resource_type_id === template.resource_type_id,
        );

        if (index !== -1) {
          grouped[index].templates.push(template);
        } else {
          grouped.push({
            resource_type_id: template.resource_type_id,
            templates: [template],
          });
        }

        return grouped;
      },
      [] as Array<{ resource_type_id: string; templates: ApptTemplate[] }>,
    );
    return groupedByResourceType.map(({ templates, resource_type_id }) => {
      const templateDatesWithCounts = templates
        .map(({ template_date, entries }) => ({
          template_date,
          allApptCount: entries.reduce(
            (acc, { cubicle_count }) => acc + cubicle_count,
            0,
          ),
          staffOnlyCount: entries.reduce(
            (acc, { staff_only }) => acc + staff_only,
            0,
          ),
        }))
        .map(({ template_date, allApptCount, staffOnlyCount }) => ({
          template_date,
          allApptCount,
          staffOnlyCount,
          onlineCount: allApptCount - staffOnlyCount,
        }));
      return {
        resource_type_id,
        templateDatesWithCounts,
      };
    });
  }, [defaultTemplates]);

  return { isLoading, resourceTypeTemplateCounts };
};
