import { DateOnlyISOString, HourMinuteFormatted } from 'utils/dateUtil';
import { ItemsResponse } from './ItemsWithCountResponse';
import { UserRoleId } from '../configs/constants';

export const REPORT_IDS = {
  REFERRALS_GP_SURGERIES: '36a9537410c8407db27c1c1280d87199', // Previously REFERRALS_REPORT
  PHLEBOTOMISTS_MONTHLY_APPTS: '3a73c3cec70b4c1fab57181bc3a6bf26', // Previously MONTHLY_PERFORMANCE_REPORT
  PHLEBOTOMISTS_DAILY_APPTS: 'd5c65a22ff924c2298079c41323c755b', // Previously DAILY_PERFORMANCE_REPORT
  REFERRALS_CCG: '15f2999747a5475590ce6c9e10b6dfed', // Previously CCG_REPORT
  APPOINTMENTS_PATIENTS_AND_PHLEBOTOMISTS: 'efd9f68a56244d83a8c01924de3165f1', // Previously DAILY_DETAILS_REPORT
  REFERRALS_GPS_AND_CCGS: 'f281862ac66f4ce587f374ca4e19e11c', // Previously GP_SURGERY_REPORT
  PHLEBOTOMISTS_LOGIN_TIMES: '95f14f79166245b4ac9194c37fef2a4c', // Previously LOGIN_REPORT
  APPOINTMENTS_BREAKDOWN: '0b019dbe74d842b1941626833582b7cf', // Previously DAILY_REPORT_BY_ACTIVITY
  APPOINTMENTS_BREAKDOWN_ALL_LOCATIONS: 'aba58502345443e68029092c75b74752',
  DNA_REPORT: 'd3faf5ce5d0c40b08bb0976e36a6c6ca', // Currently hidden
  POSSIBLE_DUPLICATE_REPORT: 'f6519550764a44168fca3664f2f3008d',
} as const;

type ReportTypes = keyof typeof REPORT_IDS;

export type ReportIds = typeof REPORT_IDS[ReportTypes];

export type Report = {
  id: string;
  name: string;
  info: string;
};

export type ReportsResponse = ItemsResponse<Report>;

export type ReportResourceTypeMap = { [K in string]: number };

export type ReportCubiclePatient = {
  name: string;
  appt_time: HourMinuteFormatted;
  checked: HourMinuteFormatted;
  called: HourMinuteFormatted;
  arrived: HourMinuteFormatted;
  processed: HourMinuteFormatted;
};
export type ReportCubicle = {
  cubicle_id: string;
  cubicle_name: string;
  user_member_name: string;
  seen: number;
  urgent?: number;
  resource_types: ReportResourceTypeMap;
};

export type ReportCubicleResponse = ReportCubicle[];

export type ReportStatisticsPatient = {
  id?: string;
  name: string;
  staff: string;
  resource_type: string;
  appt_date: DateOnlyISOString;
  appt_time: HourMinuteFormatted;
  checked: string;
  called: string;
  processed: string;
  arrived: string;
};
export type Phlebotomist = {
  id: string;
  name: string;
  seen: number;
  urgent?: number;
  patients?: ReportStatisticsPatient[];
  resource_types?: ReportResourceTypeMap;
};

export type Statistics = {
  staff: Phlebotomist[];
  all_staff_tmp?: Omit<Phlebotomist, 'id' | 'name'> & { name?: undefined };
  resource_types: ReportResourceTypeMap;
  appts: number;

  past: number;
  taken_tests: number;
  not_taken_tests: number;
  dna_mia: number;
  overflow: number;
};

export type SADApptStatistics = SADApptStatisticItem[];

export type SADApptStatisticItem = {
  trust_name: string;
  site_id: string;
  site_name: string;
  year: number;
  appt_count: number;
};

export type UserRoleCount = {
  [key in UserRoleId]: number;
};

export type SADSiteStatistics = {
  user_role_counts: UserRoleCount;
  gp_surgery_count: number;
  ccg_count: number;
  referrer_count: number;
};
