import { useFamilyMember } from 'hooks/patient/useFamilyMember';
import { SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { Appointment } from 'types/appointments';

import { usePatientSites } from '../../../containers/patient/PatientHooks';
import { useSiteValidatePatientAge } from '../../../hooks/site/useSiteValidatePatientAge';
import { PatientSite, SiteData } from '../../../types/sites';
import { getPatientSiteRoles } from '../../../utils/patient';
import { errorToast } from '../../../utils/toast';
import { getSiteRolesTrustIds } from '../../../utils/user';
import { useAuth } from '../../AuthContext';
import { useSite } from '../../SiteContext';
import { patientContext } from '../PatientContext';
import {
  PRE_BOOK_CORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER,
  PRE_BOOK_INCORRECT_ANSWER_MESSAGE,
  PRE_BOOK_QUESTION,
} from '../../../configs/siteAndTrustAttributes';
import { useSpecificSiteStringAttributes } from '../../../hooks/useSpecificSiteAttribute';
import { useGuidelinesTemplate } from '../../../query/storage';

export const bookingStages = [
  'site',
  'guidelines',
  'member',
  'question',
  'day',
  'hour',
  'appt',
  'success',
] as const;
export type PatientBookingStage = typeof bookingStages[number];

export type PatientBookingState = {
  stage: PatientBookingStage;
  date: Date;
  calendarDateShown: Date;
  bookedAppt: Appointment | null;
  memberId: string | undefined; // use undefined because it's acceptable type in book query
  siteId: string | undefined;
  /**
   * PENDING -> We are still before or just on question stage.
   * NOT_CONFIGURED -> Question wasn't configured.
   * ANSWERED_CORRECTLY -> Question was configured and user chose correct answer.
   */
  questionAnsweredState: 'PENDING' | 'NOT_CONFIGURED' | 'ANSWERED_CORRECTLY';
};
export const patientBookingInit = (): PatientBookingState => ({
  stage: 'site',
  date: new Date(),
  calendarDateShown: new Date(),
  bookedAppt: null,
  memberId: undefined,
  siteId: undefined,
  questionAnsweredState: 'PENDING',
});

export const usePatientBooking = () => {
  const updatePatientState = patientContext.useUpdateState();
  const setPatientState = patientContext.useSetState();
  return {
    booking: patientContext.useState().booking,
    /**
     * Updates booking state using lodash merge function.
     * It can't be used for setting undefined properties.
     * For setting undefined properties use `setBooking`.
     */
    updateBooking: useCallback(
      (update: Partial<PatientBookingState>) => {
        return updatePatientState({ booking: update });
      },
      [updatePatientState],
    ),
    setBooking: useCallback(
      (value: SetStateAction<PatientBookingState>) => {
        setPatientState((prevPatientState) => ({
          ...prevPatientState,
          booking:
            value instanceof Function ? value(prevPatientState.booking) : value,
        }));
      },
      [setPatientState],
    ),
  };
};

export const useResetPatientBooking = () => {
  const setState = patientContext.useSetState();
  return useCallback(() => {
    return setState((state) => ({ ...state, booking: patientBookingInit() }));
  }, [setState]);
};
export const useResetPatientBookingOnUnmount = () => {
  const reset = useResetPatientBooking();
  useEffect(() => reset, [reset]);
};

export const useGoBack = ():
  | {
      canGoBack: true;
      goBack: () => void;
    }
  | {
      canGoBack: false;
      goBack: undefined;
    } => {
  const {
    booking: { stage },
    updateBooking: updateState,
  } = usePatientBooking();
  const { showStage: showSiteStage } = usePatientBookingSiteStage();
  const { showStage: showMemberStage } = usePatientBookingMemberStage();
  const prevStage: PatientBookingStage | undefined = useMemo(() => {
    switch (stage) {
      case 'site':
        return undefined;
      case 'guidelines':
      case 'member':
        // we skip guidelines stage while going back, it is useless on way back
        return showSiteStage ? 'site' : undefined;
      case 'question':
      case 'day':
        // we skip question stage while going back, it is useless on way back
        if (showMemberStage) {
          return 'member';
        } else {
          return showSiteStage ? 'site' : undefined;
        }
      case 'hour':
        return 'day';
      case 'appt':
        return 'hour';
      case 'success':
        return undefined;
    }
  }, [stage, showSiteStage, showMemberStage]);

  const goBack = useCallback(() => {
    updateState({ stage: prevStage });
  }, [prevStage, updateState]);

  return prevStage
    ? {
        canGoBack: true,
        goBack,
      }
    : {
        canGoBack: false,
        goBack: undefined,
      };
};

export const useAssignedBookingPatientSites = (): {
  sites: PatientSite[] | undefined;
  trustIds: string[] | undefined;
} => {
  const { activeSite } = useSite();
  const { sangixUser } = useAuth();

  return useMemo(() => {
    if (!sangixUser) {
      return {
        sites: undefined,
        trustIds: undefined,
      };
    }
    const patientSiteRoles = getPatientSiteRoles(sangixUser);
    return {
      sites: patientSiteRoles
        .filter(
          (role) =>
            !role.expired && // expired roles are not available for booking
            (!activeSite.disable_patient_registration_and_switch ||
              activeSite.id === role.site_id) && // active site prevents switching to any other site, so only active site is available
            (!role.prevent_switching || activeSite.id === role.site_id), // Any site with prevent_switching can't be switched to
        )
        .map(({ site_id, site_name }) => ({
          id: site_id,
          name: site_name,
          deletable: activeSite.id !== site_id,
        }))
        .sort((a, b) => {
          // Not deletable is first, then in alphabetic order
          return (
            Number(a.deletable) - Number(b.deletable) ||
            a.name.localeCompare(b.name)
          );
        }),
      trustIds: getSiteRolesTrustIds(patientSiteRoles),
    };
  }, [sangixUser, activeSite]);
};

export const useUnassignedTrustBookingSites = (): {
  sites: SiteData[] | undefined;
} => {
  const { filteredSites } = usePatientSites();
  const { sites: assignedSites } = useAssignedBookingPatientSites();
  return {
    sites: useMemo(() => {
      if (!filteredSites || !assignedSites) {
        return undefined;
      }
      return filteredSites
        .filter((site) =>
          assignedSites.every((availableSite) => availableSite.id !== site.id),
        )
        .sort((a, b) => {
          // In alphabetic order
          return a.name.localeCompare(b.name);
        });
    }, [filteredSites, assignedSites]),
  };
};

export const useOptionalBookingUser = () => {
  const {
    booking: { memberId },
  } = usePatientBooking();
  const member = useFamilyMember(memberId);
  return memberId ? member : undefined;
};

export const useOptionalBookingUserMemberId = (): string | undefined => {
  const bookingUser = useOptionalBookingUser();
  if (!bookingUser) {
    return undefined;
  }
  if ('main_member_id' in bookingUser) {
    return bookingUser.main_member_id;
  } else {
    return bookingUser.id;
  }
};

export const useBookingUser = () => {
  const {
    booking: { memberId },
  } = usePatientBooking();
  return useFamilyMember(memberId);
};

export const useBookingUserMemberId = (): string => {
  const bookingUser = useBookingUser();
  if (!bookingUser) {
    errorToast('Booking user not found.');
    return '';
  }
  if ('main_member_id' in bookingUser) {
    return bookingUser.main_member_id;
  } else {
    return bookingUser.id;
  }
};

export const usePatientBookingSiteStage = ():
  | {
      showStage: false;
      defaultSiteId: string;
    }
  | {
      showStage: true;
      defaultSiteId: undefined;
    } => {
  return {
    showStage: true,
    defaultSiteId: undefined,
  };
};

export const usePatientBookingMemberStage = ():
  | {
      showStage: false;
      defaultMemberId: string;
    }
  | {
      showStage: true;
      defaultMemberId: undefined;
    } => {
  const { sangixUser } = useAuth();
  const { validatePatientAge } = useSiteValidatePatientAge();
  return useMemo(() => {
    if (
      sangixUser &&
      !validatePatientAge(sangixUser) &&
      sangixUser.other_members.length === 0
    ) {
      return {
        showStage: false,
        defaultMemberId: sangixUser.id,
      };
    } else {
      return {
        showStage: true,
      };
    }
  }, [sangixUser, validatePatientAge]);
};

export const usePatientBookingGuidelinesStage = ():
  | {
      isLoading: true;
      // showStage is undefined because we don't know yet if we should show this stage
      showStage: undefined;
      guidelines: undefined;
    }
  | {
      isLoading: false;
      showStage: true;
      guidelines: string;
    }
  | {
      isLoading: false;
      showStage: false;
      guidelines: undefined;
    } => {
  const { isLoading, data } = useGuidelinesTemplate();
  if (isLoading) {
    return {
      isLoading: true,
      showStage: undefined,
      guidelines: undefined,
    };
  }
  const guidelines = data?.data;
  if (guidelines) {
    return {
      isLoading: false,
      showStage: true,
      guidelines,
    };
  } else {
    return {
      isLoading: false,
      showStage: false,
      guidelines: undefined,
    };
  }
};

type QuestionConfigValues = {
  question: string;
  correctAnswer: string;
  incorrectAnswer: string;
  incorrectAnswerMessage: string;
};

export const usePatientBookingQuestionConfig = (
  site: SiteData,
):
  | ({
      enabled: true;
    } & QuestionConfigValues)
  | ({
      enabled: false;
    } & Partial<QuestionConfigValues>) => {
  const [question, correctAnswer, incorrectAnswer, incorrectAnswerMessage] =
    useSpecificSiteStringAttributes(
      site,
      PRE_BOOK_QUESTION,
      PRE_BOOK_CORRECT_ANSWER,
      PRE_BOOK_INCORRECT_ANSWER,
      PRE_BOOK_INCORRECT_ANSWER_MESSAGE,
    );

  if (question) {
    /**
     * According to site settings validation correctAnswer, incorrectAnswer
     * and incorrectAnswerMessage should be defined when question is present.
     * If for any reason they are not defined, we use fallback strings
     * to uncover the mistake for user.
     */
    return {
      enabled: true,
      question,
      correctAnswer: correctAnswer || 'CORRECT_ANSWER',
      incorrectAnswer: incorrectAnswer || 'INCORRECT_ANSWER',
      incorrectAnswerMessage:
        incorrectAnswerMessage || 'INCORRECT_ANSWER_MESSAGE',
    };
  } else {
    return {
      enabled: false,
    };
  }
};
