import styled from 'styled-components';
import { h2 } from 'styles/Typography';

export const StatusTitle = styled.h1`
  ${h2};
  font-weight: 500;
  text-align: center;
  margin-bottom: var(--s3);
`;
export const StatusDesc = styled.p`
  text-align: center;
  margin: 0;
`;
