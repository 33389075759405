import { AxiosResponse } from 'axios';
import {
  QueryKey,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import {
  CubicleFilters,
  CubicleFormValues,
  CubicleResponseData,
} from 'types/cubicles';

import {
  addNewCubicle,
  deleteCubicle,
  getAllCubicles,
  updateCubicle,
} from '../services/cubicles';
import { ping } from '../services/userProfile';
import { MILLIS_IN_MINUTE } from '../utils/dateUtil';
import {
  CacheAndStaleTimeForUnlikelyChanged,
  useMutationWithToastError,
  useQueryWithToastError,
} from './queryUtils';
import { REPORTS_QUERY_KEY } from './reports';

const CUBICLES_QUERY_KEY = 'all-cubicle';
export const useCubicles = (
  filters?: CubicleFilters,
  options?: UseQueryOptions<
    AxiosResponse<CubicleResponseData>,
    any,
    AxiosResponse<CubicleResponseData>
  >,
) => {
  return useQueryWithToastError(
    [
      CUBICLES_QUERY_KEY,
      {
        ...filters,
      },
    ],
    () => getAllCubicles(),
    {
      ...CacheAndStaleTimeForUnlikelyChanged,
      ...options,
      select: (response) => ({
        ...response,
        data: {
          ...response.data,
          data: response.data.data.sort((a, b) => a.name.localeCompare(b.name)),
        },
      }),
    },
  );
};

export const useMutateUpdateCubicle = (
  options?: UseMutationOptions<any, any, CubicleFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<CubicleFormValues>(
    [CUBICLES_QUERY_KEY],
    (update: CubicleFormValues) => updateCubicle(update),
    {
      onSettled: () => {
        client.invalidateQueries([CUBICLES_QUERY_KEY]);
        // "Changing cubicle props can change the results of supervisor report"
        client.invalidateQueries([REPORTS_QUERY_KEY, 'supervisor']);
      },
      ...options,
    },
  );
};

export const useMutateCreateCubicle = (
  options?: UseMutationOptions<any, any, CubicleFormValues>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<CubicleFormValues>(
    [CUBICLES_QUERY_KEY],
    (params) => addNewCubicle(params),
    {
      onSettled: () => client.invalidateQueries([CUBICLES_QUERY_KEY]),
      ...options,
    },
  );
};

export const useMutateDeleteCubicle = (
  options?: UseMutationOptions<any, any, string>,
) => {
  const client = useQueryClient();
  return useMutationWithToastError<string>(
    [CUBICLES_QUERY_KEY],
    (params) => deleteCubicle(params),
    {
      onSettled: () => client.invalidateQueries([CUBICLES_QUERY_KEY]),
      ...options,
    },
  );
};

export const useCubiclePing = (options?: UseQueryOptions) => {
  return useQuery('ping' as QueryKey, () => ping(), {
    refetchInterval: 10 * MILLIS_IN_MINUTE,
    ...options,
  });
};
