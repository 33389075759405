import { Column } from 'react-table';

import { useSangixTable } from './TableUtils';
import React, { useCallback, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { isEqual } from 'lodash';
import { ItemsResponse } from '../../types/ItemsWithCountResponse';
import { TableComponent } from './TableComponent';
import { NewPagination } from '../common/Pagination/NewPagination';
import {
  IObjectWithId,
  ISortAndPagination,
  ISortFilters,
  OptionalIOffsetLimitFilters,
} from '../../types/table';

export type FetchedDataTableProps<
  D extends object,
  K extends ISortAndPagination,
> = {
  data?: ItemsResponse<D>;
  columns: Column<D>[];
  isLoading: boolean;
  /**
   * Table showing cached data, while background request is in progress. Currently not used,
   * but kept if we later decide to show some soft indication of background data loading.
   */
  isFetching: boolean;
  onSortOrPageChange: (filters: K) => any;
  selectedIds?: string[];
  filters: Required<OptionalIOffsetLimitFilters> & ISortFilters; //Not available from table component - separate cmp was implemented
  resizingKey?: string;
};

export const FetchedDataTable = <
  D extends IObjectWithId,
  K extends ISortAndPagination,
>({
  isLoading,
  columns,
  data,
  onSortOrPageChange,
  filters,
  selectedIds,
  resizingKey,
}: FetchedDataTableProps<D, K>) => {
  const pageData = data?.data.slice(0, filters.pageLimit);
  const { tableProps, structuredFilters, sortBy } = useSangixTable({
    columns,
    data: pageData,
    currentFilters: filters,
    resizingKey,
    selectedIds,
  });
  let previousSortBy = usePrevious(sortBy);
  useEffect(() => {
    if (previousSortBy && !isEqual(previousSortBy, sortBy)) {
      onSortOrPageChange(structuredFilters);
    }
  }, [onSortOrPageChange, structuredFilters, previousSortBy, sortBy]);
  const onPageChange = useCallback(
    (newOffset: number) => {
      onSortOrPageChange({ ...structuredFilters, offset: newOffset });
    },
    [structuredFilters, onSortOrPageChange],
  );

  return (
    <>
      <TableComponent {...tableProps} isLoading={isLoading} />
      <NewPagination
        itemsCount={data?.data.length || 0}
        pageLimit={filters.pageLimit}
        offset={filters.offset}
        setOffset={onPageChange}
      />
    </>
  );
};
