import { Card, CardSubtitle } from 'components/common';
import { CommonButton as Button } from 'components/common/Forms/Button';
import CheckboxInput from 'components/common/Forms/CheckboxInput';
import {
  CloseButton,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormHeader,
  FormRow,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import Input from 'components/common/Forms/Input';
import CommonSelect from 'components/common/Forms/Select';
import { CardLoader } from 'components/common/Loading';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaTimes, FaUserNurse } from 'react-icons/fa';
import { UserData } from 'types/authentication';
import { Backend_Role, SelectOption } from 'types/common';
import { GeneralPractice } from 'types/generalPractices';
import { StaffSignupFormValues } from 'types/users';
import { GP_SURGERY } from '../../../../../configs/constants';

import { useSite } from '../../../../../context/SiteContext';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { useUserValidationRules } from '../../../../../hooks/patient/useUserValidationRules';
import { useRoleOptions } from '../../../../../hooks/staff/useRoleOptions';
import { LABEL_BEFORE } from '../../../../../styles/fontsStyleUtils';
import { StaffSiteRoleRowSelectWrapper } from './StaffForm.styled';
import { StaffSiteRoleRowLabel } from './StaffSiteRoleRowLabel';
import { StaffSiteRoles } from './StaffSiteRoles';

interface Props {
  closeFormHandler: () => void;
  /**
   * Default is `t('cancel')`.
   */
  closeButtonText?: string;
  submitHandler: (data: StaffSignupFormValues, isUpdateUser: boolean) => void;
  isLoading: boolean;
  rolesData?: Backend_Role[] | null;
  prefilledData?: UserData;
  GPData?: GeneralPractice[] | null;
  /**
   * Default is false.
   */
  disabledRolesAndGp?: boolean;
  /**
   * Default is true.
   */
  withPasswordForm?: boolean;
  /**
   * Custom form header. If not defined, default header will be used.
   */
  formHeader?: ReactNode;
}

export const StaffForm = ({
  closeFormHandler,
  closeButtonText,
  submitHandler,
  isLoading,
  prefilledData,
  GPData,
  rolesData,
  disabledRolesAndGp,
  withPasswordForm = true,
  formHeader,
}: Props): ReactElement => {
  const isEditForm = Boolean(prefilledData);
  const { t } = useCommonTranslation();
  const { activeSite } = useSite();
  const rolesOptions: SelectOption[] = useRoleOptions(rolesData);
  const gpOptions: SelectOption[] =
    (GPData &&
      GPData.map((gp) => ({
        label: gp.name,
        value: gp.id,
      }))) ||
    [];
  const [showPassword, setShowPassword] = useState(false);
  const [showGP, setShowGP] = useState(false);

  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<StaffSignupFormValues>({
    defaultValues: {
      active_site_id: activeSite.id,
      ...prefilledData,
      password: undefined,
      confirmPassword: undefined,
    },
  });

  useEffect(() => {
    if (!prefilledData) setValue('active_site_id', activeSite.id);
  }, [activeSite, setValue, prefilledData]);

  useEffect(() => {
    if (prefilledData && rolesData) {
      const selectedRole = rolesData.find(
        (role) => role.id === prefilledData.role_id,
      );
      if (selectedRole) {
        setValue('role_id', selectedRole.id);
      }
    }
  }, [rolesData, prefilledData, setValue]);

  useEffect(() => {
    setFocus('firstname');
  }, [setFocus]);

  const selectedRole = watch('role_id');

  useEffect(() => {
    if (selectedRole && GP_SURGERY === selectedRole) {
      setShowGP(true);
    } else {
      setShowGP(false);
    }
  }, [selectedRole]);

  const { emailRules, firstnameRules, surnameRules } = useUserValidationRules(
    {},
  );
  return (
    <Card>
      <DialogFormWrapper>
        <CloseButton
          size="auto"
          variant="primary"
          iconOnly
          onClick={() => closeFormHandler()}
        >
          <FaTimes />
        </CloseButton>
        {formHeader || (
          <FormHeader>
            <FaUserNurse />
            <FormTitle>{t('staff-information')}</FormTitle>
          </FormHeader>
        )}
        <FormBody
          onSubmit={handleSubmit((data) => submitHandler(data, isEditForm))}
        >
          <FormRow>
            <Input
              label={t('first-name')}
              placeholder={t('first-name')}
              helperText={errors.firstname?.message}
              hasError={Boolean(errors.firstname)}
              id={
                prefilledData
                  ? prefilledData.id + '_firstname'
                  : 'new_firstname'
              }
              {...register('firstname', firstnameRules)}
              required
            />
            <Input
              label={t('surname')}
              placeholder={t('surname')}
              helperText={errors.lastname?.message}
              hasError={Boolean(errors.lastname)}
              id={prefilledData ? prefilledData.id + '_surname' : 'new_surname'}
              {...register('lastname', surnameRules)}
              required
            />
          </FormRow>
          <FormRow>
            <Input
              label={t('email')}
              placeholder={t('email')}
              type="email"
              helperText={errors.email?.message}
              hasError={Boolean(errors.email)}
              id={prefilledData ? prefilledData.id + '_email' : 'new_email'}
              {...register('email', emailRules)}
            />
          </FormRow>
          {withPasswordForm && (
            <>
              <FormRow>
                <div style={{ paddingBottom: 'var(--s4)' }}>
                  <CheckboxInput
                    name="set-password"
                    label={t(isEditForm ? 'change-password' : 'set-password')}
                    id={'set_password'}
                    checked={showPassword}
                    onChange={() => setShowPassword((prev) => !prev)}
                  />
                </div>
              </FormRow>
              {showPassword && (
                <FormRow style={{ zIndex: 0 }}>
                  <Input
                    label={t('password')}
                    placeholder={t('password')}
                    type="password"
                    disabled={isLoading}
                    {...register('password', {
                      required: t('must-not-empty') as string,
                      pattern: {
                        value: /\d/,
                        message: t('password-pattern-validation') as string,
                      },
                      minLength: {
                        value: 8,
                        message: t('password-minlength-validation') as string,
                      },
                    })}
                    helperText={errors.password?.message}
                    hasError={Boolean(errors?.password)}
                  />
                  <Input
                    label={t('confirm-password')}
                    type="password"
                    placeholder={t('confirm-password')}
                    disabled={isLoading}
                    {...register('confirmPassword', {
                      required: t('must-not-empty') as string,
                      validate: (value) =>
                        value === watch('password') ||
                        (t('confirm-password-validation') as string),
                    })}
                    helperText={errors.confirmPassword?.message}
                    hasError={Boolean(errors?.confirmPassword)}
                  />
                </FormRow>
              )}
            </>
          )}
          {!isEditForm && (
            <>
              <CardSubtitle>{t('active-site-role')}</CardSubtitle>
              <FormRow>
                <StaffSiteRoleRowLabel label={activeSite.short_name} />
                <StaffSiteRoleRowSelectWrapper>
                  <Controller
                    name="role_id"
                    render={({ field }) => (
                      <CommonSelect
                        {...field}
                        instanceId={
                          prefilledData
                            ? prefilledData.id + '_role'
                            : 'new_role'
                        }
                        placeholder={t('role')}
                        disabled={!rolesOptions}
                        options={rolesOptions}
                        helperText={errors.role_id?.message}
                        hasError={Boolean(errors.role_id)}
                        required
                        style={{
                          flex: '1 1 auto',
                        }}
                      />
                    )}
                    control={control}
                    rules={{
                      required: t('must-not-empty') as string,
                    }}
                  />
                </StaffSiteRoleRowSelectWrapper>
              </FormRow>
            </>
          )}
          {showGP && (
            <FormRow style={{ zIndex: 1 }}>
              <Controller
                name="gp_surgery_id"
                render={({ field }) => (
                  <CommonSelect
                    {...field}
                    labelPosition={LABEL_BEFORE}
                    label={t('general-practice')}
                    instanceId={
                      prefilledData ? prefilledData.id + '_gp' : 'new_gp'
                    }
                    placeholder={t('general-practice')}
                    disabled={!gpOptions || disabledRolesAndGp}
                    options={gpOptions}
                    helperText={errors.gp_surgery_id?.message}
                    hasError={Boolean(errors.gp_surgery_id)}
                    onChange={(option) => field.onChange(option)}
                  />
                )}
                control={control}
                rules={{
                  required: t('must-not-empty') as string,
                }}
              />
            </FormRow>
          )}
          <FormActionsStyle align="center">
            <Button variant="primary" type="submit">
              {isEditForm ? t('save') : t('add')}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => closeFormHandler()}
            >
              {closeButtonText || t('cancel')}
            </Button>
          </FormActionsStyle>
        </FormBody>
        {prefilledData && (
          <StaffSiteRoles
            roles={rolesData}
            userData={prefilledData}
            disabled={disabledRolesAndGp}
          />
        )}

        {isLoading && <CardLoader fillWrapper={true} />}
      </DialogFormWrapper>
    </Card>
  );
};
