import React, { useMemo } from 'react';
import {
  getNewColumnWidth,
  setColumnFilterAndSortKey,
} from '../../../utils/table';
import { TableHeadStyle } from '../../../styles/Typography';
import { Column, FilterProps } from 'react-table';
import DefaultInputFilter from '../../../components/Table/DefaultInputFilter';
import { UserData } from '../../../types/authentication';
import { formatDateOnlySite, parseIsoDate } from '../../../utils/dateUtil';
import FilterByDatePicker from '../../../components/Table/FilterByDatePicker';
import { FaCalendarAlt } from 'react-icons/fa';
import {
  FilterInputContainerStyleOverride,
  RowActions,
} from '../../../components/Table/Table.styled';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { UsersFilters, UsersResponseData } from '../../../types/users';
import { UserRowActionCreator } from './SearchPatientDialog';
import { FiltersWithSet } from '../../../hooks/usePaginatedFilters';
import {
  getFilterControls,
  tableRightHandSquishColumn,
} from '../../../components/Table/TableUtils';
import { formatPhoneNumber } from '../../../utils/helpers';
import { InputPhoneNumber } from '../../../components/common/Forms/InputPhoneNumber';

export type UserFilterKeys = Array<keyof UsersFilters>;

type UsePatientColumnsPropsCommon = {
  filtersDisplayed?: UserFilterKeys;
  usersData?: UsersResponseData;
  disableSortBy?: boolean;
  rowActionsCreator: UserRowActionCreator;
};

type UsePatientColumnsPropsWithFilters = {
  showFilters: true;
} & FiltersWithSet<UsersFilters>;

type UsePatientColumnsPropsNoFilters = {
  showFilters?: false;
} & Partial<FiltersWithSet<UsersFilters>>;

type UsePatientColumnsProps = UsePatientColumnsPropsCommon &
  (UsePatientColumnsPropsNoFilters | UsePatientColumnsPropsWithFilters);

export const usePatientsColumns = ({
  usersData,
  rowActionsCreator,
  filtersDisplayed,
  showFilters,
  disableSortBy,
  filters,
  setFilters,
}: UsePatientColumnsProps) => {
  const { t } = useCommonTranslation();
  return useMemo(() => {
    const filtersToDisplay: Array<keyof UsersFilters> = filtersDisplayed ?? [
      'firstname',
      'lastname',
      'phone',
      'email',
      'birthDate',
    ];
    return [
      {
        Header: t('first-name'),
        accessor: 'firstname',
        id: setColumnFilterAndSortKey('firstname'),
        cellStyle: {
          ...TableHeadStyle,
          textTransform: 'capitalize',
        },
        width: getNewColumnWidth(20),
        disableSortBy,
        Filter: showFilters
          ? (props: FilterProps<object>) =>
              filtersToDisplay.includes('firstname') && (
                <DefaultInputFilter
                  {...props}
                  inputProps={{
                    placeholder: t('first-name'),
                  }}
                />
              )
          : undefined,
      },
      {
        Header: t('surname'),
        accessor: 'lastname',
        id: setColumnFilterAndSortKey('lastname'),
        cellStyle: {
          ...TableHeadStyle,
          textTransform: 'capitalize',
        },
        width: getNewColumnWidth(20),
        disableSortBy,
        Filter: showFilters
          ? (props: FilterProps<object>) =>
              filtersToDisplay.includes('lastname') &&
              filters && (
                <DefaultInputFilter
                  {...props}
                  inputProps={{
                    defaultValue: filters.lastname,
                    placeholder: t('surname'),
                  }}
                />
              )
          : undefined,
      },
      {
        Header: t('date-of-birth'),
        accessor: (user: UserData) =>
          formatDateOnlySite(parseIsoDate(user.birthdate)),
        width: getNewColumnWidth(16),
        id: 'birthDate',
        disableSortBy,
        Filter: showFilters
          ? (props: FilterProps<object>) =>
              filtersToDisplay.includes('birthDate') &&
              filters && (
                <FilterByDatePicker
                  {...props}
                  inputProps={{
                    defaultValue: filters.birthDate,
                    placeholder: t('date-of-birth'),
                    icon: <FaCalendarAlt />,
                  }}
                />
              )
          : undefined,
      },
      {
        Header: t('phone-number'),
        id: 'phone',
        accessor: (user: UserData) => formatPhoneNumber(user.phone),
        width: getNewColumnWidth(14),
        minWidth: 100,
        disableSortBy: true,
        Filter: showFilters
          ? ({
              column: { filterValue, setFilter, id },
              ...inputProps
            }: FilterProps<object>) => {
              const inputName = inputProps?.name || id;
              return (
                <InputPhoneNumber
                  placeholder={t('phone-number')}
                  {...inputProps}
                  noSpaceForHelperText
                  id={id}
                  value={filterValue || ''}
                  name={inputName}
                  containerStyle={FilterInputContainerStyleOverride}
                  onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                  }}
                />
              );
            }
          : undefined,
      },
      {
        Header: t('email'),
        accessor: 'email',
        width: getNewColumnWidth(20),
        disableSortBy: true,
        Filter: showFilters
          ? (props: FilterProps<object>) =>
              filtersToDisplay.includes('email') && (
                <DefaultInputFilter
                  {...props}
                  inputProps={{
                    placeholder: t('email'),
                  }}
                />
              )
          : undefined,
      },
      {
        ...(filters && setFilters
          ? getFilterControls({ t, filters, setFilters })
          : {
              accessor: 'id',
              ...tableRightHandSquishColumn({}),
            }),
        Cell: ({ value }: { value: string }) => {
          const thePatient = usersData?.data.find((p) => p.id === value);
          return (
            <RowActions>
              {thePatient && rowActionsCreator(thePatient)}
            </RowActions>
          );
        },
      },
    ] as Column<UserData>[];
  }, [
    t,
    usersData,
    rowActionsCreator,
    filtersDisplayed,
    showFilters,
    filters,
    setFilters,
    disableSortBy,
  ]);
};
