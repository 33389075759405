import { Loading } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import { FaHospital } from 'react-icons/fa';
import { useParams } from 'react-router';

import {
  Card,
  CardHeader,
  PatientGraphics,
  PatientInner,
  PatientLayoutWrapper,
} from '../../../components/common';
import { CommonLink } from '../../../components/common/Forms/Button';
import Footer from '../../../components/Footer';
import {
  PopupIconWrapper,
  PopupTitle,
} from '../../../components/Popup/ConfirmDialog/ConfirmDialog.styled';
import { useClientRect } from '../../../hooks/getBoundingClientRect';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { useTrustMiniSite } from '../../../query/trustMiniSites';
import { trustMiniSiteURL } from '../../../routes/AppRoutes';
import Page404 from '../../../routes/status/Page404';
import { PatientNavBar } from '../../patient/PatientNavBar';
import { TrustMiniSiteCategoriesList } from './TrustMiniSiteCategoriesList';
import { TrustMiniSiteCategorySitesList } from './TrustMiniSiteCategorySitesList';
import {
  TrustMiniSiteButtonBar,
  TrustMiniSiteButtonBarRight,
  TrustMiniSiteListWrapper,
  TrustMiniSiteStepTitle,
} from './TrustMiniSitePage.styled';

export const TrustMiniSitePage = () => {
  const { t } = useCommonTranslation();
  const { rect, ref } = useClientRect();
  const { trustId, categoryId } =
    useParams<{ trustId: string; categoryId: string }>();
  const { data: trustMiniSite, isLoading } = useTrustMiniSite(trustId);
  if (isLoading) return <Loading />;
  if (!trustMiniSite || trustMiniSite.categories.length === 0)
    return <Page404 />;

  const { trust_id, trust_name, categories } = trustMiniSite;
  const category = categoryId
    ? categories.find((c) => c.id === categoryId)
    : undefined;
  if (categoryId && !category) return <Page404 />;

  return (
    <PatientLayoutWrapper>
      <PageHelmet title={trust_name} />
      <PatientGraphics contentRect={rect} />
      <PatientNavBar
        nhsLogoTitle={trust_name}
        showNhsLogoForNoUser={true}
        showLoginButtonForNoUser={true}
      ></PatientNavBar>
      <PatientInner ref={ref} maxWidth={55}>
        <Card>
          <PopupIconWrapper inlineIcon>{<FaHospital />}</PopupIconWrapper>
          <CardHeader>
            {category && <PopupTitle>{category.name}</PopupTitle>}
            <TrustMiniSiteStepTitle>
              {t(
                category
                  ? 'trust-mini-site-select-hospital'
                  : 'trust-mini-site-select-clinic-type',
              )}
            </TrustMiniSiteStepTitle>
          </CardHeader>
          <TrustMiniSiteListWrapper>
            {category ? (
              <TrustMiniSiteCategorySitesList
                trustMiniSite={trustMiniSite}
                category={category!}
              />
            ) : (
              <TrustMiniSiteCategoriesList trustMiniSite={trustMiniSite} />
            )}
          </TrustMiniSiteListWrapper>
          {category && (
            <TrustMiniSiteButtonBar>
              <TrustMiniSiteButtonBarRight>
                <CommonLink
                  type="button"
                  variant="secondary"
                  size="large"
                  to={`${trustMiniSiteURL}/${trust_id}`}
                >
                  {t('trust-mini-site-back-to-clinic-type')}
                </CommonLink>
              </TrustMiniSiteButtonBarRight>
            </TrustMiniSiteButtonBar>
          )}
        </Card>
      </PatientInner>
      <Footer />
    </PatientLayoutWrapper>
  );
};
