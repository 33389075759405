import { Card } from 'components/common';
import { SearchPatientKeys } from 'components/views-components/staff/calendar/CalendarHeader';
import React, { useCallback } from 'react';

import { useSelectUserWithHiddenRefetch } from '../../hooks/user/useSelectUserWithUpdate';
import { UserData } from '../../types/authentication';
import { PatientDetailCard } from './calendar/PatientDetailCard';
import { SearchSelectPatientTable } from './calendar/SearchSelectPatientTable';
import { SangixMainGrid } from './SangixMainGrid';

interface Props {
  searchPatientKeys?: SearchPatientKeys;
  selectPatient: (user: UserData | undefined) => void;
}

export const StaffCalendarPatientSearch = ({
  searchPatientKeys,
  selectPatient,
}: Props) => {
  const {
    setSelectedUser: setPreviewedPatient,
    selectedUser: previewedPatient,
  } = useSelectUserWithHiddenRefetch();

  const previewPatient = useCallback(
    (user) => {
      setPreviewedPatient(user);
    },
    [setPreviewedPatient],
  );

  return (
    <SangixMainGrid
      main={
        <Card>
          <SearchSelectPatientTable
            initialFilters={searchPatientKeys}
            onClose={() => {
              selectPatient(undefined);
            }}
            onSelectPatient={selectPatient}
            onPreviewPatient={previewPatient}
          />
        </Card>
      }
      secondary={
        <>
          {previewedPatient && (
            <PatientDetailCard
              key={previewedPatient.id}
              patient={previewedPatient}
              onPatientSelectButtonClicked={() => {
                selectPatient(previewedPatient);
              }}
              onPatientCloseButtonClicked={() => {
                previewPatient(undefined);
              }}
            />
          )}
        </>
      }
    />
  );
};
