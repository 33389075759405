import styled from 'styled-components';

export const WeekInlineCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--s2);
`;

export const WeekInlineCalendarPickerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--s2);
  margin-bottom: var(--s2);
`;
