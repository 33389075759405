import styled from 'styled-components';

export const WeekRowStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--s4);
  justify-content: center;
  margin-top: var(--s3);
`;

export const DayColumnStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.25rem;
  gap: var(--s2);
`;