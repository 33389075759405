import { useForm } from 'react-hook-form';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { ThirdPartyLimit } from '../../../../../types/users';
import { WEEK_DAY_LABELS } from '../../../../../utils/dateUtil';
import { Popup } from '../../../../Popup/Popup';
import { Card, CardTitle } from '../../../../common';
import { CommonButton } from '../../../../common/Forms/Button';
import {
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
  FormRow,
} from '../../../../common/Forms/Forms.styled';
import CommonInput from '../../../../common/Forms/Input';

interface Props {
  thirdPartyLimit: ThirdPartyLimit;
  /**
   * If submitHandler is provided, the form will be in edit mode.
   * Otherwise, the form will be in view mode and fields are disabled.
   */
  submitHandler?: (data: ThirdPartyLimit) => void;
  cancelHandler: () => void;
}

interface FormValues {
  day0: number;
  day1: number;
  day2: number;
  day3: number;
  day4: number;
  day5: number;
  day6: number;
}

type DayKey = keyof FormValues;

export const StaffThirdPartyLimit = ({
  thirdPartyLimit,
  submitHandler,
  cancelHandler,
}: Props) => {
  const { t } = useCommonTranslation();
  const disabled = !submitHandler;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      day0: thirdPartyLimit[0],
      day1: thirdPartyLimit[1],
      day2: thirdPartyLimit[2],
      day3: thirdPartyLimit[3],
      day4: thirdPartyLimit[4],
      day5: thirdPartyLimit[5],
      day6: thirdPartyLimit[6],
    },
  });

  const submitFormHandler: (data: FormValues) => void = (data) => {
    submitHandler &&
      submitHandler([
        data.day0,
        data.day1,
        data.day2,
        data.day3,
        data.day4,
        data.day5,
        data.day6,
      ]);
  };

  return (
    <Popup
      closeOnDocumentClick={false}
      closeOnEscape={false}
      open={true}
      size="small"
    >
      <Card>
        <CardTitle>{t('third-party-limit')}</CardTitle>
        <DialogFormWrapper>
          <FormBody onSubmit={handleSubmit((data) => submitFormHandler(data))}>
            {WEEK_DAY_LABELS(t).map(({ label, backendDowIndex }) => {
              const dayKey = ('day' + backendDowIndex) as DayKey;
              return (
                <FormRow key={backendDowIndex}>
                  <CommonInput
                    label={label}
                    type="number"
                    helperText={errors[dayKey]?.message}
                    hasError={Boolean(errors[dayKey])}
                    {...register(dayKey, {
                      valueAsNumber: true,
                      required: t('must-not-empty') as string,
                      pattern: {
                        value: /^[0-9]+$/,
                        message: t('must-be-whole-number'),
                      },
                      min: {
                        value: 0,
                        message: t('must-greater-zero'),
                      },
                    })}
                    required
                    disabled={disabled}
                  />
                </FormRow>
              );
            })}
            <FormActionsStyle align="center">
              {disabled ? (
                <CommonButton
                  type="button"
                  variant="secondary"
                  onClick={() => cancelHandler()}
                >
                  {t('close')}
                </CommonButton>
              ) : (
                <>
                  <CommonButton variant="primary" type="submit">
                    {t('save')}
                  </CommonButton>
                  <CommonButton
                    type="button"
                    variant="secondary"
                    onClick={() => cancelHandler()}
                  >
                    {t('cancel')}
                  </CommonButton>
                </>
              )}
            </FormActionsStyle>
          </FormBody>
        </DialogFormWrapper>
      </Card>
    </Popup>
  );
};
