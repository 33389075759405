import { CommonButton } from 'components/common/Forms/Button';
import React, { ReactElement, useCallback } from 'react';
import {
  addDays,
  DATE_FNS_UK_DATE_WITH_FULL_DATE_FORMAT,
  endOfWeek,
  formatDate,
  isAfter,
  isBefore,
  isSameDay,
  startOfWeek,
} from '../../utils/dateUtil';
import { WeekInlineCalendarWrapper } from './WeekDayPicker.styled';

type Props = {
  filterDate?: (date: Date) => boolean;
  minDate?: Date;
  weekDate: Date;
  setSelectedDate: (date: Date) => any;
};

export const WeekDayPicker = ({
  minDate,
  filterDate,
  setSelectedDate,
  weekDate,
}: Props): ReactElement => {
  const weekStartDate = startOfWeek(weekDate);
  const weekEndDate = endOfWeek(weekDate);

  const isWithinRange = useCallback(
    (date: Date) =>
      !minDate || isAfter(date, minDate) || isSameDay(date, minDate),
    [minDate],
  );
  const isDisabled = useCallback(
    (date: Date) => !isWithinRange(date) || (filterDate && !filterDate(date)),
    [isWithinRange, filterDate],
  );
  let renderDate = weekStartDate;
  const weekdays = [];
  do {
    weekdays.push(renderDate);
    renderDate = addDays(renderDate, 1);
  } while (isBefore(renderDate, weekEndDate));
  return (
    <WeekInlineCalendarWrapper>
      {weekdays.map((weekDay, index) => (
        <CommonButton
          key={`weekday_${index}`}
          variant={'secondary'}
          disabled={isDisabled(weekDay)}
          onClick={() => setSelectedDate(weekDay)}
        >
          {formatDate(weekDay, DATE_FNS_UK_DATE_WITH_FULL_DATE_FORMAT)}
        </CommonButton>
      ))}
    </WeekInlineCalendarWrapper>
  );
};
