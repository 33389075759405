import { ResourceType } from '../../../../types/resource-types';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import {
  useDeleteApptTemplate,
  useMutateUpdateApptTemplate,
  useMutateUpdateApptTemplateForWeek,
  usePostApptTemplate,
} from '../../../../query/template';
import { errorToast, successToast } from '../../../../utils/toast';
import { useCallback } from 'react';
import {
  ApptTemplate,
  ApptTemplateEntry,
} from '../../../../types/apptTemplate';
import { DateOnlyISOString, parseIsoDate } from '../../../../utils/dateUtil';
import { isEqual } from 'lodash';
import {
  isDefaultFullWeekTemplateDay,
  isDefaultTemplateDate,
} from './DefaultTemplate';

export const useSaveTimePlanning = ({
  selectedResourceType,
  defaultTemplate,
  selectedDateOnlyIso,
}: {
  selectedResourceType?: ResourceType;
  defaultTemplate?: ApptTemplate;
  selectedDateOnlyIso: DateOnlyISOString;
}) => {
  const { t } = useCommonTranslation();
  const { mutateAsync: updateApptTemplate, isLoading: isUpdateLoading } =
    useMutateUpdateApptTemplate();
  const { mutateAsync: addApptTemplate, isLoading: isAddLoading } =
    usePostApptTemplate({
      onSuccess: () => {
        successToast(t('time-planner-changes-saved'));
      },
    });
  const { mutate: deleteApptTemplate, isLoading: isDeleteLoading } =
    useDeleteApptTemplate({
      onSuccess: () => {
        successToast(t('time-planner-changes-saved'));
      },
    });
  const { mutate: updateForFullWeek, isLoading: isFullWeekUpdateLoading } =
    useMutateUpdateApptTemplateForWeek({
      onSuccess: () => {
        successToast(t('time-planner-full-week-changes-saved'));
      },
    });

  const createAndUpdateTimePlanning = useCallback(
    async (
      entries: ApptTemplateEntry[],
      resourceTypeId: string,
      templateId?: string,
    ) => {
      let createdTemplateId = templateId;
      if (!createdTemplateId) {
        const newTemplate = await addApptTemplate({
          template_date: selectedDateOnlyIso,
          resource_type_id: resourceTypeId,
        });
        createdTemplateId = newTemplate?.data?.id;
      }
      if (createdTemplateId) {
        await updateApptTemplate({
          entries: entries,
          id: createdTemplateId,
        });
      }
    },
    [addApptTemplate, updateApptTemplate, selectedDateOnlyIso],
  );

  const saveTimePlanning = useCallback(
    async (entries: ApptTemplateEntry[], templateId?: string) => {
      if (selectedResourceType) {
        if (
          templateId &&
          defaultTemplate &&
          isEqual(entries, defaultTemplate.entries) &&
          !isDefaultTemplateDate(parseIsoDate(selectedDateOnlyIso))
        ) {
          /**
           * Deleting custom template for specific date, since it is now the same as default template.
           */
          deleteApptTemplate(templateId);
        } else if (
          isDefaultFullWeekTemplateDay(parseIsoDate(selectedDateOnlyIso))
        ) {
          await updateForFullWeek({ entries });
        } else {
          await createAndUpdateTimePlanning(
            entries,
            selectedResourceType.id,
            templateId,
          );
        }
      } else {
        errorToast(t('no-resource-type-selected'));
      }
    },
    [
      updateForFullWeek,
      createAndUpdateTimePlanning,
      deleteApptTemplate,
      selectedResourceType,
      t,
      defaultTemplate,
      selectedDateOnlyIso,
    ],
  );
  return {
    saveTimePlanning,
    isLoading:
      isUpdateLoading ||
      isAddLoading ||
      isDeleteLoading ||
      isFullWeekUpdateLoading,
  };
};
