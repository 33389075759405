import React from 'react';
import {
  UserData,
  UserMemberData,
  UserMemberRelation,
} from '../../../../../types/authentication';
import { AppointmentCard } from '../../../../../components/views-components/staff/appointments/AppointmentCard';
import { useCommonTranslation } from '../../../../../hooks/i18n/useCommonTranslation';
import { FaUserEdit } from 'react-icons/fa';
import { MemberAccountFormBody } from '../../../../../components/views-components/patient/profile/MemberAccountFormBody';
import { useGPSurgeriesOptions } from '../../../../../hooks/referrals/useGPSurgeriesOptions';
import {
  useCreateOtherUserMemberMutation,
  useUpdateOtherUserMemberMutation,
} from '../../../../../query/users';
import { UserMemberFormValues } from '../../../../../types/users';
import { successToast } from '../../../../../utils/toast';
import { usePatientTrusts } from '../../../../../hooks/user/usePatientTrusts';
import { getPatientTrustIds } from '../../../../../utils/patient';

type IProps = {
  closeFormHandler: (userAfterChange?: UserData) => void;
  onBackClicked: () => any;
  userToEdit?: UserMemberData;
  primaryUser: UserData;
};

export const StaffMemberEditCard: React.FC<IProps> = ({
  closeFormHandler,
  onBackClicked,
  userToEdit,
  primaryUser,
}) => {
  const { t } = useCommonTranslation();
  const userAccountId = primaryUser.user_account_id;

  const { isLoading: isTrustsLoading, trusts } = usePatientTrusts(primaryUser);
  const { isLoading: isGpSurgeriesLoading, gpSurgeriesOptions } =
    useGPSurgeriesOptions({
      gpFilters: {
        trustIDs: getPatientTrustIds(primaryUser),
      },
    });

  const { isLoading: isUpdateUserMemberLoading, mutate: updateUserMember } =
    useUpdateOtherUserMemberMutation({
      onSuccess: () => {
        successToast(t('member-updated-successfully'));
        onBackClicked();
      },
    });

  const { isLoading: isCreateUserMemberLoading, mutate: createUserMember } =
    useCreateOtherUserMemberMutation({
      onSuccess: () => {
        successToast(t('member-created-successfully'));
        onBackClicked();
      },
    });

  const onSaveMember = (member: UserMemberFormValues) => {
    if (userToEdit) {
      updateUserMember({
        ...member,
        id: userToEdit.id,
        relation: Number(member.relation) as UserMemberRelation,
      });
    } else {
      createUserMember({
        ...member,
        user_account_id: userAccountId,
        relation: Number(member.relation) as UserMemberRelation,
      });
    }
  };

  const isLoading =
    isTrustsLoading ||
    isGpSurgeriesLoading ||
    isUpdateUserMemberLoading ||
    isCreateUserMemberLoading;

  return (
    <AppointmentCard
      isLoading={isLoading}
      title={userToEdit ? t('edit-shared-user') : t('add-shared-user')}
      onCloseClicked={closeFormHandler}
      icon={<FaUserEdit />}
    >
      {trusts && (
        <MemberAccountFormBody
          gpSurgeriesOptions={gpSurgeriesOptions}
          onBackClicked={onBackClicked}
          trusts={trusts}
          onSaveMember={onSaveMember}
          member={userToEdit}
          formButtonsAlign={'center'}
        />
      )}
    </AppointmentCard>
  );
};
