import { Card } from 'components/common';
import { CardLoader } from 'components/common/Loading';
import { PageHelmet } from 'components/common/PageHelmet';
import CalendarHeader, {
  SearchPatientKeys,
} from 'components/views-components/staff/calendar/CalendarHeader';
import { useSiteIntAttributes } from 'hooks/useAttribute';
import React, { useCallback, useMemo, useState } from 'react';
import {
  STAFF_CLOSE_HOUR_IN_SECONDS,
  STAFF_OPEN_HOUR_IN_SECONDS,
} from '../../configs/siteAndTrustAttributes';
import { useSelectUserWithHiddenRefetch } from '../../hooks/user/useSelectUserWithUpdate';
import { useCommonTranslation } from '../../hooks/i18n/useCommonTranslation';
import { UserData } from '../../types/authentication';
import {
  DateTimeToMinuteOnlyISOString, getDateRange,
  isToday,
  parseIsoDate,
} from '../../utils/dateUtil';
import { BookAppointmentSlotCard } from './calendar/BookAppointmentSlotCard';
import { BookOverflow } from './calendar/BookOverflow';
import { useStaffCalendar } from './calendar/hooks/useStaffCalendar';
import { PatientDetailCard } from './calendar/PatientDetailCard';
import { StaffCalendarAppointmentFlowCard } from './calendar/StaffCalendarAppointmentFlowCard';
import { StaffCalendarPatientSearch } from './StaffCalendarPatientSearch';
import { StaffLayout } from './StaffLayout';
import { SangixMainGrid } from './SangixMainGrid';
import { DateRangeType } from '../../types/calendar';
import { useResourceTypesWithCubiclesAssigned } from '../../hooks/resourceTypes/useResourceTypesWithCubiclesAssigned';
import { CalendarDateRangeView } from '../../components/views-components/staff/calendar/CalendarDateRangeView';

const DEFAULT_DATE_RANGE_TYPE: DateRangeType = 'week';

export const StaffCalendar = () => {
  const { t } = useCommonTranslation();

  const [searchPatientKeys, setSearchPatientKeys] = useState<
    SearchPatientKeys | undefined
  >(undefined);

  const searchingPatients = !!searchPatientKeys;

  const { setSelectedUser: setSelectedPatient, selectedUser: selectedPatient } =
    useSelectUserWithHiddenRefetch();

  const [openHourInSeconds, closeHourInSeconds] = useSiteIntAttributes(
    STAFF_OPEN_HOUR_IN_SECONDS,
    STAFF_CLOSE_HOUR_IN_SECONDS,
  );
  const [dateRangeType, setDateRangeType] = useState<DateRangeType>(
    DEFAULT_DATE_RANGE_TYPE,
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [overflowOpened, setOverflowOpened] = useState(false);

  const dateRange = useMemo(
    () => getDateRange(selectedDate, dateRangeType),
    [selectedDate, dateRangeType],
  );

  const {
    isLoading: isFilteredResourceTypeFetching,
    resourceTypesWithCubicleAssigned,
  } = useResourceTypesWithCubiclesAssigned();

  const {
    isFetching: isCalendarDataFetching,
    futureAppointmentsSlots,
    setSelectedResourceType,
    selectedResourceType,
    refetch,
  } = useStaffCalendar({
    dateRange,
    filteredResourceTypes: resourceTypesWithCubicleAssigned,
  });
  const [selectedAppointmentSlotTime, setSelectedAppointmentSlotTime] =
    useState<DateTimeToMinuteOnlyISOString>();

  const selectedAppointmentSlot = selectedAppointmentSlotTime
    ? futureAppointmentsSlots?.[selectedAppointmentSlotTime]
    : undefined;

  const isFetching = isFilteredResourceTypeFetching || isCalendarDataFetching;

  const onDateTitleClick = useCallback(
    (date: Date) => {
      const revertedDateRangeType = dateRangeType === 'day' ? 'week' : 'day';
      setSelectedDate(date);
      setDateRangeType(revertedDateRangeType);
    },
    [dateRangeType],
  );

  const closeForm = useCallback(
    (keepPatient: boolean) => {
      setSelectedAppointmentSlotTime(undefined);
      if (!keepPatient) {
        setSelectedPatient(undefined);
      }
    },
    [setSelectedAppointmentSlotTime, setSelectedPatient],
  );

  const selectPatient = useCallback(
    (user: UserData | undefined) => {
      setSearchPatientKeys(undefined);
      setSelectedAppointmentSlotTime(undefined);
      setSelectedPatient(user);
    },
    [setSelectedAppointmentSlotTime, setSelectedPatient],
  );

  return (
    <StaffLayout>
      <PageHelmet title={t('calendar')} />
      {searchingPatients ? (
        <StaffCalendarPatientSearch
          searchPatientKeys={searchPatientKeys}
          selectPatient={selectPatient}
        />
      ) : (
        <SangixMainGrid
          main={
            <Card fillHeight>
              <CalendarHeader
                filteredResourceTypes={resourceTypesWithCubicleAssigned}
                isResourceTypeFetching={isFetching}
                selectedResourceType={selectedResourceType}
                setSelectedResourceType={setSelectedResourceType}
                selectedDate={selectedDate}
                onSelectDate={setSelectedDate}
                selectedPatient={selectedPatient}
                clearSelectedPatient={() => {
                  selectPatient(undefined);
                }}
                showPatientSearch={(searchPatientKeys) => {
                  selectPatient(undefined);
                  setSearchPatientKeys(searchPatientKeys);
                }}
                onOverflowClicked={() => setOverflowOpened(true)}
                dateRangeType={dateRangeType}
                setDateRangeType={setDateRangeType}
              />
              {isFetching ? (
                <CardLoader
                  style={{
                    marginTop: 'var(--s4)',
                  }}
                />
              ) : openHourInSeconds === undefined ? (
                'No Open Hour'
              ) : closeHourInSeconds === undefined ? (
                'No Close Hour'
              ) : selectedResourceType === undefined ? (
                <CardLoader />
              ) : futureAppointmentsSlots === undefined ? (
                'No Appointments Data'
              ) : (
                <>
                  <CalendarDateRangeView
                    openHourInSeconds={openHourInSeconds}
                    closeHourInSeconds={closeHourInSeconds}
                    dateRange={dateRange}
                    selectedResourceType={selectedResourceType}
                    futureAppointmentsByDate={futureAppointmentsSlots}
                    setSelectedAppointmentsSlot={setSelectedAppointmentSlotTime}
                    selectedAppointmentSlot={selectedAppointmentSlotTime}
                    dateRangeType={dateRangeType}
                    onDateTitleClick={onDateTitleClick}
                    onRefreshClick={refetch}
                  />
                </>
              )}
            </Card>
          }
          secondary={
            <>
              {selectedResourceType && selectedAppointmentSlot ? (
                <BookAppointmentSlotCard
                  selectedAppointmentSlot={selectedAppointmentSlot}
                  selectedPatient={selectedPatient}
                  closeForm={closeForm}
                  isWalkInAvailable={
                    selectedResourceType.walkin &&
                    isToday(parseIsoDate(selectedAppointmentSlot[0].appt_time))
                  }
                />
              ) : overflowOpened ? (
                <BookOverflow
                  closeForm={() => {
                    setSelectedPatient(undefined);
                    setOverflowOpened(false);
                  }}
                  selectedPatient={selectedPatient}
                />
              ) : selectedPatient ? (
                <PatientDetailCard
                  key={selectedPatient.id}
                  patient={selectedPatient}
                />
              ) : (
                <StaffCalendarAppointmentFlowCard
                  filteredResourceTypesFetching={isFilteredResourceTypeFetching}
                  filteredResourceTypes={resourceTypesWithCubicleAssigned}
                />
              )}
            </>
          }
        />
      )}
    </StaffLayout>
  );
};

