import { Card } from 'components/common';
import {
  DialogFormWrapper,
  FormHeader,
  FormTitle,
} from 'components/common/Forms/Forms.styled';
import { Col } from 'components/common/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import { FaUserCog } from 'react-icons/fa';

import { CardLoader } from '../../../../components/common/Loading';
import { PromptWithBeforeUnload } from '../../../../components/Form/PromptWithBeforeUnload';
import { APPT_FLAGS_SELECTED } from '../../../../configs/siteAndTrustAttributes';
import { useSpecificSiteSelectedApptFlags } from '../../../../hooks/appointments/useSiteSelectedApptFlags';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import { SiteData } from '../../../../types/sites';
import { Subject, useSubjectListener } from '../../../../utils/subject';
import { errorToast } from '../../../../utils/toast';
import { ISitePreferencesProps } from '../SitePreferences';
import { AppointmentFlagsTable } from './AppointmentFlagsTable';

type Props = ISitePreferencesProps & {
  site: SiteData;
  saveEvent: Subject<void>;
};

export const AppointmentFlagsPreferences: React.FC<Props> = ({
  site,
  saveEvent,
  submitHandler,
  isFetching,
}) => {
  const { t } = useCommonTranslation();
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    setIsDirty(false);
  }, [site]);

  const siteSelectedFlags = useSpecificSiteSelectedApptFlags(site);

  const [selectedFlags, setSelectedFlags] = useState<string[]>(
    siteSelectedFlags.map((flag) => `${flag.id}`),
  );

  const toggleFlag = useCallback(
    (flag: string) => {
      if (selectedFlags.includes(flag)) {
        setSelectedFlags(selectedFlags.filter((f) => f !== flag));
      } else {
        setSelectedFlags([...selectedFlags, flag]);
      }
      setIsDirty(true);
    },
    [selectedFlags],
  );

  const handleSubmit = useCallback(() => {
    if (selectedFlags.length > 3) {
      errorToast(t('appointment-flags-limit'));
      return;
    }

    let bitwise = 0;
    selectedFlags.forEach((flag) => {
      bitwise = bitwise | Number(flag);
    });
    let newFlags = site.attributes.filter(
      (flag) => flag.key !== APPT_FLAGS_SELECTED,
    );
    newFlags.push({
      key: APPT_FLAGS_SELECTED,
      value_int: bitwise,
      value_str: bitwise.toString(),
    });
    submitHandler({
      ...site,
      attributes: newFlags,
    });
  }, [selectedFlags, site, submitHandler, t]);

  useSubjectListener(
    saveEvent,
    () => {
      if (!isFetching) {
        handleSubmit();
      }
    },
    [isFetching, handleSubmit],
  );

  return (
    <Col md={8}>
      <Card>
        <DialogFormWrapper>
          <PromptWithBeforeUnload when={isDirty}></PromptWithBeforeUnload>
          <FormHeader>
            <FaUserCog />
            <FormTitle>{t('appointment-flags')}</FormTitle>
          </FormHeader>
          <AppointmentFlagsTable
            selectedFlags={selectedFlags}
            onToggleFlag={toggleFlag}
          />
        </DialogFormWrapper>
        {isFetching && <CardLoader fillWrapper={true} />}
      </Card>
    </Col>
  );
};
