import { useEnteredCubicle } from './useEnteredCubicle';
import { useExistingAppointments } from '../../query/appointments';
import { addDays, formatDateOnlyISO } from '../../utils/dateUtil';
import { useAuth } from '../../context/AuthContext';
import { useMemo } from 'react';

export const useCurrentUserUnfinishedAppts = () => {
  const { sangixUser } = useAuth();

  /**
   * While it is not necessary to have to check for entered cubicle,
   * it should be unlikely that user would have unfinished appointment
   * that needs to be picked up if he was not inside the cubicle.
   * To limit the BE requests, Peter suggested to check this.
   */
  const {
    enteredCubicle,
    isLoading: isCubicleLoading,
    isFetching: isEnteredCubicleFetching,
  } = useEnteredCubicle();
  const {
    data: calledAndProcessing,
    isLoading: isApptsLoading,
    isFetching: isApptsFetching,
  } = useExistingAppointments(
    {
      fromDate: formatDateOnlyISO(new Date()),
      toDate: formatDateOnlyISO(addDays(new Date(), 1)),
      statuses: ['CALLED', 'PROCESSING'],
    },
    {
      enabled: !!enteredCubicle,
    },
  );

  const userAppts = useMemo(
    () =>
      calledAndProcessing?.data.data.filter(
        ({ staff_id }) => staff_id === sangixUser?.main_member_id,
      ),
    [calledAndProcessing, sangixUser],
  );
  return {
    isFetching: isEnteredCubicleFetching || isApptsFetching,
    isLoading: isApptsLoading || isCubicleLoading,
    userAppts,
  };
};
